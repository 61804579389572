import PropTypes from 'prop-types'
import React from "react"
import { withTranslation } from 'react-i18next'
import { Col, Modal, ModalBody, Row } from "reactstrap"

const ConfirmModal = ({ show, onConfirmClick, onCloseClick, text, t }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick}>
      <ModalBody className="py-2 px-3">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "7em", color: "orange" }}
              />
              <h3>{text ? t(text) : t("Are you sure?")}</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3 pb-4">
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={onConfirmClick}
              >
                {t("Confirm!")}
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={onCloseClick}
              >
                {t("Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  show: PropTypes.any
}

export default withTranslation()(ConfirmModal)
