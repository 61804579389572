import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

//Import Breadcrumb
import { withTranslation } from "react-i18next";
import { getEntity } from "../../../helpers/backend_helper";
import EntityProfileTab from "./EntityProfileTab";
import Breadcrumb from "../../../components/Common/Breadcrumb";


const EntityDetail = ({match, t}) => {
  const { id } = match.params;
  const [item, setItem] = useState(null);

  const fetchItem = async () => {
    const item = await getEntity(id)
    setItem(item)
  }

  useEffect(() => {
    fetchItem()
  }, [])

  //meta title
  document.title = "Details | Tellit";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={t("Entity")} breadcrumbItem={item ? t("Detail") + ' ' + t("Entity") + ' ' + item.name:''} />

          <Row>
            {/* Render profilemenu */}
            { item && <EntityProfileTab entity={item} /> }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(EntityDetail)
