import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
import { withTranslation } from 'react-i18next';

class Pie extends Component {  
  getOption = () => {
    const { t, data, label } = this.props;
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        top: "0",
        
        data: (data || []).map(e => e[label]),
        textStyle: {
          color: ["#858d98"],
        },
      },
      color: ['#ED5565', '#FC6E51', '#FFCE54', '#A0D468', '#48CFAD', '#4FC1E9', '#5D9CEC', '#AC92EC', '#EC87C0', '#F5F7Fa', '#CCD1D9', '#656D78', '#DA4453', '#E9573F', '#F6BB42', '#8CC152', '#37BC9B', '#3BAFDA', '#4A89DC', '#967ADC', '#D770AD', '#E6E9ED', '#AAB2BD', '#434A54'],
      series: [
        {
          top: 50,
          name: t("Total incidents"),
          type: "pie",
          radius: "80%",
          center: ["50%", "50%"],
          data: (data || []).map(e => { return {value: e.total, name: e[label]}}),
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "300px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}

const Piet = withTranslation()(Pie)
export default Piet