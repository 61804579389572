import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { withTranslation } from 'react-i18next'
import { Col, FormFeedback, Input, Modal, ModalBody, ModalHeader, Row, Form } from "reactstrap"
import { useFormik } from 'formik'
import * as Yup from "yup"
import { updateLocations } from '../../../../helpers/backend_helper'

const SortItemsModal = ({ show, onSaveHandler, onCloseClick, subtype, toggle, t }) => {

  const [loadingBtn, setLoadingBtn] = useState(false)

  useEffect(() => {
    if(subtype)
      validation.setFieldValue('locations', subtype.locations, true)
  }, [subtype])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      locations: subtype?.locations?.length > 0 ? subtype?.locations : [],
    },
    validationSchema: Yup.object({
      locations: Yup.array().min(1).of(Yup.object().shape({
        id: Yup.string().required(t("required")),
        sort_order: Yup.number().required(t("required")),
      })),
    }),
    onSubmit: async values => {
      console.log('values',values)

      // update user
      //dispatch(onUpdateUser(updateUser));
      setLoadingBtn(true)
      const updatedLocations = await updateLocations(values.locations)
      console.log(updatedLocations)

      onSaveHandler(updatedLocations)
      setLoadingBtn(false)

      validation.resetForm()
      toggle()
    },
  })

  return (
    <Modal isOpen={show}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Sort items")}
      </ModalHeader>
      <ModalBody className="py-2 px-3">
        <Form
          onSubmit={e => {
            console.log('submiit')
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col>
              <table className='table table-condensed'>
                <thead>
                  <tr>
                    <td>{t('Name')}</td>
                    <td>{t('Sort order')}</td>
                  </tr>
                </thead>
                
              {(validation?.values.locations || []).map( (e, idx) => { return (
                <tr key={e.id}>
                  <td>
                      {e.name}
                  </td>
                  <td>
                    <Input
                      style={{padding: '5px 20px 5px 5px'}}
                      name="sort_order"
                      type="number"
                      onChange={(evt) => {validation.setFieldValue(`locations[${idx}].sort_order`, evt.target.value, true)}}
                      onBlur={() => {validation.setFieldTouched(`locations[${idx}].sort_order`, true)}}
                      value={e.sort_order || ""}
                      invalid={
                        validation.touched.locations?.[idx]?.sort_order &&
                          validation.errors.locations?.[idx]?.sort_order
                          ? true
                          : false
                      }
                    />
                    {validation.touched.locations?.[idx]?.sort_order &&
                      validation.errors.locations?.[idx]?.sort_order ? (
                      <FormFeedback type="invalid">
                        {validation.errors.locations?.[idx]?.sort_order}
                      </FormFeedback>
                    ) : null}
                  </td>
                </tr>
              ) })}
              </table>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-center mt-3 pb-4">
                <button
                    type="submit"
                    className="btn btn-primary btn-md save-user"
                  >
                    <i className="fas fa-save"></i> {t("Save")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ms-2"
                  onClick={onCloseClick}
                >
                  {t("Close")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

SortItemsModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveHandler: PropTypes.func,
  toggle: PropTypes.func,
  show: PropTypes.any,
  subtype: PropTypes.object.required
}

export default withTranslation()(SortItemsModal)
