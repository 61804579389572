import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import ClipLoader from "react-spinners/ClipLoader";
import './custom.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'


const TableContainer3 = ({
  columns,
  data,
  handleNewItemClick,
  handleSearchBtnClick,
  handlePageBtnClick,
  handleUpdateClick,
  addBtnText,
  loading,
  pageOptions,
  handleColumnSort,
  sortable
}) => {
  const { t, i18n } = useTranslation()
  
  const disableAdd = addBtnText ? false:true
  const [search, setSearch] = useState('');
  const [color, setColor] = useState("#f46a6a");

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  return (
    <React.Fragment>

      <Row className="mb-2">
        <Col sm="4">
        <div className="app-search d-none d-lg-block" style={{paddingTop: '5px'}}>
          <div className="position-relative">
            <input type="text" className="form-control" placeholder={t("Search")+'...'} 
                onKeyUp={(evt) => { setSearch(evt.currentTarget.value) }}
                onKeyPress={(evt) => { if (event.key === 'Enter') { handleSearchBtnClick(search) }}}
            />
            <button className="btn btn-primary" type="button" onClick={() => { handleSearchBtnClick(search) } }>
              <i className="bx bx-search-alt align-middle"></i>
            </button>
          </div>
        </div>
        </Col>
        { !disableAdd &&
        <Col sm="8">
          <div className="text-sm-end" style={{paddingTop: '5px'}}>
            <Button
              type="button"
              color="primary"
              className="btn btn-md mb-2 me-2"
              onClick={handleUpdateClick}
            >
                <i className='fas fa-sync'></i> {t("Update")}
            </Button>
            <Button
              type="button"
              color="primary"
              className="btn btn-md mb-2 me-2"
              onClick={handleNewItemClick}
            >
              <i className="fas fa-plus"></i> { addBtnText }
            </Button>
          </div>
        </Col>
        }
      </Row>

      <Row className="mb-4">
        <Col xl="12">
          <div className="">
            <BootstrapTable
              remote
              sortable={sortable}
              keyField={"id"}
              data = {data}
              columns={columns}
              responsive
              bordered={true}
              striped={true}
              headerClasses="table-light"
              pagination={ paginationFactory({ 
                page: pageOptions.page,
                sizePerPage: pageOptions.sizePerPage,
                totalSize: pageOptions.totalSize,
                hideSizePerPage: true,
                showTotal: true,
                paginationTotalRenderer: (from, to, size) => (
                  <span className="react-bootstrap-table-pagination-total">
                    {t("Showing")} { from } {t("to")} { to } {t("of")} { size } {t("results")}
                  </span>
                )
              })}
              onTableChange={ (type, { page, sortField, sortOrder  }) => { if(type==='sort') handleColumnSort(page, sortField, sortOrder); else handlePageBtnClick(page) } }
              noDataIndication={ loading ? <ClipLoader color={color} loading={loading} size={30} /> : t('No data') }
              showRightSidebarAction={false}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

TableContainer3.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  handleNewItemClick: PropTypes.func,
  handleSearchBtnClick: PropTypes.func,
  handlePageBtnClick: PropTypes.func,
  handleUpdateClick: PropTypes.func,
  addBtnText: PropTypes.string,
  loading: PropTypes.bool,
  pageOptions: PropTypes.object,
  handleColumnSort: PropTypes.func,
  sortable: PropTypes.bool,
};

export default TableContainer3;
