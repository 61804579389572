import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from 'react-select';

//redux
import { withTranslation } from 'react-i18next';
import { addClient, addSubAdmi, deleteUser, getClients, getCountriesSelect, getIncidentTypes, getIncidentTypesSelect, getSubAdmins, putUserResetPassword, updateClient, updateEntityIncidentTypes, updateSubAdmi } from '../../../helpers/backend_helper';
import TableContainer2 from '../../../components/Common/TableContainer2';

import '../../../components/Common/custom.css';
import classNames from "classnames";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactSelect from "react-select";

const IncidentTypesTab = props => {
  const t = props.t;
  const masterItemName = t('Incident Type');
  const masterItemNamePlural = t('Incident Types');

  //meta title
  document.title = masterItemName + t('List') + " | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    console.log('console.log(getIncidentTypes)')
    const response = await getIncidentTypes({params: {...tableOpts, entityId: props.entity.id}}) 
    console.log('Response', response)
    setItems(response.data)
    setItem(response.data.map(e => { return {value: e.id, label: e.name}}))
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  const fetchIncidentTypes = async () => {
    const response = await getIncidentTypesSelect()
    console.log(response)
    setIncidentTypes(response.map(e => {return {value: e.id, label: e.name };}))
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])


  useEffect(() => {
    fetchIncidentTypes()
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: item,
    },
    validationSchema: Yup.object({
      type: Yup.array().min(1).of(Yup.object().shape({
        value: Yup.string().required(t("required")),
      })),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          incidentTypes: values.type.map(e => { return {id: e.value} }),
        };

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateEntityIncidentTypes(props.entity.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm();
      } else {
        const newItem = {
          incidentTypes: values.type.map(e => { return {id: e.value} }),
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await updateEntityIncidentTypes(props.entity.id, newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm();
      }
      toggle();
    },
  });

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1 d-none"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'name',
      text: t('Name'),
      sort: true
    }, {
      dataField: 'incidentSubtypes.length',
      text: t('Sub Types'),
      sort: true
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];


  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteUser(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleResetPass = async () => {
    const response = await putUserResetPassword(item.masterUser.id)
    setConfirmModal(false);
  };

  const handleItemCreateClick = () => {
    //setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const history = useHistory();
  const handleItemDetailClick = row => {
    history.push("/managment/clients/detail/" + row.id);
  };

  const handleItemEditClick = (row) => {
    setItem(row);
    setIsEdit(true);
    toggle();
  };

  const handleResetPasswordClick = (row) => {
    setItem(row);
    setConfirmModal(true);
  };

  console.log('tableOpts', tableOpts)
  console.log('incidentTypes', validation.values)
  console.log('item', item)

  console.log('validation', validation.errors)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ConfirmModal
        show={confirmModal}
        onConfirmClick={handleResetPass}
        onCloseClick={() => setConfirmModal(false)}
        text={t('Do you want reset password?')}
      />

              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }

                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal} >
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Type')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation.errors.type?true:false})}
                                      name="type"
                                      options={incidentTypes}
                                      isMulti
                                      value={validation.values.type || null}
                                      onChange={(option, actionMeta) => { validation.setFieldValue('type', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('type', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation.touched.type &&
                                      validation.errors.type ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.type}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
    </React.Fragment>
  )
}

export default withTranslation()(IncidentTypesTab)
