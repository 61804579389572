import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import CreatableSelect from 'react-select/creatable';

//redux
import { withTranslation } from 'react-i18next';
import { addEntity, deleteEntity, getClientGroupEntitiesSelect, getCountriesPhoneSelect, getEntities, putUserResetPassword, updateEntity, getEntityLinkedUsersCount, getConfigs } from '../../helpers/backend_helper';
import TableContainer2 from '../../components/Common/TableContainer2';

import '../../components/Common/custom.css';
import classNames from "classnames";
import ConfirmModal from "../../components/Common/ConfirmModal";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import getAuthUser from "../../components/helpers/auth";


const EntitiesList = props => {
  const currentUser = getAuthUser()
  const t = props.t;
  const masterItemName = t('Entity');
  const masterItemNamePlural = t('Entities');

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [minDigitsPhone, setMinDigitsPhone] = useState([]);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [countries, setCountries] = useState([]);
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);
  const [groups, setGroups] = useState([]);
  const [linkedUsers, setLinkedUsers] = useState('');

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getEntities({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  const fetchCountries = async () => {
    const response = await getCountriesPhoneSelect()
    setCountries(response.map(e => {return {value: e.dialing_prefix, label: '+' + e.dialing_prefix + " | " + e.name };}))
    const labelDefault = response.find(e => {return e.id == currentUser.countryId})
    validation.setValues({ 
      ...validation.values,
      phone: { 
        ...validation.values.phone, 
        country_code: {
          value: labelDefault.dialing_prefix,
          label: '+' + labelDefault.dialing_prefix + ' | ' + labelDefault.name
        }
      }
    })
  }

  const fetchGroups = async () => {
    console.log(currentUser)
    const response = await getClientGroupEntitiesSelect(currentUser.clientId)
    setGroups(response.map(e => {return {value: e.id, label: e.name };}))
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  useEffect(() => {
    fetchCountries()
    fetchGroups()
  }, [])

  const fetchConfigs = async () => {
    setLoading(true)
    const configs = await getConfigs()
    const minPhoneDigits = configs.filter(e => { return e.name=='MIN_PHONE_DIGITS'})
    setMinDigitsPhone(Number(minPhoneDigits.length > 0 ? minPhoneDigits[0].value : 8))
    setLoading(false)
  }

  useEffect(() => {
    fetchConfigs()
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      groups: (item?.groups && item.groups.map( e => { return {value: e.id, label: e.name}})) || [],
      name: (item && item.name) || "",
      description: (item && item.description) || "",
      email: (item && item.email) || "",
      usePassword: (item && item.password) ? true:false,
      password: (item && item.password) || "",
      active: (item && 'active' in item) ? item.active : true,
      adBlocking: (item && item.adBlocking) ? true : false,
      address: { 
        line1: (item?.address && item.address.line1) || '' ,
        number: (item?.address && item.address.number) || '',
        locality: (item?.address && item.address.locality) || '',
        zip: (item?.address && item.address.zip) || '' 
      },
      phone: { 
        country_code: (item?.phone?.country_code && {value: item.phone.country_code , label: '+'+item.phone.country_code}) || '' ,
        area_code: (item?.phone && item.phone.area_code) || '',
        number: (item?.phone && item.phone.number) || '',
      },
      allowAnonymous: (item && 'allowAnonymous' in item) ? item.allowAnonymous : false,
    },
    validationSchema: Yup.object({
      groups: Yup.array().required(t("required")).min(1, t("must have at least 1 item")),
      name: Yup.string().required(t("required")),
      description: Yup.string().required(t("required")),
      email: Yup.string().required(t("required")),
      usePassword: Yup.boolean().required(t("required")),
      password: Yup.string().when("usePassword", {
        is: true,
        then: Yup.string().required(t("required"))
      }),
      address: Yup.object().shape({
        line1: Yup.string().required(t("required")),
        number: Yup.string().required(t("required")),
        locality: Yup.string().required(t("required")),
        zip: Yup.string().required(t("required"))
      }),
      phone: Yup.object().shape({
        country_code: Yup.object().shape({
          value: Yup.string().required(t("required"))
        }),
        area_code: Yup.string().max(5),
        number: Yup.string().test(
          'len',
          t('must be at least NUMBER digits').replace('NUMBER', minDigitsPhone),
          (value, obj) => { 
            return ((obj.parent.area_code?.length ?? 0) + (obj.parent.number?.length ?? 0)) >= minDigitsPhone
        }),
      })
    }),
    onSubmit: async values => {
      console.log('area_code',values.phone.area_code, values.area_code ? values.area_code.toString():'')
      if (isEdit) {
        const updateItem = {
          ...values,
          id: item.id,
          groups: values.groups.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          active: values.active ? values.active : false,
          adBlocking: values.adBlocking ? values.adBlocking : false,
          phone: {
            ...values.phone,
            area_code: values.phone.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone.country_code.value.toString(),
            number: values.phone.number.toString()
          },
          password: values.usePassword ? values.password : '',
        }

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateEntity(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm();
      } else {
        const newItem = {
          ...values,
          groups: values.groups.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          active: values.active ? values.active : false,
          adBlocking: values.adBlocking ? values.adBlocking : false,
          phone: {
            ...values.phone,
            area_code: values.phone.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone.country_code.value.toString(),
            number: values.phone.number.toString()
          },
          password: values.usePassword ? values.password : '',
        }
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addEntity(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm();
      }
      toggle();
    },
  });

  console.log(minDigitsPhone, 'minDigitsPhone')

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemViewClick(row); } }
          title={t("View and edit details")}>
            <i className="fas fa-eye"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t("Edit") + ' ' + t('Base data')}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row); } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'groups.name',
      text: t('Groups'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.groups ?  row.groups.map( t => t.name).join(', '):'' }
    }, 
    {
      dataField: 'name',
      text: t('Name'),
      sort: true
    }, {
      dataField: 'email',
      text: t('Email'),
      sort: true
    }, {
      dataField: 'active',
      text: t('Active'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.active ? t('Yes'):t('No') }
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];


  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalAsk, setDeleteModalAsk] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteEntity(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleDeleteUserAsk = async () => {
    const response = await getEntityLinkedUsersCount(item.id)
    setDeleteModalAsk(false);
    if(response.countLinkedUsers == 0) {
      setLinkedUsers('')
      const response = await deleteEntity(item.id)
      fetchData()
    } else {
      setLinkedUsers(response.countLinkedUsers)
      setDeleteModal(true);
    }
  };

  const handleResetPass = async () => {
    const response = await putUserResetPassword(item.masterUser.id)
    setConfirmModal(false);
  };

  const handleItemCreateClick = () => {
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModalAsk(true);
  };

  const handleItemEditClick = (row) => {
    validation.resetForm()
    setItem(row);    
    setIsEdit(true);
    toggle();
  };

  const history = useHistory();
  const handleItemViewClick = (row) => {
    history.push("/managment/entities/detail/" + row.id);
  };

  console.log('tableOpts', tableOpts)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModalAsk}
        onDeleteClick={handleDeleteUserAsk}
        onCloseClick={() => setDeleteModalAsk(false)}
        message={t(`delete_entity_message_1`).replace('ENTITY', item?.name)}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        message={t(`delete_entity_message_2`).replace('USERS', linkedUsers)}
      />

      <ConfirmModal
        show={confirmModal}
        onConfirmClick={handleResetPass}
        onCloseClick={() => setConfirmModal(false)}
        text={t('Do you want reset password?')}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }

                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal} style={{maxWidth: '80%', width: '100%'}}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName} - { t("Base data")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Row>
                                    <Col xs={6}>
                                      <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> {t('Entity')}</h5>
                                      <div className="mb-3">
                                        <Label className="form-Label">{t('Grupo')}</Label>
                                        <CreatableSelect
                                          className={classNames({'is-invalid': validation.touched.groups && validation.errors.groups?true:false})}
                                          name="groups"
                                          options={groups}
                                          isMulti
                                          formatCreateLabel={(inputValue) => {
                                            return t("Create") + " \"".concat(inputValue, "\"");
                                          }}
                                          value={validation.values.groups || []}
                                          onChange={(option, actionMeta) => { console.log(option), validation.setFieldValue('groups', option, true)}}
                                          onBlur={ () => { console.log('blur'); validation.setFieldTouched('groups', true, true)} }
                                          placeholder={t('Select or type new')+'...'}
                                        />
                                        {validation.touched.groups &&
                                          validation.errors.groups ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.groups}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Name")}</Label>
                                        <Input
                                          name="name"
                                          type="text"
                                          maxLength={60}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.name || ""}
                                          invalid={
                                            validation.touched.name &&
                                              validation.errors.name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.name &&
                                          validation.errors.name ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.name}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Description")}</Label>
                                        <Input
                                          name="description"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.description || ""}
                                          invalid={
                                            validation.touched.description &&
                                              validation.errors.description
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.description &&
                                          validation.errors.description ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.description}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Email")}</Label>
                                        <Input
                                          name="email"
                                          type="email"
                                          maxLength={60}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.email || ""}
                                          invalid={
                                            validation.touched.email &&
                                              validation.errors.email
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.email &&
                                          validation.errors.email ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.email}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="pt-2 mb-3">
                                        <Label className="form-label">{t("Use Password")}</Label> <Input
                                            name="usePassword"
                                            label="active"
                                            type="checkbox"
                                            onChange={(evt) => {}}
                                            onClick={(evt) => { validation.setFieldValue('usePassword', !evt.target.checked , true)}}
                                            checked={validation.values.usePassword}
                                            invalid={
                                              validation.touched.usePassword &&
                                                validation.errors.usePassword
                                                ? true
                                                : false
                                            }
                                          />
                                      </div>
                                      { validation.values.usePassword &&
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Password")}</Label>
                                        <Input
                                          name="password"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.password || ""}
                                          invalid={
                                            validation.touched.password &&
                                              validation.errors.password
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.password &&
                                          validation.errors.password ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.password}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      }
                                      <div className="pt-2 mb-3">
                                        <Label className="form-label">{t("Active")}</Label> <Input
                                            name="active"
                                            label="active"
                                            type="checkbox"
                                            onChange={(evt) => {}}
                                            onClick={(evt) => { validation.setFieldValue('active', !evt.target.checked , true)}}
                                            checked={validation.values.active}
                                            invalid={
                                              validation.touched.active &&
                                                validation.errors.active
                                                ? true
                                                : false
                                            }
                                          />
                                      </div>
                                      <div className="pt-2 mb-3">
                                        <Label className="form-label">{t("Allow incidents except Tellit to be sent anonymously")}</Label> <Input
                                            name="allowAnonymous"
                                            label="allowAnonymous"
                                            type="checkbox"
                                            onChange={(evt) => {}}
                                            onClick={(evt) => { validation.setFieldValue('allowAnonymous', !evt.target.checked , true)}}
                                            checked={validation.values.allowAnonymous}
                                            invalid={
                                              validation.touched.allowAnonymous &&
                                                validation.errors.allowAnonymous
                                                ? true
                                                : false
                                            }
                                          />
                                      </div>
                                      <div className="pt-2 mb-3">
                                        <Label className="form-label">{t("AdBlocking")}</Label> <Input
                                            name="adBlocking"
                                            label="adBlocking"
                                            type="checkbox"
                                            onChange={(evt) => {}}
                                            onClick={(evt) => { validation.setFieldValue('adBlocking', !evt.target.checked , true)}}
                                            checked={validation.values.adBlocking}
                                            invalid={
                                              validation.touched.adBlocking &&
                                                validation.errors.adBlocking
                                                ? true
                                                : false
                                            }
                                          />
                                      </div>
                                    </Col>
                                    <Col xs={6}>
                                      <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> {t('Address')}</h5>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Street")}</Label>
                                        <Input
                                          name="address.line1"
                                          type="text"
                                          maxLength={255}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.address?.line1 || ""}
                                          invalid={
                                            validation.touched.address?.line1 &&
                                              validation.errors.address?.line1
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.address?.line1 &&
                                          validation.errors.address?.line1 ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.address?.line1}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("House Number")}</Label>
                                        <Input
                                          name="address.number"
                                          type="text"
                                          maxLength={20}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.address?.number || ""}
                                          invalid={
                                            validation.touched.address?.number &&
                                              validation.errors.address?.number
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.address?.number &&
                                          validation.errors.address?.number ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.address?.number}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Zipcode")}</Label>
                                        <Input
                                          name="address.zip"
                                          type="text"
                                          maxLength={6}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.address?.zip || ""}
                                          invalid={
                                            validation.touched.address?.zip &&
                                              validation.errors.address?.zip
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.address?.zip &&
                                          validation.errors.address?.zip ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.address?.zip}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Locality")}</Label>
                                        <Input
                                          name="address.locality"
                                          type="text"
                                          maxLength={50}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.address?.locality || ""}
                                          invalid={
                                            validation.touched.address?.locality &&
                                              validation.errors.address?.locality
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.address?.locality &&
                                          validation.errors.address?.locality ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.address?.locality}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <h5 className="font-size-14 mt-5 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> {t('Phone')}</h5>
                                      <div className="mb-3">
                                        <Label className="form-Label">{t('Country Code')}</Label>
                                        <Select
                                          className={classNames({'is-invalid': validation.errors.countryId?true:false})}
                                          name="phone.country_code.value"
                                          options={countries}
                                          value={validation.values.phone.country_code || null}
                                          onChange={(option, actionMeta) => { validation.setFieldValue('phone.country_code', option, true)}}
                                          onBlur={ () => { validation.setFieldTouched('phone.country_code', true, true)} }
                                          placeholder={t('Select')+'...'}
                                        />
                                        {validation.touched.phone?.country_code?.value &&
                                          validation.errors.phone?.country_code?.value ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.phone?.country_code?.value}
                                          </FormFeedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                      <Label className="form-label">{t("Area Code")}</Label>
                                        <Input
                                          name="phone.area_code"
                                          type="number"
                                          maxLength={5}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.phone?.area_code || ""}
                                          invalid={
                                            validation.touched.phone?.area_code &&
                                              validation.errors.phone?.area_code
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.phone?.area_code &&
                                          validation.errors.phone?.area_code ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.phone?.area_code}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Phone number")}</Label>
                                        <Input
                                          name="phone.number"
                                          type="number"
                                          maxLength={10}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.phone?.number || ""}
                                          invalid={
                                            validation.touched.phone?.number &&
                                              validation.errors.phone?.number
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.phone?.number &&
                                          validation.errors.phone?.number ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.phone?.number}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EntitiesList)
