import axios from "axios"
import { del, get, post, postFile, put } from "./api_helper"
import * as url from "./url_helper"

const API_URL = process.env.REACT_APP_TELLIT_API_URL

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

export const postLogin = (data) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  
  return axios
    .post(API_URL + url.POST_LOGIN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      console.log('err', err);
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = err
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      throw message
    })
}

export const postForgetPwd = (config) => post(url.POST_RESET_PASSWORD, config)
export const postUpdatePassword = (config) => post(url.BASE_USERS+'/change-password', config)
export const getProfile = () => get(url.ADD_NEW_EVENT)
export const postRegister = () => post(url.ADD_NEW_EVENT)


export const postImage = (formData, config) => postFile(url.BASE_ASSET, formData, {
  ...config,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export const getConfigs = () => get(url.BASE_CONFIGS)
export const setConfigs = (item, config) => put(`${url.BASE_CONFIGS}`, item, config)

// get Users
export const getUsers = (config) => get(url.BASE_USERS, config)
export const addUser = (config) => post(url.BASE_USERS, config)
export const updateUser = (id, item, config) => put(`${url.BASE_USERS}/${id}`, item, config)
export const deleteUser = (id, item, config) => del(`${url.BASE_USERS}/${id}`, config)
export const putUserResetPassword = (id, config) => put(`${url.BASE_USERS}/${id}/reset-password`, {}, config)

export const getSponsors = (config) => get(url.BASE_SPONSORS, config)
export const getSponsor = (id, config) => get(`${url.BASE_SPONSORS}/${id}`, config)
export const addSponsor = (config) => post(url.BASE_SPONSORS, config)
export const updateSponsor = (id, item, config) => put(`${url.BASE_SPONSORS}/${id}`, item, config)
export const deleteSponsor = (id, item, config) => del(`${url.BASE_SPONSORS}/${id}`, config)

export const getSponsorsContracts = (config) => get(url.BASE_SPONSORS_CONTRACTS, config)
export const getSponsorsContract = (id, config) => get(`${url.BASE_SPONSORS_CONTRACTS}/${id}`, config)
export const addSponsorsContracts = (config) => post(url.BASE_SPONSORS_CONTRACTS, config)
export const updateSponsorsContracts = (id, item, config) => put(`${url.BASE_SPONSORS_CONTRACTS}/${id}`, item, config)
export const deleteSponsorsContracts = (id, item, config) => del(`${url.BASE_SPONSORS_CONTRACTS}/${id}`, config)
export const getSponsorsContractsSelect = (config) => get(url.BASE_SPONSORS_CONTRACTS+'/select', config)

export const getSponsorsAdvertisments = (config) => get(url.BASE_SPONSORS_ADVERTISMENTS, config)
export const getSponsorsAdvertisment = (id, config) => get(`${url.BASE_SPONSORS_ADVERTISMENTS}/${id}`, config)
export const addSponsorsAdvertisments = (config) => post(url.BASE_SPONSORS_ADVERTISMENTS, config)
export const updateSponsorsAdvertisments = (id, item, config) => put(`${url.BASE_SPONSORS_ADVERTISMENTS}/${id}`, item, config)
export const deleteSponsorsAdvertisments = (id, item, config) => del(`${url.BASE_SPONSORS_ADVERTISMENTS}/${id}`, config)

export const getProviders = (config) => get(url.BASE_PROVIDERS, config)
export const addProvider = (config) => post(url.BASE_PROVIDERS, config)
export const updateProvider = (id, item, config) => put(`${url.BASE_PROVIDERS}/${id}`, item, config)
export const deleteProvider = (id, item, config) => del(`${url.BASE_PROVIDERS}/${id}`, config)

export const getClients = (config) => get(url.BASE_CLIENTS, config)
export const getClientsSelect = (config) => get(url.BASE_CLIENTS+'/select', config)
export const getClient = (id, config) => get(`${url.BASE_CLIENTS}/${id}`, config)
export const getClientGroupEntitiesSelect = (id, config) => get(`${url.BASE_CLIENTS}/${id}/select-entity-groups`, config)
export const addClient = (config) => post(url.BASE_CLIENTS, config)
export const updateClient = (id, item, config) => put(`${url.BASE_CLIENTS}/${id}`, item, config)
export const updateClientConfig = (id, item, config) => put(`${url.BASE_CLIENTS}/${id}/configuration`, item, config)
export const deleteClient = (id, item, config) => del(`${url.BASE_CLIENTS}/${id}`, config)

export const getSubAdmins = (config) => get(url.BASE_SUBADMINS, config)
export const getSubAdmi = (id, config) => get(`${url.BASE_SUBADMINS}/${id}`, config)
export const addSubAdmi = (config) => post(url.BASE_SUBADMINS, config)
export const updateSubAdmi = (id, item, config) => put(`${url.BASE_SUBADMINS}/${id}`, item, config)
export const deleteSubAdmi = (id, item, config) => del(`${url.BASE_SUBADMINS}/${id}`, config)

export const getMasterAdmins = (config) => get(url.BASE_MASTERADMINS, config)
export const getMasterAdmin = (id, config) => get(`${url.BASE_MASTERADMINS}/${id}`, config)
export const addMasterAdmin = (config) => post(url.BASE_MASTERADMINS, config)
export const updateMasterAdmin = (id, item, config) => put(`${url.BASE_MASTERADMINS}/${id}`, item, config)
export const deleteMasterAdmin = (id, item, config) => del(`${url.BASE_MASTERADMINS}/${id}`, config)

export const getInternalUsers = (config) => get(url.BASE_INTERNAL_USERS, config)
export const getInternalUser = (id, config) => get(`${url.BASE_INTERNAL_USERS}/${id}`, config)
export const addInternalUser = (config) => post(url.BASE_INTERNAL_USERS, config)
export const updateInternalUser = (id, item, config) => put(`${url.BASE_INTERNAL_USERS}/${id}`, item, config)
export const deleteInternalUser = (id, item, config) => del(`${url.BASE_INTERNAL_USERS}/${id}`, config)

export const getResponseTeamUsers = (config) => get(url.BASE_RESPONSE_TEAMS_USER, config)
export const getResponseTeamUsersSelect = (config) => get(url.BASE_RESPONSE_TEAMS_USER+'/select', config)
export const getResponseTeamUser = (id, config) => get(`${url.BASE_RESPONSE_TEAMS_USER}/${id}`, config)
export const addResponseTeamUser = (config) => post(url.BASE_RESPONSE_TEAMS_USER, config)
export const updateResponseTeamUser = (id, item, config) => put(`${url.BASE_RESPONSE_TEAMS_USER}/${id}`, item, config)
export const deleteResponseTeamUser = (id, item, config) => del(`${url.BASE_RESPONSE_TEAMS_USER}/${id}`, config)

export const getResponseTeams = (config) => get(url.BASE_RESPONSE_TEAMS, config)
export const getTeamsSelect = (config) => get(url.BASE_RESPONSE_TEAMS+'/team-select', config)
export const getResponseTeam = (id, config) => get(`${url.BASE_RESPONSE_TEAMS}/${id}`, config)
export const addResponseTeam = (config) => post(url.BASE_RESPONSE_TEAMS, config)
export const updateResponseTeam = (id, item, config) => put(`${url.BASE_RESPONSE_TEAMS}/${id}`, item, config)
export const deleteResponseTeam = (id, item, config) => del(`${url.BASE_RESPONSE_TEAMS}/${id}`, config)

export const getEntities = (config) => get(url.BASE_ENTITIES, config)
export const getEntitiesSelect = (config) => get(url.BASE_ENTITIES+'/select', config)

export const getEntity = (id, config) => get(`${url.BASE_ENTITIES}/${id}`, config)
export const addEntity = (config) => post(url.BASE_ENTITIES, config)
export const updateEntity = (id, item, config) => put(`${url.BASE_ENTITIES}/${id}`, item, config)
export const deleteEntity = (id, item, config) => del(`${url.BASE_ENTITIES}/${id}`, config)
export const updateEntityIncidentTypes = (id, item, config) => put(`${url.BASE_ENTITIES}/${id}/incident-types`, item, config)
export const getEntityIncidentTypesWithSubtypesSelect = (id, config) => get(`${url.BASE_ENTITIES}/${id}/incident-types-with-subtypes/select`, config)
export const updateEntityLocations = (id, item, config) => put(`${url.BASE_ENTITIES}/${id}/locations`, item, config)
export const updateEntityLocation = (id, locationId, item, config) => put(`${url.BASE_ENTITIES}/${id}/locations/${locationId}`, item, config)
export const addClientIncidentSubTypeToEntity = (id, item, config) => post(`${url.BASE_ENTITIES}/${id}/incident-subtypes`, item, config)
export const getEntityLocations = (id, config) => get(`${url.BASE_ENTITIES}/${id}/locations`, config)
export const getEntityLinkedUsersCount = (id, config) => get(`${url.BASE_ENTITIES}/${id}/linked-users-count`, config)
export const updateEntityIncidentSubtype = (id, subtypeId, item, config) => put(`${url.BASE_ENTITIES}/${id}/incident-subtypes/${subtypeId}`, item, config)
export const updateEntityIncidentType = (id, incidentTypeId, item, config) => put(`${url.BASE_ENTITIES}/${id}/incident-type/${incidentTypeId}`, item, config)

export const getIncidentsActive = (config) => get(url.BASE_INCIDENTS+'/active', config)
export const getIncidentsInactive = (config) => get(url.BASE_INCIDENTS+'/inactive', config)
export const getIncident = (id, config) => get(`${url.BASE_INCIDENTS}/${id}`, config)
export const addIncident = (config) => post(url.BASE_INCIDENTS, config)
export const updateIncident = (id, item, config) => put(`${url.BASE_INCIDENTS}/${id}`, item, config)
export const deleteIncident = (id, item, config) => del(`${url.BASE_INCIDENTS}/${id}`, config)
export const addIncidentAttachments = (id, item, config) => post(`${url.BASE_INCIDENTS}/${id}/evidences`, item, config)
export const addIncidentComment = (id, item, config) => post(`${url.BASE_INCIDENTS}/${id}/attach-comment`, item, config)

export const getIncidentsFilters = (config) => get(url.BASE_INCIDENTS + '/filters', config)
export const getViewsFilters = (config) => get(url.BASE_SPONSORS + '/filters', config)

export const getDivisions = (config) => get(url.BASE_DIVISIONS, config)
export const getDivision = (id, config) => get(`${url.BASE_DIVISIONS}/${id}`, config)
export const addDivision = (config) => post(url.BASE_DIVISIONS, config)
export const updateDivision = (id, item, config) => put(`${url.BASE_DIVISIONS}/${id}`, item, config)
export const deleteDivision = (id, item, config) => del(`${url.BASE_DIVISIONS}/${id}`, config)

export const getIncidentTypes = (config) => get(url.BASE_INCIDENT_TYPES, config)
export const getIncidentTypesSelect = (config) => get(url.BASE_INCIDENT_TYPES + '/select', config)

export const getIncidentType = (id, config) => get(`${url.BASE_INCIDENT_TYPES}/${id}`, config)
export const addIncidentType = (config) => post(url.BASE_INCIDENT_TYPES, config)
export const updateIncidentType = (id, item, config) => put(`${url.BASE_INCIDENT_TYPES}/${id}`, item, config)
export const deleteIncidentType = (id, item, config) => del(`${url.BASE_INCIDENT_TYPES}/${id}`, config)

export const getIncidentSubtypes = (config) => get(url.BASE_INCIDENT_SUBTYPES, config)
export const getIncidentSubtypesSelect = (config) => get(url.BASE_INCIDENT_SUBTYPES + '/select', config)

export const getIncidentSubtype = (id, config) => get(`${url.BASE_INCIDENT_SUBTYPES}/${id}`, config)
export const addIncidentSubtype = (config) => post(url.BASE_INCIDENT_SUBTYPES, config)
export const updateIncidentSubtype = (id, item, config) => put(`${url.BASE_INCIDENT_SUBTYPES}/${id}`, item, config)
export const deleteIncidentSubtype = (id, item, config) => del(`${url.BASE_INCIDENT_SUBTYPES}/${id}`, config)

export const getLocations = (config) => get(url.BASE_INCIDENT_LOCATIONS, config)
export const getLocationSelect = (config) => get(url.BASE_INCIDENT_LOCATIONS + '/select', config)
export const getLocation = (id, config) => get(`${url.BASE_INCIDENT_LOCATIONS}/${id}`, config)
export const addLocation = (config) => post(url.BASE_INCIDENT_LOCATIONS, config)
export const updateLocation = (id, item, config) => put(`${url.BASE_INCIDENT_LOCATIONS}/${id}`, item, config)
export const updateLocations = (itemArray, config) => put(`${url.BASE_INCIDENT_LOCATIONS}`, { locations: itemArray }, config)
export const deleteLocation = (id, item, config) => del(`${url.BASE_INCIDENT_LOCATIONS}/${id}`, config)

// get Countries
export const getCountries = (config) => get(url.BASE_COUNTRIES, config)
export const getCountry = (id, config) => get(`${url.BASE_COUNTRIES}/${id}`, config)
export const getCountriesSelect = (config) => get(url.BASE_COUNTRIES+'/select', config)
export const getCountriesPhoneSelect = (config) => get(url.BASE_COUNTRIES+'/select-phone', config)
export const addCountry = (config) => post(url.BASE_COUNTRIES, config)
export const updateCountry = (id, item, config) => put(`${url.BASE_COUNTRIES}/${id}`, item, config)
export const deleteCountry = (id, item, config) => del(`${url.BASE_COUNTRIES}/${id}`, config)


// get Languages
export const getLanguages = (config) => get(url.BASE_LANGUAGES, config)
export const getLanguagesSelect = (config) => get(url.BASE_LANGUAGES + '/select', config)
export const addLanguage = (config) => post(url.BASE_LANGUAGES, config)
export const updateLanguage = (id, item, config) => put(`${url.BASE_LANGUAGES}/${id}`, item, config)
export const deleteLanguage = (id, item, config) => del(`${url.BASE_LANGUAGES}/${id}`, config)


// get EmailTemplates
export const getEmailTemplates = (config) => get(url.BASE_EMAIL_TEMPLATES, config)
export const addEmailTemplate = (config) => post(url.BASE_EMAIL_TEMPLATES, config)
export const updateEmailTemplate = (id, item, config) => put(`${url.BASE_EMAIL_TEMPLATES}/${id}`, item, config)
export const deleteEmailTemplate = (id, item, config) => del(`${url.BASE_EMAIL_TEMPLATES}/${id}`, config)

// get ClientAreas
export const getClientAreas = (config) => get(url.BASE_CLIENT_AREAS, config)
export const getClientAreasSelect = (config) => get(url.BASE_CLIENT_AREAS + '/select', config)
export const addClientArea = (config) => post(url.BASE_CLIENT_AREAS, config)
export const updateClientArea = (id, item, config) => put(`${url.BASE_CLIENT_AREAS}/${id}`, item, config)
export const deleteClientArea = (id, item, config) => del(`${url.BASE_CLIENT_AREAS}/${id}`, config)


// get Translates
export const getTranslates = (config) => get(url.BASE_TRANSLATES, config)
export const addTranslate = (config) => post(url.BASE_TRANSLATES, config)
export const updateTranslate = (id, item, config) => put(`${url.BASE_TRANSLATES}/${id}`, item, config)
export const deleteTranslate = (id, item, config) => del(`${url.BASE_TRANSLATES}/${id}`, config)


// get Installations
export const getInstallation = (id, config) => get(url.BASE_INSTALLATIONS+'/'+id, config)
export const getInstallations = (config) => get(url.BASE_INSTALLATIONS, config)
export const getInstallationsSelect = (config) => get(url.BASE_INSTALLATIONS+'/select', config)
export const addInstallation = (config) => post(url.BASE_INSTALLATIONS, config)
export const updateInstallation = (id, item, config) => put(`${url.BASE_INSTALLATIONS}/${id}`, item, config)
export const deleteInstallation = (id, item, config) => del(`${url.BASE_INSTALLATIONS}/${id}`, config)


// get sections
export const getSection = (id, config) => get(url.BASE_SECTIONS+'/'+id, config)
export const getSections = (config) => get(url.BASE_SECTIONS, config)
export const getSectionsSelect = (config) => get(url.BASE_SECTIONS+'/select', config)
export const addSections = (config) => post(url.BASE_SECTIONS, config)
export const updateSections = (id, item, config) => put(`${url.BASE_SECTIONS}/${id}`, item, config)
export const deleteSections = (id, item, config) => del(`${url.BASE_SECTIONS}/${id}`, config)


// get notices
export const getNotice = (id, config) => get(url.BASE_NOTICES +'/'+id, config)
export const getNotices = (config) => get(url.BASE_NOTICES, config)
export const addNotices = (config) => post(url.BASE_NOTICES, config)
export const updateNotices = (id, item, config) => put(`${url.BASE_NOTICES}/${id}`, item, config)
export const deleteNotices = (id, item, config) => del(`${url.BASE_NOTICES}/${id}`, config)


// Reports
export const getReportIncidentsTypeByEntity = (config) => post(url.BASE_REPORTS +'/incidents-types-by-entity', config)
export const getReportViews = (config) => post(url.BASE_REPORTS +'/views', config)
export const getReportClicks = (config) => post(url.BASE_REPORTS +'/clicks', config)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
}
