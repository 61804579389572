import React, { useState } from 'react';

//Import Icons
import FeatherIcon from "feather-icons-react";

//constants
import { layoutTheme } from "../../../constants/layout";
import { Tooltip } from 'reactstrap';
import { withTranslation } from 'react-i18next';
const LightDark = ({ layoutMode, onChangeLayoutMode, t }) => {
    const mode = layoutMode === layoutTheme['DARKMODE'] ? layoutTheme['LIGHTMODE'] : layoutTheme['DARKMODE'];
    const [tooltip, setTooltip] = useState(false);


    return (
        <>
            <Tooltip
                placement="bottom"
                isOpen={tooltip}
                target="pickModeScreen"
                toggle={() => {
                setTooltip(!tooltip);
                }}
            >  {t('Choose Dark/Light Mode')}
            </Tooltip>
            <div id='pickModeScreen' className="dropdown d-none d-sm-inline-block">
                <button
                    onClick={
                        () => onChangeLayoutMode(mode)
                    }
                    type="button" className="btn header-item">
                    {layoutMode === layoutTheme['DARKMODE'] ?
                        <FeatherIcon
                            icon="sun"
                            className="icon-lg layout-mode-light"
                        />
                        :
                        <FeatherIcon
                            icon="moon"
                            className="icon-lg layout-mode-dark"
                        />
                    }
                </button>
            </div>
        </>
    );
}

export default withTranslation()(LightDark)