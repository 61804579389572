import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { postImage, getIncidentSubtypes, getLocationSelect, getTeamsSelect, addIncidentSubtype, deleteIncidentSubtype, getIncidentTypesSelect, updateIncidentSubtype, getIncidentSubtypesSelect, addClientIncidentSubType, addClientIncidentSubTypeToEntity, updateEntityIncidentSubtype } from '../../../helpers/backend_helper';
import TableContainer2 from '../../../components/Common/TableContainer2';

import '../../../components/Common/custom.css';
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import classNames from "classnames";
import getAuthUser from "../../../components/helpers/auth";
import ReactSelect from "react-select";
import CreatableSelect from 'react-select/creatable';

import Dropzone from "react-dropzone";
import PropTypes from 'prop-types';
import { result } from "lodash";
import TableContainer4 from '../../../components/Common/TableContainer4';
import SortItemsModal from "./components/SortItemsModal";

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const IncidentSubTypesTabNew = ({t, entity}) => {
  const masterItemName = t('Incident Subtype');
  const masterItemNamePlural = t('Incident Subtypes');
  const currentUser = getAuthUser()
  const typeOptions = [
    {value: 'STANDAR', label: 'STANDAR'},
    {value: 'INDIVIDUAL', label: 'INDIVIDUAL'}
  ]
  //meta title
  document.title = masterItemName + "List | Tellit";

  const [locations, setLocations] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [incidentTypesPick, setIncidentTypesPick] = useState([]);
  const [incidentSubTypesPick, setIncidentSubTypesPick] = useState([]);
  const [teams, setTeams] = useState([]);

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [modal, setModal] = useState(false)
  const [modalPick, setModalPick] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);
  const [updateAsset, setUpdateAsset] = useState (false);

  const [steps, setSteps] = useState ([]);
  
  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
    entityId: entity.id

  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getIncidentSubtypes({params: tableOpts}) 
    response.data.forEach( e=> { e.action_steps = JSON.parse(e?.action_steps) })
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  const fetchIncidenTypesSelect = async () => {
    setLoading(true)
    const response = await getIncidentTypesSelect({params: {entityId: entity.id}})
    setIncidentTypes(response.map(e => { return {value: e.id, label: e.name}} ))
    setLoading(false)
  }

  setIncidentTypes
  const fetchLocationsSelect = async () => {
    setLoading(true)
    const response = await getLocationSelect()
    setLocations(response.map(e => { return {value: e.id, label: e.name}} ))
    setLoading(false)
  }

  const fetchResponseTeamsSelect = async () => {
    setLoading(true)
    const response = await getTeamsSelect() 
    setTeams(response.map(e => { return {value: e.id, label: e.name}} ))
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  useEffect(() => {
    fetchIncidenTypesSelect()
    fetchLocationsSelect()
    fetchResponseTeamsSelect()
  }, [])

  const fetchIncidentTypesPick = async () => {
    const response = await getIncidentTypesSelect({
      params:{
        entityId: entity.id,
      }
    })
    console.log(response)
    setIncidentTypesPick(response.map(e => {return {value: e.parentIncidentTypeId, label: e.name, original: e.id }}))
  }

  useEffect(() => {
    fetchIncidentTypesPick()
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      incidentType: (item?.incidentType && {value: item?.incidentType.id, label: item?.incidentType.name}) || "",
      name: (item && item.name) || "",
      sort_order: (item?.sort_order) || "",
      time_backup_response: (item?.time_backup_response) || "",
      max_time_to_response: (item?.max_time_to_response) || "",
      responseTeams: (item?.responseTeams.length > 0 && item.responseTeams.map(e => { return {value: e.id, label: e.name}})) || [],
      backResponseTeam: (item?.backResponseTeam && { value: item?.backResponseTeam.id, label: item?.backResponseTeam.name}) || null,
      entity_locations: (item?.entity_locations) || false,
      locations: (item?.locations.length > 0 && item.locations.map(e => { return {value: e.parentLocationId, label: e.name}})) || [],
      assetId: (item?.assetId) || '',
      action: '',
      action_steps: (item?.action_steps) || {},
      supportMedia: (item) ? (item?.supportMedia) : true,
      requiredSupportMedia: (item?.requiredSupportMedia) || false,
      supportText: (item) ? (item?.supportText) : true,
      requiredSupportText: (item?.requiredSupportText) || false,
      askQuestion: (item?.askQuestion) || false,
      askQuestionMandatory: (item?.askQuestionMandatory) || false,
      specificQuestion: (item?.specificQuestion) || "",
      skipScreenLocation: (item?.skipScreenLocation) || false,
      skipScreenMap: (item?.skipScreenMap) || false,
      skipScreenDate: (item?.skipScreenDate) || false,
      skipScreenSummary: (item?.skipScreenSummary) || false,
      includeExternals: (item?.includeExternals) || false,
      includeEndUsers: (item?.includeEndUsers) || false,
    },
    validationSchema: Yup.object({
      incidentType: Yup.object().shape({value: Yup.string().required(t("required"))}),
      name: Yup.string().required(t("required")),
      time_backup_response: Yup.number().required(t("required")),
      max_time_to_response: Yup.number().required(t("required")),
      responseTeams: Yup.array().min(1).of(Yup.object().shape({
        value: Yup.string().required(t("required")),
      })),
      backResponseTeam: Yup.object().nullable().shape({value: Yup.string()}),
      entity_locations: Yup.bool(),
      locations: Yup.array().when('entity_locations', {
        is: false,
        then: Yup.array().min(1).of(Yup.object().shape({
          value: Yup.string().required(t("required")),
        })),
        otherwise: Yup.array()
      }),
      assetId: Yup.string().required(t("required")),
      action_steps: Yup.object().shape({'0': Yup.string().required(t("required"))}),
      supportMedia: Yup.bool(),
      requiredSupportMedia: Yup.bool(),
      supportText: Yup.bool(),
      requiredSupportText: Yup.bool(),
      askQuestion: Yup.bool(),
      askQuestionMandatory: Yup.bool(),
      specificQuestion: Yup.string().when('askQuestion', {
        is: true,
        then: Yup.string().required(t("required")).min(4),
        otherwise: Yup.string().nullable()
      }),
      skipScreenLocation: Yup.bool(),
      skipScreenMap: Yup.bool(),
      skipScreenDate: Yup.bool(),
      skipScreenSummary: Yup.bool(),
      includeExternals: Yup.bool(),
      includeEndUsers: Yup.bool(),
    }),
    onSubmit: async values => {
      console.log('values',values)
      
      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values,
          incidentTypeId: values.incidentType.value,
          backResponseTeamId: values.backResponseTeam?.value ?? null,
          responseTeams: values.responseTeams.map( e => { return {id: e.value}}),
          locations: values.locations.map( e => { return {id: e.value}}),
          entity_locations: values?.entity_locations ? values?.entity_locations : false,
          action_steps: JSON.stringify(values.action_steps),
          entityId: entity.id,
          supportMedia: values.supportMedia ? true : false,
          requiredSupportMedia: values.requiredSupportMedia ? true : false,
          supportText: values.supportText ? true : false,
          requiredSupportText: values.requiredSupportText ? true : false,
          askQuestion: values.askQuestion ? true : false,
          askQuestionMandatory: values.askQuestionMandatory ? true : false,
          skipScreenLocation: values.skipScreenLocation ? true : false,
          skipScreenMap: values.skipScreenMap ? true : false,
          skipScreenDate: values.skipScreenDate ? true : false,
          skipScreenSummary: values.skipScreenSummary ? true : false,
          includeExternals: values.includeExternals ? true : false,
          includeEndUsers: values.includeEndUsers ? true : false,
        }

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateEntityIncidentSubtype(entity.id, item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm()
      } else {
        const newItem = {
          ...values,
          incidentTypeId: values.incidentType.value,
          backResponseTeamId: values.backResponseTeam?.value ?? null,
          responseTeams: values.responseTeams.map( e => { return {id: e.value}}),
          locations: values.locations.map( e => { return {id: e.value}}),
          entity_locations: values?.entity_locations ? values?.entity_locations : false,
          action_steps: JSON.stringify(values.action_steps),
          entityId: entity.id,
          supportMedia: values.supportMedia ? true : false,
          requiredSupportMedia: values.requiredSupportMedia ? true : false,
          supportText: values.supportText ? true : false,
          requiredSupportText: values.requiredSupportText ? true : false,
          askQuestion: values.askQuestion ? true : false,
          askQuestionMandatory: values.askQuestionMandatory ? true : false,
          skipScreenLocation: values.skipScreenLocation ? true : false,
          skipScreenMap: values.skipScreenMap ? true : false,
          skipScreenDate: values.skipScreenDate ? true : false,
          skipScreenSummary: values.skipScreenSummary ? true : false,
          includeExternals: values.includeExternals ? true : false,
          includeEndUsers: values.includeEndUsers ? true : false,
        }
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addIncidentSubtype(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm()
      }
      toggle()
    },
  })

  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: {},
      subtype: {},
    },
    validationSchema: Yup.object({
      type: Yup.object().shape({
        value: Yup.string().min(1, 'required').required(t("required")),
      }),
      subtype: Yup.object().shape({
        value: Yup.string().min(1, 'required').required(t("required")),
      }),
    }),
    onSubmit: async values => {
      console.log('validate', values)
      const item = {
        clientIncidentSubtypeId: values.subtype.value,
        entityIncidentTypeId: values.type.original
      }
      setLoadingBtn(true)
      const itemResponse = await addClientIncidentSubTypeToEntity(entity.id, item)
      fetchData()
      setLoadingBtn(false)
      validation2.resetForm()
      togglePick()

    },
  })

  const fetchIncidentSubTypesPick = async (incidentTypeId) => {
    const response = await getIncidentSubtypesSelect({
      params:{
        //entityId: entity.id,
        incidentTypeId: incidentTypeId
      }
    })

    setIncidentSubTypesPick(response.map(e => {return {value: e.id, label: e.name }}))
  }

  useEffect(() => {
    // validation2.setFieldValue('subtype', emptyValueSelect, true);
    if(validation2.values?.type?.value)
      fetchIncidentSubTypesPick(validation2.values.type.value)
  }, [validation2.values.type])

  const handleUploadFile = async () => {
    if (!isEdit || updateAsset) {
      validation.setTouched({...validation.touched, assetId: true})
      if (selectedFiles.length == 0) {
        validation.setValues({...validation.values, assetId: ''})
        validation.validateForm(validation.values)
        return;
      }
      const file = await submissionFiles();
      validation.values.assetId = file.id
    }
    validation.submitForm()
  }

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row); } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemSortLocations(row); } }
          title={t("Sort locations")}>
            <i className="fas fa-list"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'asset.path',
      text: t('Asset'),
      formatter: (cell, row, rIdx) => (<img height="50" src={API_URL + '/' + row.asset.path}></img>),
      sort: false
    }, {
      dataField: 'incidentType.name',
      text: t('Incident Type'),
      sort: true
    }, {
      dataField: 'name',
      text: t('Incident Subtype'),
      sort: true
    }, {
      dataField: 'sort_order',
      text: t('Sort'),
      sort: true
    }, {
      dataField: 'responseTeam.name',
      text: t('Response Team'),
      formatter: (cell, row, rIdx) => { return row.responseTeams.map( e => e.name).join(', ') }
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const togglePick = () => {
    setModalPick(!modalPick);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sortItemsModal, setSortItemsModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteIncidentSubtype(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleItemCreateClick = () => {
    setUpdateAsset(false)
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const history = useHistory();

  const handleItemEditClick = (row) => {
    validation.resetForm()
    setUpdateAsset(false)
    setItem(row);
    setIsEdit(true);
    toggle();
  };

  const handleItemSortLocations = row => {
    setItem(row);
    setSortItemsModal(true);
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    validation.setFieldValue('assetId','1',true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submissionFiles = async () => {
    let result = await postImage(selectedFiles[0]);
    return result;
	};

  function addAction() {
    if (validation.values.action.trim().length > 0) {
      let idx = Object.keys(validation.values.action_steps).length
      let newValue = {...validation.values.action_steps }
      newValue[idx] = validation.values.action.trim()
      console.log(newValue)
      validation.setFieldValue('action_steps', newValue)
      validation.setFieldValue('action', '')
    }
  }

  function delAction(key) {
    delete validation.values.action_steps[key]
    const temp = Object.values(validation.values.action_steps)
    validation.setFieldValue('action_steps', {...temp})
  }

  function handleUpdateLocations() {
    console.log('update')
  }

  console.log('tableOpts', tableOpts)
  console.log('teams', teams)
  console.log('validation.values.action_steps', validation.values.action_steps)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />


      <Card>
        <CardBody>
        <Row>
            <Col xl="12">

                <TableContainer4
                  columns={columns}
                  data={items}
                  handleButton2Click={() => { togglePick() }}
                  addBtn2Text={t("Pick from list of Incident SubTypes")}
                  loading={loading}
                  sortable={true}
                  handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                  handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                  handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }

                  pageOptions={
                    {
                      sizePerPage: tableMeta.take,
                      page: tableMeta.page,
                      totalSize: tableMeta.itemCount,
                      custom: true,
                      hideSizePerPage: true
                    }
                  }
                />

                <Modal isOpen={modal} >
                  <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-Label">{t('Incident Type')}</Label>
                            <ReactSelect
                              className={classNames({'is-invalid': validation.errors.incidentType?true:false})}
                              name="incident_type"
                              options={incidentTypes}
                              value={validation.values.incidentType || null}
                              onChange={(option, actionMeta) => { validation.setFieldValue('incidentType', option, true)}}
                              onBlur={ () => { console.log('blur'); validation.setFieldTouched('incidentType', true, true)} }
                              placeholder={t('Select')+'...'}
                            />
                            {validation.touched.incidentType &&
                              validation.errors.incidentType ? (
                              <FormFeedback type="invalid">
                                {validation.errors.incidentType.value}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                          <Label className="form-label">{t("Name")}</Label>
                            <Input
                              name="name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                  validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                              validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{t("Sort Order")}</Label>
                            <Input
                              name="sort_order"
                              type="number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.sort_order || ""}
                              invalid={
                                validation.touched.sort_order &&
                                  validation.errors.sort_order
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.sort_order &&
                              validation.errors.sort_order ? (
                              <FormFeedback type="invalid">
                                {validation.errors.sort_order}
                              </FormFeedback>
                            ) : null}
                          </div>  
                          <div className="mb-3">  
                          <Label className="form-label">{t("Time to pass until backup response team informed")}</Label>
                            <Input
                              name="time_backup_response"
                              type="number"
                              min={0}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.time_backup_response || ""}
                              invalid={
                                validation.touched.time_backup_response &&
                                  validation.errors.time_backup_response
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.time_backup_response &&
                              validation.errors.time_backup_response ? (
                              <FormFeedback type="invalid">
                                {validation.errors.time_backup_response}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">  
                          <Label className="form-label">{t("Max time the incident is open before to send notification")}</Label>
                            <Input
                              name="max_time_to_response"
                              type="number"
                              min={0}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.max_time_to_response || ""}
                              invalid={
                                validation.touched.max_time_to_response &&
                                  validation.errors.max_time_to_response
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.max_time_to_response &&
                              validation.errors.max_time_to_response ? (
                              <FormFeedback type="invalid">
                                {validation.errors.max_time_to_response}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-Label">{t('Response Teams')}</Label>
                            <ReactSelect
                              className={classNames({'is-invalid': validation.errors.responseTeams?true:false})}
                              name="responseTeams"
                              options={teams}
                              isMulti
                              value={validation.values.responseTeams || null}
                              onChange={(option, actionMeta) => { validation.setFieldValue('responseTeams', option, true)}}
                              onBlur={ () => { console.log('blur'); validation.setFieldTouched('responseTeams', true, true)} }
                              placeholder={t('Select')+'...'}
                            />
                            {validation.touched.responseTeams &&
                              validation.errors.responseTeams ? (
                              <FormFeedback type="invalid">
                                {validation.errors.responseTeams}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-Label">{t('Backup Response Team')}</Label>
                            <ReactSelect
                              className={classNames({'is-invalid': validation.errors.backResponseTeam?true:false})}
                              name="backResponseTeam"
                              options={teams}
                              isClearable={true}
                              value={validation.values.backResponseTeam || null}
                              onChange={(option, actionMeta) => { validation.setFieldValue('backResponseTeam', option, true)}}
                              onBlur={ () => { console.log('blur'); validation.setFieldTouched('backResponseTeam', true, true)} }
                              placeholder={t('Select')+'...'}
                            />
                            {validation.touched.backResponseTeam &&
                              validation.errors.backResponseTeam ? (
                              <FormFeedback type="invalid">
                                {validation.errors.backResponseTeam}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                                <Label className="form-Label">{t('Locations')}</Label>
                                <CreatableSelect
                                  className={classNames({'is-invalid': validation.touched.locations && validation.errors.locations?true:false})}
                                  name="groups"
                                  options={locations}
                                  isMulti
                                  formatCreateLabel={(inputValue) => {
                                    return t("Create") + " \"".concat(inputValue, "\"");
                                  }}
                                  value={validation.values.locations || []}
                                  onChange={(option, actionMeta) => { console.log(option), validation.setFieldValue('locations', option, true)}}
                                  onBlur={ () => { console.log('blur'); validation.setFieldTouched('locations', true, true)} }
                                  placeholder={t('Select or type new')+'...'}
                                />
                                {validation.touched.locations &&
                                  (Array.isArray(validation.errors.locations)) ? (
                                  <FormFeedback type="invalid">
                                    {Array.isArray(validation.errors.locations) ? validation.errors.locations.join(', '):validation.errors.locations}
                                  </FormFeedback>
                                ) : null}
                          </div>
                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Include external incident responders")}</Label> <Input
                                name="includeExternals"
                                label="includeExternals"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('includeExternals', !validation.values.includeExternals , true)}}
                                checked={validation.values.includeExternals}
                              />
                          </div>
                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Include end user contacts")}</Label> <Input
                                name="includeEndUsers"
                                label="includeEndUsers"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('includeEndUsers', !validation.values.includeEndUsers , true)}}
                                checked={validation.values.includeEndUsers}
                              />
                          </div>
                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Use Entity Related Locations")}</Label> <Input
                                name="entity_locations"
                                label="entity_locations"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('entity_locations', !validation.values.entity_locations , true)}}
                                checked={validation.values.entity_locations}
                              />
                          </div>
                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Support Media")}</Label> <Input
                                name="supportMedia"
                                label="supportMedia"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('supportMedia', !evt.target.checked , true)}}
                                checked={validation.values.supportMedia}
                              />
                          </div>
                          { validation.values.supportMedia &&
                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Support Media is Required")}</Label> <Input
                                name="requiredSupportMedia"
                                label="requiredSupportMedia"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('requiredSupportMedia', !evt.target.checked , true)}}
                                checked={validation.values.requiredSupportMedia}
                              />
                          </div>
                          }
                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Support Comments")}</Label> <Input
                                name="supportText"
                                label="supportText"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('supportText', !evt.target.checked , true)}}
                                checked={validation.values.supportText}
                              />
                          </div>
                          {
                            validation.values.supportText && 
                            <div className="pt-2 mb-1">
                              <Label className="form-label">{t("Support Comments is Required")}</Label> <Input
                                  name="requiredSupportText"
                                  label="requiredSupportText"
                                  type="checkbox"
                                  onClick={(evt) => { validation.setFieldValue('requiredSupportText', !evt.target.checked , true)}}
                                  checked={validation.values.requiredSupportText}
                                />
                            </div>
                          }

                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Ask specific question")}</Label> <Input
                                name="askQuestion"
                                label="askQuestion"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('askQuestion', !evt.target.checked , true)}}
                                checked={validation.values.askQuestion}
                              />
                          </div>

                          { validation.values.askQuestion &&
                            <div className="pt-2 mb-1">
                              <Label className="form-label">{t("Answer specific question mandatory")}</Label> <Input
                                  name="askQuestionMandatory"
                                  label="askQuestionMandatory"
                                  type="checkbox"
                                  onClick={(evt) => { validation.setFieldValue('askQuestionMandatory', !evt.target.checked , true)}}
                                  checked={validation.values.askQuestionMandatory}
                                />
                            </div>
                          }

                          { validation.values.askQuestion && 
                            <div className="mt-2 mb-3">
                              <Label className="form-label">{t("Specific Question")}</Label>
                              <Input
                                name="specificQuestion"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.specificQuestion || ""}
                                invalid={
                                  validation.touched.specificQuestion &&
                                    validation.errors.specificQuestion
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.specificQuestion &&
                                validation.errors.specificQuestion ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.specificQuestion}
                                </FormFeedback>
                              ) : null}
                            </div>
                          }

                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Skip Selection of Location")}</Label> <Input
                                name="skipScreenLocation"
                                label="skipScreenLocation"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('skipScreenLocation', !evt.target.checked , true)}}
                                checked={validation.values.skipScreenLocation}
                              />
                          </div>

                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Skip map")}</Label> <Input
                                name="skipScreenMap"
                                label="skipScreenMap"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('skipScreenMap', !evt.target.checked , true)}}
                                checked={validation.values.skipScreenMap}
                              />
                          </div>

                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Skip datetime")}</Label> <Input
                                name="skipScreenDate"
                                label="skipScreenDate"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('skipScreenDate', !evt.target.checked , true)}}
                                checked={validation.values.skipScreenDate}
                              />
                          </div>

                          <div className="pt-2 mb-1">
                            <Label className="form-label">{t("Skip summary before sending")}</Label> <Input
                                name="skipScreenSummary"
                                label="skipScreenSummary"
                                type="checkbox"
                                onClick={(evt) => { validation.setFieldValue('skipScreenSummary', !evt.target.checked , true)}}
                                checked={validation.values.skipScreenSummary}
                              />
                          </div>


                          { isEdit &&
                          <div className={classNames({"d-none": updateAsset || validation.values.assetId == '' ? true:false, "pt-2": true, "mb-3":true })}>
                            <Label className="form-label">{t("Current Asset")}</Label><br />
                            <img height="100" src={API_URL + '/' + item.asset.path}></img><br />
                            <button
                              type="button"
                              onClick={() => { validation.setFieldValue('assetId', ''); setUpdateAsset(true) }}
                              className="btn btn-primary btn-sm save-user mt-1"
                            >
                              <i className="fas fa-refresh"></i> {t("Change")}
                            </button>
                          </div>
                          }
                          <div className={classNames({"d-none": isEdit && !updateAsset ? true:false, "is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                              <Label className="form-label">{t("Icon")}</Label><br />
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                }}
                                onFileDialogOpen={() => {
                                  validation.setFieldTouched('assetId', true, true)
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone" style={{minHeight: '120px'}}>
                                    <div
                                      className="text-center needsclick mt-2"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-1">
                                        <i className="display-5 text-muted bx bx-cloud-upload" />
                                      </div>
                                      <h5>{t('Drop files here or click to upload')}.</h5>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div className="dropzone-previews mt-3" id="file-previews">
                                {selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>                                                    
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  );
                                })}
                              </div>
                          </div>
                          {validation.touched.assetId &&
                                validation.errors.assetId ? (
                                <FormFeedback type="invalid" className="invalid">
                                  {validation.errors.assetId}
                                </FormFeedback>
                              ) : null}

                          <div className="mb-3">
                              <Label className="form-Label">{t('Action Step')}</Label>
                              <InputGroup>
                                <Input
                                  className={classNames({'is-invalid': validation.touched.action_steps && 'action_steps' in validation.errors && Object.keys(validation.errors.action_steps).length > 0 ?true:false})}
                                  name="action"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.action || ""}
                                  placeholder={t('Add next action step here...')}
                                />
                                <Button type="button" onClick={() => addAction() }>
                                  {t('Add')}
                                </Button>
                              </InputGroup>
                              <br />
                              <ol 
                                className={classNames({'is-invalid': validation.touched.action_steps && 'action_steps' in validation.errors && Object.keys(validation.errors.action_steps).length ?true:false})}
                              >
                                { Object.keys(validation.values.action_steps).map( (e) => { return (
                                <li className="p-2" key={e}>
                                  {validation.values.action_steps[e]}
                                  <button 
                                    type="button"
                                    className="btn btn-danger btn-sm float-end"
                                    onClick={() => { delAction(e) }}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>  
                                </li>)})
                                }
                              </ol>

                              {validation.touched.action_steps && 'action_steps' in validation.errors && 
                                  Object.keys(validation.errors.action_steps).length > 0 ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.action_steps[0]}
                                  </FormFeedback>
                                ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-center mt-2">
                            <button
                              type="button"
                              onClick={handleUploadFile}
                              className="btn btn-primary btn-md save-user"
                            >
                              <i className="fas fa-save"></i> {t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                <Modal isOpen={modalPick} >
                    <ModalHeader toggle={togglePick} tag="h4">
                      { t("Pick from client")}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation2.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-Label">{t('Incident Type')}</Label>
                              <ReactSelect
                                className={classNames({'is-invalid': validation2.errors.type?true:false})}
                                name="type"
                                options={incidentTypesPick}
                                value={validation2.values.type || null}
                                onChange={(option, actionMeta) => { validation2.setFieldValue('type', option, true); validation2.setFieldValue('subtype', {}, true) }}
                                onBlur={ () => { console.log('blur'); validation2.setFieldTouched('type', true, true)} }
                                placeholder={t('Select')+'...'}
                              />
                              { validation2.touched.type &&
                                validation2.errors.type ? (
                                <FormFeedback type="invalid">
                                  {validation2.errors.type?.value}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-Label">{t('Incident Subtype')}</Label>
                              <ReactSelect
                                className={classNames({'is-invalid': validation2.errors.subtype?true:false})}
                                name="subtype"
                                options={incidentSubTypesPick}
                                value={validation2.values.subtype || null}
                                onChange={(option, actionMeta) => { validation2.setFieldValue('subtype', option, true) }}
                                onBlur={ () => { console.log('blur'); validation2.setFieldTouched('subtype', true, true)} }
                                placeholder={t('Select')+'...'}
                              />
                              { validation2.touched.subtype &&
                                validation2.errors.subtype ? (
                                <FormFeedback type="invalid">
                                  {validation2.errors.subtype?.value}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-md save-user"
                              >
                                <i className="fas fa-copy"></i> {t("Copy")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                </Modal>

                <SortItemsModal
                  show={sortItemsModal}
                  onSaveHandler={(items) => { setItem({...item, locations: items}); fetchData() }}
                  onCloseClick={() => setSortItemsModal(false)}
                  subtype={item}
                />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

IncidentSubTypesTabNew.propTypes = {
  entity: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(IncidentSubTypesTabNew)
