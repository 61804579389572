import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Col, Row, Form, Label, Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from "react-i18next";
import {
  addSponsorsContracts,
  getEntitiesSelect,
  updateSponsorsContracts,
} from "../../../helpers/backend_helper";

import NibbleInput from "../../../components/Fields/NibbleInput";

const SponsorContractView = ({ t, isEdit, item, onComplete, clients }) => {
  //const [countries, setCountries] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entityGroups, setEntityGroups] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object({
      //countryId: Yup.object().required(t("required")),
      name: Yup.string().nullable().required(t("required")),
      advertising_type: Yup.string().nullable().required(t("required")),
      clientId: Yup.string().nullable().required(t("required")),
      entities: Yup.array().required(t("required")),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values,
        };
        console.log(updateItem);

        setLoadingBtn(true);
        const itemResponse = await updateSponsorsContracts(item.id, updateItem);
        setLoadingBtn(false);

        validation.resetForm();
      } else {
        const newItem = {
          ...values,
        };
        setLoadingBtn(true);
        const itemResponse = await addSponsorsContracts(newItem);
        setLoadingBtn(false);
        validation.resetForm();
      }
      onComplete();
    },
  });

  const fetchEntitiesByClient = async (clientId) => {
    let entities = [];
    if (clientId) {
      entities = await getEntitiesSelect({ params: { clientId } });

      const groups = {};
      entities = entities.map((e) => {
        e.groupId = e.groups[0]?.id;
        e.groupName = e.groups[0]?.name;
        groups[e.groupId] = e.groupName;
        return e;
      });

      setEntityGroups(groups);
      setEntities(entities);
      validation.setFieldValue(
        "entities",
        entities.map((e) => e.id)
      );
    }
    return entities;
  };

  useEffect(() => {
    fetchEntitiesByClient(validation?.values?.clientId);
  }, [validation?.values?.clientId]);

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col xs={12}>
            <div className="d-none mb-3" style={{ wordBreak: "break-word" }}>
              {JSON.stringify(validation.values)}
            </div>
            <Row>
              <Col md={12}>
                <p>
                  <b>{t("Name")}</b>
                  <br />
                  {item.name}
                </p>
                <p>
                  <b>{t("Advertising type")}</b>
                  <br />
                  {t(item.advertising_type)}
                </p>
                <p>
                  <b>{t("Start Day")}</b>
                  <br />
                  {t(item.start_day ?? "-")}
                </p>
                <p>
                  <b>{t("End Day")}</b>
                  <br />
                  {t(item.end_day ?? "-")}
                </p>
                <p>
                  <b>{t("Client")}</b>
                  <br />
                  {t(item.client.name)}
                </p>
                <p>
                  <b>{t("Entities")}</b>
                  <br />
                  {Object.keys(entityGroups).map((gId, ie) => (
                    <Col key={gId} className="mt-2" md={6}>
                      <Label for={gId} className="form-label fw-bold">
                        {entityGroups[gId]}
                      </Label>
                      <div className="ps-4">
                        {entities.filter((c) => c.groupId == gId).map(e => (
                          <div key={e.id}>{item.entities.find(o => o == e.id) ? <i className="fa fa-check text-success"></i>:<i className="fa fa-times text-danger"></i>} {e.name}</div>
                        ))}
                      </div>
                    </Col>
                  ))}
                </p>
              </Col>
            </Row>

          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

SponsorContractView.propTypes = {
  item: PropTypes.object.required,
  clients: PropTypes.array.required,
  t: PropTypes.any,
};

export default withTranslation()(SponsorContractView);
