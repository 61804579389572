import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { addLocation, deleteLocation, getEntityLocations, getLocations, getLocationSelect, postImage, updateEntityLocation, updateEntityLocations, updateLocation } from '../../../helpers/backend_helper';

import '../../../components/Common/custom.css';
import getAuthUser from "../../../components/helpers/auth";
import TableContainer4 from "../../../components/Common/TableContainer4";
import ReactSelect from "react-select";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import MapPicker from "../../../components/MapPicker";
import TableContainer5 from '../../../components/Common/TableContainer5';

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const LocationsList = props => {
  const t = props.t;
  const entity = props.entity;
  const masterItemName = t('Location');
  const masterItemNamePlural = t('Locations');
  const currentUser = getAuthUser()

  const typeOptions = [
    {value: 'STANDAR', label: t('STANDAR')},
    {value: 'INDIVIDUAL', label: t('INDIVIDUAL')}
  ]

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [teams, setTeams] = useState([]);
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);
  const [updateAsset, setUpdateAsset] = useState (false);
  const [modalPick, setModalPick] = useState(false)
  const [locations, setLocations] = useState([])

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
    entityId: entity.id
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getEntityLocations(entity.id, {}) 
    setItems(response)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  const fetchLocationsSelect = async () => {
    const response = await getLocationSelect({params: {forLocation: true}})
    console.log(response)
    const responseIds = response.map(e => e.id)
    const existIds = items.filter(e => { return e.entityRelated }).map(e => e.parentLocationId)
    console.log('existIds', existIds)
    const noExistIds = responseIds.filter(e => { return !existIds.includes(e) })
    console.log('noExistIds', noExistIds)
    setLocations(response.filter(e => { return noExistIds.includes(e.id)}).map(e => {return {value: e.id, label: e.name };}))

  }

  useEffect(() => {
    fetchLocationsSelect()
  }, [items])
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      position: item?.latitude ? {lat: Number(item?.latitude), lng: Number(item?.longitude)}:{lat: 19.4009907, lng: -99.1767005},
      name: (item && item.name) || "",
      description: (item?.description) || "",
      assetId: (item?.assetId) || '',
      sort_order: (item?.sort_order) || "",
      type: (item?.type) ? {value: item?.type, label: item?.type} : {value: 'STANDAR', label:'STANDAR'},
      entityRelated: (item?.entityRelated) || false,
      incidentSubtypeRelated: (item?.incidentSubtypeRelated) || false,
      showLocationName: (item?.showLocationName) || false,
      restrictReportLocation: (item?.maxRadius > 0) ? true : false,
      latitude: (item?.latitude) ? Number(item?.latitude) : null,
      longitude: (item?.longitude) ? Number(item?.longitude) : null,
      maxRadius: (item?.maxRadius) || 0
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t("required")).min(1),
      description: Yup.string(),
      assetId: Yup.string().required(t("required")),
      sort_order: Yup.number().required(t("required")),
      type: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      entityRelated: Yup.bool(),
      incidentSubtypeRelated: Yup.bool(),
      showLocationName: Yup.bool(),
      latitude: Yup.number().when('restrictReportLocation', {
        is: true,
        then: Yup.number().required(t("required")),
        otherwise: Yup.number().nullable()
      }),
      longitude: Yup.number().when('restrictReportLocation', {
        is: true,
        then: Yup.number().required(t("required")),
        otherwise: Yup.number().nullable()
      }),
      maxRadius: Yup.number().when('restrictReportLocation', {
        is: true,
        then: Yup.number().required(t("required")),
      }),
    }),
    onSubmit: async values => {
      console.log('values',values)
      
      if (isEdit) {
        const updateItem = {
          //id: item.id,
          ...values,
          entityRelated: values.entityRelated ? true : false,
          incidentSubtypeRelated: values.incidentSubtypeRelated ? true : false,
          type: values.type?.value,
          showLocationName: values.showLocationName ? true : false,
          maxRadius: Number(values.maxRadius),
          restrictReportLocation: values.restrictReportLocation ? true : false
        }

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateEntityLocation(entity.id, item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm()
        setUpdateAsset(false)
        setselectedFiles([])

      } else {
        const newItem = {
          ...values,
          entityId: entity.id,
          entityRelated: values.entityRelated ? true : false,
          incidentSubtypeRelated: values.incidentSubtypeRelated ? true : false,
          type: values.type?.value,
          showLocationName: values.showLocationName ? true : false,
          restrictReportLocation: values.restrictReportLocation ? true : false
        }
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addLocation(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm()
        setUpdateAsset(false)
        setselectedFiles([])
      }
      toggle();
    },
  });

  // validation
  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: [],
    },
    validationSchema: Yup.object({
      type: Yup.array().min(1).of(Yup.object().shape({
        value: Yup.string().required(t("required")),
      })),
    }),
    onSubmit: async values => {
      const newItem = {
        incidentTypes: values.type.map(e => { return {id: e.value} }),
      }
      // save new user
      //dispatch(onAddNewUser(newUser));
      setLoadingBtn(true)
      const itemResponse = await updateEntityLocations(entity.id, newItem)
      fetchData()
      setLoadingBtn(false)
      validation2.resetForm()
      //toggle2()
    },
  });

  const handleUploadFile = async () => {
    if (!isEdit || updateAsset) {
      validation.setTouched({...validation.touched, assetId: true})
      if (selectedFiles.length == 0) {
        validation.setValues({...validation.values, assetId: ''})
        validation.submitForm()
        return;
      }
      const file = await submissionFiles();
      validation.values.assetId = file.id
    }
    validation.submitForm()
    console.log('complete upload file')
  }

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row); } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'asset.path',
      text: t('Asset'),
      formatter: (cell, row, rIdx) => ( row.asset ? <img width="100" src={API_URL + '/' + row.asset.path}></img>:t('-')),
      sort: false
    }, {
      dataField: 'name',
      text: t('Name'),
      sort: true
    }, {
      dataField: 'name',
      text: t('Belong to entity'),
      sort: true,
      formatter: (a, row) => { return row.entityItems?.length > 0 ? t('Yes'):t('No') }
    }, {
      dataField: 'name',
      text: t('Belong to subtypes'),
      sort: true,
      formatter: (a, row) => { return row.subtypeItems?.length > 0 ? t('Yes'):t('No')}
    }, {
      dataField: 'sort_order',
      text: t('Sort order'),
      sort: true,
      formatter: (a, row) => { return row.entityItems?.length > 0 ? row.sort_order:''}
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    console.log('toogle')
    setModal(!modal);
  };

  const togglePick = () => {
    setModalPick(!modalPick);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteLocation(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleItemCreateClick = () => {
    setUpdateAsset(false)
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const handleItemEditClick = (row) => {
    setUpdateAsset(row.asset ? false : true)
    validation.resetForm()
    setItem(row)
    setIsEdit(true)
    setModal(true)
    console.log('aca')
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    validation.setFieldValue('assetId','1',true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submissionFiles = async () => {
    let result = await postImage(selectedFiles[0]);
    return result;
	};

  console.log('tableOpts', tableOpts)
  console.log('modal', modal)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        message={item?.entityRelated ? t('delete_entity_location_when_entityrelated'):t('delete_entity_location_when_subtyperelated')}
      />
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer5
                          columns={columns}
                          data={items}
                          sortable={true}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          /* addBtnText={t("Add") + " " + masterItemName} */
                          handleButton2Click={() => { togglePick(); }}
                          addBtn2Text={t("Pick from list of Locations")}
                          loading={loading}
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }
                        />

                        <Modal isOpen={modal} >
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <Label className="form-label">{t("Name")}</Label>
                                      <Input
                                        name="name"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                          validation.touched.name &&
                                            validation.errors.name
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.name &&
                                        validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.name}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="mb-3">
                                    <Label className="form-label">{t("Description")}</Label>
                                      <Input
                                        name="description"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""}
                                        invalid={
                                          validation.touched.description &&
                                            validation.errors.description
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.description &&
                                        validation.errors.description ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.description}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="mb-3">
                                    <Label className="form-label">{t("Sort Order")}</Label>
                                      <Input
                                        name="sort_order"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.sort_order || ""}
                                        invalid={
                                          validation.touched.sort_order &&
                                            validation.errors.sort_order
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.sort_order &&
                                        validation.errors.sort_order ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.sort_order}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="mb-3 d-none">
                                      <Label className="form-Label">{t('Type')}</Label>
                                      <ReactSelect
                                        className={classNames({'is-invalid': validation.errors?.type?.value ? true:false})}
                                        name="type"
                                        options={typeOptions}
                                        value={validation.values.type || null}
                                        onChange={(option, actionMeta) => { validation.setFieldValue('type', option, true)}}
                                        onBlur={ () => { console.log('blur'); validation.setFieldTouched('type', true, true)} }
                                        placeholder={t('Select')+'...'}
                                      />
                                      {validation.touched.type?.value &&
                                        validation.errors.type?.value ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.type?.value}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="pt-2 mb-1 d-none">
                                      <Label className="form-label">{t("Entity Related")}</Label> <Input
                                          name="entityRelated"
                                          label="entityRelated"
                                          type="checkbox"
                                          onClick={(evt) => { validation.setFieldValue('entityRelated', !evt.target.checked , true)}}
                                          checked={validation.values.entityRelated}
                                        />
                                    </div>
                                    <div className="d-none">
                                      <Label className="form-label">{t("Subtype Incident Related")}</Label> <Input
                                          name="incidentSubtypeRelated"
                                          label="incidentSubtypeRelated"
                                          type="checkbox"
                                          onClick={(evt) => { validation.setFieldValue('incidentSubtypeRelated', !evt.target.checked , true)}}
                                          checked={validation.values.incidentSubtypeRelated}
                                        />
                                  </div>

                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Show Location Name")}</Label> <Input
                                        name="showLocationName"
                                        label="showLocationName"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('showLocationName', !evt.target.checked , true)}}
                                        checked={validation.values.showLocationName}
                                      />
                                  </div>

                                  <div className="">
                                    <Label className="form-label">{t("Only allow an incident within a radius")}</Label> <Input
                                        name="restrictReportLocation"
                                        label="restrictReportLocation"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('restrictReportLocation', !evt.target.checked , true); if((!evt.target.checked) == false) validation.setFieldValue('maxRadius', 0, true)}}
                                        checked={validation.values.restrictReportLocation}
                                      />
                                  </div>

                                  { validation.values.restrictReportLocation &&
                                  <>
                                  <MapPicker
                                    position={validation.values.position}
                                    onChange={ (position) => { 
                                      validation.setFieldValue('position', position) 
                                      validation.setFieldValue('latitude', position.lat) 
                                      validation.setFieldValue('longitude', position.lng) 
                                    }}
                                  ></MapPicker>

                                  <Row>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <Label className="form-label">{t("Latitude")}</Label>
                                        <Input
                                          name="latitude"
                                          type="number"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.latitude || ""}
                                          invalid={
                                            validation.touched.latitude &&
                                              validation.errors.latitude
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.latitude &&
                                          validation.errors.latitude ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.latitude}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <Label className="form-label">{t("Longitude")}</Label>
                                        <Input
                                          name="longitude"
                                          type="number"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.longitude || ""}
                                          invalid={
                                            validation.touched.longitude &&
                                              validation.errors.longitude
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.longitude &&
                                          validation.errors.longitude ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.longitude}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="mb-3">
                                    <Label className="form-label">{t("Report Only Radius")} {t('(kms)')}</Label>
                                    <Input
                                      name="maxRadius"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.maxRadius || ""}
                                      invalid={
                                        validation.touched.maxRadius &&
                                          validation.errors.maxRadius
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.maxRadius &&
                                      validation.errors.maxRadius ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.maxRadius}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  </>
                                  }
                                    { isEdit && item.asset &&
                                    <div className={classNames({"d-none": updateAsset || validation.values.assetId == '' ? true:false, "pt-2": true, "mb-3":true })}>
                                      <Label className="form-label">{t("Current Asset")}</Label><br />
                                      <img height="100" src={API_URL + '/' + item.asset.path}></img><br />
                                      <button
                                        type="button"
                                        onClick={() => { validation.setFieldValue('assetId', ''); setUpdateAsset(true) }}
                                        className="btn btn-primary btn-sm save-user mt-1"
                                      >
                                        <i className="fas fa-sync"></i> {t("Change")}
                                      </button>
                                    </div>
                                    }
                                    <div className={classNames({"d-none": isEdit && !updateAsset ? true:false, "is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                                        <Label className="form-label">{t("Icon")}</Label><br />
                                        <Dropzone
                                          onDrop={(acceptedFiles) => {
                                            handleAcceptedFiles(acceptedFiles);
                                          }}
                                          onFileDialogOpen={() => {
                                            validation.setFieldTouched('assetId', true, true)
                                          }}
                                        >
                                          {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone" style={{minHeight: '120px'}}>
                                              <div
                                                className="text-center needsclick mt-2"
                                                {...getRootProps()}
                                              >
                                                <input {...getInputProps()} />
                                                <div className="mb-1">
                                                  <i className="display-5 text-muted bx bx-cloud-upload" />
                                                </div>
                                                <h5>{ t('Drop files here or click to upload') }</h5>
                                              </div>
                                            </div>
                                          )}
                                        </Dropzone>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                          {selectedFiles.map((f, i) => {
                                            return (
                                              <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                              >
                                                <div className="p-2">
                                                  <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                      >
                                                        {f.name}
                                                      </Link>
                                                      <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                      </p>                                                    
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Card>
                                            );
                                          })}
                                        </div>
                                    </div>
                                    {validation.touched.assetId &&
                                          validation.errors.assetId ? (
                                          <FormFeedback type="invalid" className="invalid">
                                            {validation.errors.assetId}
                                          </FormFeedback>
                                        ) : null}
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center mt-2">
                                    <button
                                      type="button"
                                      onClick={handleUploadFile}
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>

                        <Modal isOpen={modalPick} >
                          <ModalHeader toggle={togglePick} tag="h4">
                            { t("Pick from client")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation2.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Locations')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation2.errors.type?true:false})}
                                      name="type"
                                      options={locations}
                                      isMulti
                                      value={validation2.values.type || null}
                                      onChange={(option, actionMeta) => { validation2.setFieldValue('type', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation2.setFieldTouched('type', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation2.touched.type &&
                                      validation2.errors.type ? (
                                      <FormFeedback type="invalid">
                                        {validation2.errors.type}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-copy"></i> {t("Copy")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
    </React.Fragment>
  )
}

export default withTranslation()(LocationsList)
