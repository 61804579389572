import React from 'react';
import { withTranslation } from "react-i18next";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
    Container,
    Card,
    Table,
    Badge,
    CardBody,
    Button
} from "reactstrap";
import { useHistory } from 'react-router-dom';

const CurrentList = (props) => {
    const t = props.t;
    const masterItemName = props.t("Incidents")
    document.title = masterItemName + " | Tellit";
    const history = useHistory()
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={masterItemName} breadcrumbItem={props.t("Working on")} />
                    <Card>
                        <CardBody>
                        <div className="text-end mt-2">
                            <a href="javascript://" className='btn btn-primary btn-sm'>
                                <i className='fas fa-sync'></i> {props.t("Update")}
                            </a>
                        </div>

                        <div className="table-responsive">
                            <Table className="table mt-4 mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th>{ props.t("Entity") }</th>
                                    <th>{ props.t("Type") }</th>
                                    <th>{ props.t("Datetime") }</th>
                                    <th>{ props.t("Status") }</th>
                                    <th>{ props.t("Call me") }</th>
                                    <th>{ props.t("Institution") }</th>
                                    <th>{ props.t("Owner") }</th>
                                    <th>{ props.t('Actions') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">{t("American School")}</th>
                                    <td>{t("Abuse")}</td>
                                    <td>2022/09/02 09:17</td>
                                    <td>{t("New")}</td>
                                    <td>{ props.t("Yes") }</td>
                                    <td>
                                        <Badge className="rounded-pill bg-success">{ props.t("Available") }</Badge>
                                    </td>
                                    <td>-</td>
                                    <td>
                                        <Button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-1"
                                            onClick={(evt) => { history.push('/incidents/work-on/detail'); } }
                                            title={t("Delete")}
                                        >
                                            <i className="fas fa-eye"></i>
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">{t("American School")}</th>
                                    <td>{t("Lamp bulb defect")}</td>
                                    <td>2022/09/02 09:17</td>
                                    <td>{t("Accepted")}</td>
                                    <td>{ props.t("No") }</td>
                                    <td>
                                        <Badge className="rounded-pill bg-warning">{ props.t("under-minimum") }</Badge>
                                    </td>
                                    <td>Jhon Smith</td>
                                    <td>
                                        <Button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-1"
                                            onClick={(evt) => { history.push('/incidents/work-on/detail'); } }
                                            title={t("Delete")}
                                        >
                                            <i className="fas fa-eye"></i>
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">{t("American School")}</th>
                                    <td>{t("HW not working")}</td>
                                    <td>2022/09/02 09:17</td>
                                    <td></td>
                                    <td>{ props.t("No") }</td>
                                    <td>
                                        <Badge className="rounded-pill bg-success">{ props.t("Available") }</Badge>
                                    </td>
                                    <td>Myself</td>
                                    <td>
                                        <Button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-1"
                                            onClick={(evt) => { history.push('/incidents/work-on/detail'); } }
                                            title={t("Delete")}
                                        >
                                            <i className="fas fa-eye"></i>
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">{t("American School")}</th>
                                    <td>{t("Toilets defect")}</td>
                                    <td>2022/09/02 09:17</td>
                                    <td>{t("In Progress")}</td>
                                    <td>{ props.t("No") }</td>
                                    <td>
                                        <Badge className="rounded-pill bg-danger">{ props.t("No Available") }</Badge>
                                    </td>
                                    <td>Joanne Sho</td>
                                    <td>
                                        <Button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-1"
                                            onClick={(evt) => { history.push('/incidents/work-on/detail'); } }
                                            title={t("Delete")}
                                        >
                                            <i className="fas fa-eye"></i>
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                            </Table>
                        </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(CurrentList)
