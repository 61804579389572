import React, { useEffect, useState } from "react"
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from 'react-select'

//redux
import { withTranslation } from 'react-i18next'
import { addResponseTeam, addResponseTeamUser, deleteUser, getResponseTeams, getResponseTeamUsers, getTeamsSelect, putUserResetPassword, updateResponseTeam, updateResponseTeamUser, getCountriesPhoneSelect, getResponseTeamUsersSelect, getConfigs } from '../../helpers/backend_helper'
import TableContainer2 from '../../components/Common/TableContainer2'

import '../../components/Common/custom.css'
import ConfirmModal from "../../components/Common/ConfirmModal"
import { useHistory } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import classNames from "classnames"
import getAuthUser from '../../components/helpers/auth'
import TableContainer6 from "../../components/Common/TableContainer6"

const ResponseTeamUsersList = props => {
  const t = props.t
  const currentUser = getAuthUser()
  const masterItemName = t('Incident Responder')
  const masterItemNamePlural = t('Incident Responders')

  //meta title
  document.title = masterItemName + "List | Tellit"

  const [minDigitsPhone, setMinDigitsPhone] = useState([])
  const [items, setItems] = useState([])
  const [item, setItem] = useState()
  const [teams, setTeams] = useState([])
  const [modal, setModal] = useState(false)
  const [modalExternal, setModalExternal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false)
  const [loadingBtn, setLoadingBtn] = useState (false)
  const [countries, setCountries] = useState([])

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getResponseTeamUsers({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  const fetchTeams = async () => {
    const response = await getTeamsSelect()
    setTeams(response.map(e => {return {value: e.id, label: e.name }}))
  }

  const fetchCountries = async () => {
    const response = await getCountriesPhoneSelect()
    setCountries(response.map(e => {return {value: e.dialing_prefix, label: '+' + e.dialing_prefix + " | " + e.name }}))
    const labelDefault = response.find(e => {return e.id == currentUser.countryId})
    validation.setValues({ 
      ...validation.values,
      phone: { 
        ...validation.values.phone, 
        country_code: {
          value: labelDefault.dialing_prefix,
          label: '+' + labelDefault.dialing_prefix + ' | ' + labelDefault.name
        }
      }
    })
  }

  useEffect(() => {
    fetchCountries()
    fetchData()
  }, [tableOpts])


  useEffect(() => {
    fetchTeams()
  }, [])
  
  const fetchConfigs = async () => {
    setLoading(true)
    const configs = await getConfigs()
    const minPhoneDigits = configs.filter(e => { return e.name=='MIN_PHONE_DIGITS'})
    setMinDigitsPhone(Number(minPhoneDigits.length > 0 ? minPhoneDigits[0].value : 8))
    setLoading(false)
  }

  useEffect(() => {
    fetchConfigs()
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      teams: (item && item.teams.map( e => { return {value: e.id, label: e.name}})) || "",
      first_name: (item && item.first_name) || "",
      last_name: (item && item.last_name) || "",
      email: (item && item.email) || "",
      skills: (item && item.settings?.skills) || "",
      active: (item && item.active) || true,
      phone: { 
        country_code: (item?.phone?.country_code && {value: item.phone.country_code , label: '+'+item.phone.country_code}) || '' ,
        area_code: (item?.phone && item.phone.area_code) || '',
        number: (item?.phone && item.phone.number) || '',
      },
    },
    validationSchema: Yup.object({
      teams: Yup.array().required(t("required")).min(1),
      first_name: Yup.string().required(t("required")),
      last_name: Yup.string(),
      skills: Yup.string(),
      email: Yup.string().required(t("required")),
      phone: Yup.object().shape({
        country_code: Yup.object().shape({
          value: Yup.string().required(t("required"))
        }),
        area_code: Yup.string().max(5),
        number: Yup.string().test(
          'len',
          t('must be at least NUMBER digits').replace('NUMBER', minDigitsPhone),
          (value, obj) => {
            return ((obj.parent.area_code?.length ?? 0) + (obj.parent.number?.length ?? 0)) >= minDigitsPhone
        }),
      })
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          teams: values.teams.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          settings: {...item.settings, skills: values.skills},
          active: values.active ? values.active : false,
          phone: {
            ...values.phone,
            area_code: values.phone?.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone?.country_code?.value ? values.phone.country_code.value.toString():'',
            number: values.phone?.number.toString() ?? ''
          },
          external: false,
        }

        // update user
        //dispatch(onUpdateUser(updateUser))
        setLoadingBtn(true)
        const itemResponse = await updateResponseTeamUser(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm()
      } else {
        console.log(values)
        const newItem = {
          external: false,
          teams: values.teams.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          settings: {skills: values.skills},
          active: values["active"] ? values['active']:false,
          phone: {
            ...values.phone,
            area_code: values.phone?.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone?.country_code?.value ? values.phone.country_code.value.toString():'',
            number: values.phone?.number.toString() ?? ''
          }
        }
        // save new user
        //dispatch(onAddNewUser(newUser))
        setLoadingBtn(true)
        const itemResponse = await addResponseTeamUser(newItem)
        fetchData()
        fetchTeams()
        setLoadingBtn(false)
        validation.resetForm()
      }
      toggle()
    },
  })

  const validationExternal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      teams: (item && item.teams.map( e => { return {value: e.id, label: e.name}})) || "",
      first_name: (item && item.first_name) || "",
      last_name: (item && item.last_name) || "",
      email: (item && item.email) || "",
      skills: (item && item.settings?.skills) || "",
      active: (item && item.active) || true,
      phone: { 
        country_code: (item?.phone?.country_code && {value: item.phone.country_code , label: '+'+item.phone.country_code}) || {} ,
        area_code: (item?.phone && item.phone?.area_code) || '',
        number: (item?.phone && item.phone?.number) || '',
      },
    },
    validationSchema: Yup.object({
      teams: Yup.array().required(t("required")).min(1),
      first_name: Yup.string(),
      last_name: Yup.string(),
      skills: Yup.string(),
      email: Yup.string().required(t("required")),
      phone: Yup.object().shape({
        country_code: Yup.object().shape({
          value: Yup.string()
        }),
        area_code: Yup.string().max(5),
        number: Yup.string().test(
          'len',
          t('must be at least NUMBER digits').replace('NUMBER', minDigitsPhone),
          (value, obj) => {
            return (((obj.parent.area_code?.length ?? 0) + (obj.parent.number?.length ?? 0)) >= minDigitsPhone) || (obj.parent.number?.length ?? 0) == 0
        }),
      })
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          teams: values.teams.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          settings: {...item.settings, skills: values.skills},
          active: values.active ? values.active : false,
          phone: {
            ...values.phone,
            area_code: values.phone?.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone?.country_code?.value ? values.phone.country_code.value.toString():'',
            number: values.phone?.number.toString() ?? ''
          },
          external: true
        }

        // update user
        //dispatch(onUpdateUser(updateUser))
        setLoadingBtn(true)
        const itemResponse = await updateResponseTeamUser(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validationExternal.resetForm()
      } else {
        console.log(values)
        const newItem = {
          external: true,
          teams: values.teams.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          settings: {skills: values.skills},
          active: values["active"] ? values['active']:false,
          phone: {
            ...values.phone,
            area_code: values.phone?.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone?.country_code?.value ? values.phone.country_code.value.toString():'',
            number: values.phone?.number?.toString() ?? ''
          }
        }
        // save new user
        //dispatch(onAddNewUser(newUser))
        setLoadingBtn(true)
        const itemResponse = await addResponseTeamUser(newItem)
        fetchData()
        fetchTeams()
        setLoadingBtn(false)
        validationExternal.resetForm()
      }
      toggleModalExternal()
    },
  })

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { if(row.role == 'IR_EXTERNAL') handleItemEditClick2(row); else handleItemEditClick(row) } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row) } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
        { row.role != 'IR_EXTERNAL' &&
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleResetPasswordClick(row) } }
          title={t("Reset Password")}>
            <i className="fas fa-sync"></i>
        </Button>
        }
      </span>
    )
  }

  const columns = [
    {
      dataField: 'team.name',
      text: t('Teams'),
      formatter: (cell, row, rIdx) => { return row.teams.map( t => t.name).join(', ') },
    }, {
      dataField: 'first_name',
      text: t('First Name'),
      sort: true
    }, {
      dataField: 'last_name',
      text: t('Last name'),
      sort: true
    }, {
      dataField: 'email',
      text: t('Email'),
      sort: true
    }, {
      dataField: 'tyrolepe',
      text: t('External'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.role == 'IR_EXTERNAL' ? t('Yes'):t('No') },
    }, {
      dataField: 'active',
      text: t('Active'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.active ? t('Yes'):t('No') },
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ]

  const toggleModalExternal = () => {
    setModalExternal(!modalExternal)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const handleDeleteUser = async () => {
    const response = await deleteUser(item.id)
    fetchData()
    setDeleteModal(false)
  }

  const handleResetPass = async () => {
    const response = await putUserResetPassword(item.id)
    setConfirmModal(false)
  }

  const handleItemCreateClick = () => {
    setItem(null)
    setIsEdit(false)
    toggle()
  }

  const handleItemExternalCreateClick = () => {
    setItem(null)
    setIsEdit(false)
    toggleModalExternal()
  }

  const handleItemDeleteClick = row => {
    setItem(row)
    setDeleteModal(true)
  }

  const history = useHistory()
  const handleItemDetailClick = row => {
    history.push("/managment/clients/detail/" + row.id)
  }

  const handleItemEditClick = (row) => {
    validation.resetForm()
    setItem(row)
    setIsEdit(true)
    toggle()
  }

  const handleItemEditClick2 = (row) => {
    validationExternal.resetForm()
    setItem(row)
    setIsEdit(true)
    toggleModalExternal()
  }

  const handleResetPasswordClick = (row) => {
    setItem(row)
    setConfirmModal(true)
  }

  console.log('tableOpts', tableOpts)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ConfirmModal
        show={confirmModal}
        onConfirmClick={handleResetPass}
        onCloseClick={() => setConfirmModal(false)}
        text={t('Do you want reset password?')}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer6
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick() }}
                          handleNewItemClick2={() => { handleItemExternalCreateClick() }}
                          addBtnText={masterItemName}
                          addBtnText2={masterItemName}
                          loading={loading}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }
                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              <Row>
                                <Col lg={6}>
                                  
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Teams')}</Label>
                                    <Select
                                      className={classNames({'is-invalid': validation.errors.teams?true:false})}
                                      name="teams"
                                      options={teams}
                                      isMulti
                                      value={validation.values.teams || null}
                                      onChange={(option, actionMeta) => { console.log(option), validation.setFieldValue('teams', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('teams', true, true)} }
                                      placeholder={t('Select or type new')+'...'}
                                    />
                                    {validation.touched.teams &&
                                      validation.errors.teams ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.teams}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("First name")}</Label>
                                    <Input
                                      name="first_name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.first_name || ""}
                                      invalid={
                                        validation.touched.first_name &&
                                          validation.errors.first_name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.first_name &&
                                      validation.errors.first_name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.first_name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Last name")}</Label>
                                    <Input
                                      name="last_name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.last_name || ""}
                                      invalid={
                                        validation.touched.last_name &&
                                          validation.errors.last_name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.last_name &&
                                      validation.errors.last_name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.last_name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Email")}</Label>
                                    <Input
                                      name="email"
                                      type="email"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.email || ""}
                                      invalid={
                                        validation.touched.email &&
                                          validation.errors.email
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.email &&
                                      validation.errors.email ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.email}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">{t("Skills")}</Label>
                                    <textArea
                                      className="form-control"
                                      name="skills"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.skills &&
                                          validation.errors.skills
                                          ? true
                                          : false
                                      }
                                    >{validation.values.skills}</textArea>
                                    {validation.touched.skills &&
                                      validation.errors.skills ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.skills}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="pt-2 mb-3">
                                    <Label className="form-label">{t("Active")}</Label> <Input
                                        name="active"
                                        label="active"
                                        type="checkbox"
                                        onChange={(evt) => {}}
                                        onClick={(evt) => { validation.setFieldValue('active', !evt.target.checked , true)}}
                                        checked={validation.values.active}
                                        invalid={
                                          validation.touched.active &&
                                            validation.errors.active
                                            ? true
                                            : false
                                        }
                                      />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> {t('Phone')}</h5>
                                      <div className="mb-3">
                                        <Label className="form-Label">{t('Country Code')}</Label>
                                        <Select
                                          className={classNames({'is-invalid': validation.errors.countryId?true:false})}
                                          name="phone.country_code.value"
                                          options={countries}
                                          value={validation.values.phone.country_code || null}
                                          onChange={(option, actionMeta) => { validation.setFieldValue('phone.country_code', option, true)}}
                                          onBlur={ () => { validation.setFieldTouched('phone.country_code', true, true)} }
                                          placeholder={t('Select')+'...'}
                                        />
                                        {validation.touched.phone?.country_code?.value &&
                                          validation.errors.phone?.country_code?.value ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.phone?.country_code?.value}
                                          </FormFeedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                      <Label className="form-label">{t("Area Code")}</Label>
                                        <Input
                                          name="phone.area_code"
                                          type="number"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.phone?.area_code || ""}
                                          invalid={
                                            validation.touched.phone?.area_code &&
                                              validation.errors.phone?.area_code
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.phone?.area_code &&
                                          validation.errors.phone?.area_code ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.phone?.area_code}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Phone number")}</Label>
                                        <Input
                                          name="phone.number"
                                          type="number"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.phone?.number || ""}
                                          invalid={
                                            validation.touched.phone?.number &&
                                              validation.errors.phone?.number
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.phone?.number &&
                                          validation.errors.phone?.number ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.phone?.number}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>

                        <Modal isOpen={modalExternal}>
                          <ModalHeader toggle={toggleModalExternal} tag="h4">
                            {!!isEdit ? t("Edit") + " " + t('External Incident Responder') : t("Add") + " " + t('External Incident Responder')}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                validationExternal.handleSubmit()
                                return false
                              }}
                            >
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Teams')}</Label>
                                    <Select
                                      className={classNames({'is-invalid': validationExternal.errors.teams?true:false})}
                                      name="teams"
                                      options={teams}
                                      isMulti
                                      value={validationExternal.values.teams || null}
                                      onChange={(option, actionMeta) => { console.log(option), validationExternal.setFieldValue('teams', option, true)}}
                                      onBlur={ () => { console.log('blur'); validationExternal.setFieldTouched('teams', true, true)} }
                                      placeholder={t('Select or type new')+'...'}
                                    />
                                    {validationExternal.touched.teams &&
                                      validationExternal.errors.teams ? (
                                      <FormFeedback type="invalid">
                                        {validationExternal.errors.teams}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Bussines Name")}</Label>
                                    <Input
                                      name="last_name"
                                      type="text"
                                      onChange={validationExternal.handleChange}
                                      onBlur={validationExternal.handleBlur}
                                      value={validationExternal.values.last_name || ""}
                                      invalid={
                                        validationExternal.touched.last_name &&
                                        validationExternal.errors.last_name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationExternal.touched.last_name &&
                                      validationExternal.errors.last_name ? (
                                      <FormFeedback type="invalid">
                                        {validationExternal.errors.last_name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Email")}</Label>
                                    <Input
                                      name="email"
                                      type="email"
                                      onChange={validationExternal.handleChange}
                                      onBlur={validationExternal.handleBlur}
                                      value={validationExternal.values.email || ""}
                                      invalid={
                                        validationExternal.touched.email &&
                                        validationExternal.errors.email
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationExternal.touched.email &&
                                      validationExternal.errors.email ? (
                                      <FormFeedback type="invalid">
                                        {validationExternal.errors.email}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">{t("Skills")}</Label>
                                    <textArea
                                      className="form-control"
                                      name="skills"
                                      onChange={validationExternal.handleChange}
                                      onBlur={validationExternal.handleBlur}
                                      invalid={
                                        validationExternal.touched.skills &&
                                        validationExternal.errors.skills
                                          ? true
                                          : false
                                      }
                                    >{validationExternal.values.skills}</textArea>
                                    {validationExternal.touched.skills &&
                                      validationExternal.errors.skills ? (
                                      <FormFeedback type="invalid">
                                        {validationExternal.errors.skills}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="pt-2 mb-3">
                                    <Label className="form-label">{t("Active")}</Label> <Input
                                        name="active"
                                        label="active"
                                        type="checkbox"
                                        onChange={(evt) => {}}
                                        onClick={(evt) => { validationExternal.setFieldValue('active', !evt.target.checked , true)}}
                                        checked={validationExternal.values.active}
                                        invalid={
                                          validationExternal.touched.active &&
                                          validationExternal.errors.active
                                            ? true
                                            : false
                                        }
                                      />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> {t('Phone')}</h5>
                                      <div className="mb-3">
                                        <Label className="form-Label">{t('Country Code')}</Label>
                                        <Select
                                          className={classNames({'is-invalid': validationExternal.errors.countryId?true:false})}
                                          name="phone.country_code.value"
                                          options={countries}
                                          value={validationExternal.values.phone.country_code || null}
                                          onChange={(option, actionMeta) => { validationExternal.setFieldValue('phone.country_code', option, true)}}
                                          onBlur={ () => { validationExternal.setFieldTouched('phone.country_code', true, true)} }
                                          placeholder={t('Select')+'...'}
                                        />
                                        {validationExternal.touched.phone?.country_code?.value &&
                                          validationExternal.errors.phone?.country_code?.value ? (
                                          <FormFeedback type="invalid">
                                            {validationExternal.errors.phone?.country_code?.value}
                                          </FormFeedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                      <Label className="form-label">{t("Area Code")}</Label>
                                        <Input
                                          name="phone.area_code"
                                          type="number"
                                          onChange={validationExternal.handleChange}
                                          onBlur={validationExternal.handleBlur}
                                          value={validationExternal.values.phone?.area_code || ""}
                                          invalid={
                                            validationExternal.touched.phone?.area_code &&
                                            validationExternal.errors.phone?.area_code
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationExternal.touched.phone?.area_code &&
                                          validationExternal.errors.phone?.area_code ? (
                                          <FormFeedback type="invalid">
                                            {validationExternal.errors.phone?.area_code}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                      <div className="mb-3">
                                      <Label className="form-label">{t("Phone number")}</Label>
                                        <Input
                                          name="phone.number"
                                          type="number"
                                          onChange={validationExternal.handleChange}
                                          onBlur={validationExternal.handleBlur}
                                          value={validationExternal.values.phone?.number || ""}
                                          invalid={
                                            validationExternal.touched.phone?.number &&
                                            validationExternal.errors.phone?.number
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationExternal.touched.phone?.number &&
                                          validationExternal.errors.phone?.number ? (
                                          <FormFeedback type="invalid">
                                            {validationExternal.errors.phone?.number}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ResponseTeamUsersList)
