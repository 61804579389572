import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

//import components
import SubAdminTab from "../../SubAdmins/SubAdminList";

//Import Breadcrumb
import { withTranslation } from "react-i18next";
import { getClient } from "../../../helpers/backend_helper";
import ClientProfileTab from "./ClientProfileTab";
import Breadcrumb from "../../../components/Common/Breadcrumb";


const ClientDetail = ({match, t}) => {
  const { id } = match.params;
  const [item, setItem] = useState(null);

  const fetchCountry = async () => {
    const item = await getClient(id)
    setItem(item)
  }

  useEffect(() => {
    fetchCountry()
  }, [])

  //meta title
  document.title = "Details Customer | Tellit";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={t("Customer")} breadcrumbItem={t("Detail")} />

          <Row>
            {/* Render profilemenu */}
            { item && <ClientProfileTab client={item} /> }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ClientDetail)
