import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import * as moment from "moment"

//redux
import { withTranslation } from 'react-i18next';
import { addNotices, deleteNotices, deleteUser, getNotices, putUserResetPassword, updateNotices } from '../../helpers/backend_helper';
import TableContainer2 from '../../components/Common/TableContainer2';

import '../../components/Common/custom.css';
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ConfirmResetPasswordModal from "../../components/Common/ConfirmResetPasswordModal";

const NoticesList = props => {
  const t = props.t;
  const masterItemName = t('Notice');
  const masterItemNamePlural = t('Notices');

  //meta title
  document.title = masterItemName + " List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [countries, setCountries] = useState([]);
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getNotices({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      message: (item && item.message) || "",
      link: (item && item.link) || "",
      //sort_order: (item && item.sort_order) || 1,
      active: (item && 'active' in item) ? item.active : true,
    },
    validationSchema: Yup.object({
      message: Yup.string().required(t("required")),
      link: Yup.string().matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          t('Enter valid url')
      ),
      //sort_order: Yup.number().required(t("required")),
      active: Yup.bool().required(t("required")),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values,
          active: values.active ? values.active : false
        }

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateNotices(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm()
      } else {
        const newItem = {
          message: values["message"],
          link: values["link"],
          //sort_order: values["sort_order"],
          active: values["active"] ? values['active']:false,
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addNotices(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm();
      }
      toggle();
    },
  });

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        { !row.active &&
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row); } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
        }
      </span>
    )
  }

  const columns = [
    {
      dataField: 'message',
      text: t('Message'),
      sort: true
    }, {
      dataField: 'created_at',
      text: t('Date and time sent'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.active ? moment(row.date_event).local().format('YYYY-MM-DD HH:mm'):'' },
    }, {
      dataField: 'active',
      text: t('Active'),
      sort: true,
      formatter: (a,row) => { return row.active ? t('Yes'):t('No')}
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteNotices(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleResetPass = async () => {
    const response = await putUserResetPassword(item.masterUser.id)
    setConfirmModal(false);
  };

  const handleItemCreateClick = () => {
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const history = useHistory();
  const handleItemDetailClick = row => {
    history.push("/managment/clients/detail/" + row.id);
  };

  const handleItemEditClick = (row) => {
    validation.resetForm()
    setItem(row);
    setIsEdit(true);
    toggle();
  };

  console.log('tableOpts', tableOpts)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ConfirmResetPasswordModal
        show={confirmModal}
        onConfirmClick={handleResetPass}
        onCloseClick={() => setConfirmModal(false)}
        text={t('Do you want reset password?')}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemNamePlural + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }

                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Message")}</Label>
                                    <Input
                                      name="message"
                                      type="textarea"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      multiple={false}
                                      value={validation.values.message || ""}
                                      invalid={
                                        validation.touched.message &&
                                          validation.errors.message
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.message &&
                                      validation.errors.message ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.message}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Link")}</Label>
                                    <Input
                                      name="link"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.link || ""}
                                      invalid={
                                        validation.touched.link &&
                                          validation.errors.link
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.link &&
                                      validation.errors.link ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.link}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  { (!isEdit || (isEdit && !item.active)) && 
                                  <div className="pt-2 mb-3">
                                    <Label className="form-label">{t("Send immediately with save")}</Label> <Input
                                        name="active"
                                        label="active"
                                        type="checkbox"
                                        onChange={(evt) => {}}
                                        onClick={(evt) => { validation.setFieldValue('active', !evt.target.checked , true)}}
                                        checked={validation.values.active}
                                        invalid={
                                          validation.touched.active &&
                                            validation.errors.active
                                            ? true
                                            : false
                                        }
                                      />
                                  </div>
                                  }
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(NoticesList)
