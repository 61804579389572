import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

//import components
import SubAdminTab from "../../SubAdmins/SubAdminList";

//Import Breadcrumb
import { withTranslation } from "react-i18next";
import { getSponsor } from "../../../helpers/backend_helper";
import SponsorProfileTab from "./SponsorProfileTab";
import Breadcrumb from "../../../components/Common/Breadcrumb";


const SponsorDetail = ({match, t}) => {
  const { id } = match.params;
  const [item, setItem] = useState(null);

  const fetchCountry = async () => {
    const item = await getSponsor(id)
    setItem(item)
  }

  const emptyItem = {
    id: null,
    name: null,
    advertising_type: null,
    start_day: null,
    end_day: null,
    active: true,
    clients: [],
    entities: [],
    sponsorId: null,
  }

  useEffect(() => {
    fetchCountry()
  }, [])

  //meta title
  document.title = "Details Sponsor | Tellit";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={t("Sponsor")} breadcrumbItem={t("Detail")} />

          <Row>
            {/* Render profilemenu */}
            { item && <SponsorProfileTab sponsor={item} /> }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SponsorDetail)
