import React, { useEffect, useRef } from 'react';
import { withTranslation } from "react-i18next";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import './incident.css'

import {
    Container,
    Card,
    Row,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Col,
    TabContent,
    TabPane,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Label,
    FormFeedback,
    Input,
    Table,    
} from "reactstrap";

import * as Yup from "yup";
import classnames from "classnames"
import { useState } from 'react';
import ProfileTab1 from './ProfileTab1';
import { addIncidentAttachments, addIncidentComment, getIncident, postImage, updateIncident } from '../../../helpers/backend_helper';
import { Link } from 'react-router-dom';
import getAuthUser from '../../../components/helpers/auth';
import * as moment from 'moment';
import Dropzone from 'react-dropzone';
import { useFormik } from 'formik';
import classNames from 'classnames';

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const IncidentDetail = ({match, t}) => {
    const currentUser = getAuthUser()
    const masterItemName = t("Incident Detail")
    document.title = masterItemName + " | Tellit";
    const { id } = match.params;
    const [item, setItem] = useState(null);
    const [indexChecked, setIndexChecked] = useState(-1);
    const [modal, setModal] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState (false);
    const textAreaRef = useRef();

    const replaceTexts = (description) => {
      description = description.replace('COMMENT_ADDED_BY', t('COMMENT_ADDED_BY'))
      description = description.replace('FILE_ATTACHED_BY', t('FILE_ATTACHED_BY'))
      description = description.replace('CHANGE_TO_COMPLETED_BY_ACTIONS', t('CHANGE_TO_COMPLETED_BY_ACTIONS'))
      description = description.replace('CHANGE_STATE_TO_ACEPTED_BY', t('CHANGE_STATE_TO_ACEPTED_BY'))
      description = description.replace('ASSIGNED_TO', t('ASSIGNED_TO'))
      description = description.replace('MARKED_AS', t('MARKED_AS'))
      description = description.replace('COMPLETED', t('COMPLETED'))
      description = description.replace('UNCOMPLETED', t('UNCOMPLETED'))
      description = description.replace('COMPLETED', t('COMPLETED'))
      description = description.replace('ACTION', t('ACTION'))
      description = description.replace('BY', t('BY'))

      return description
    }

    const fetchItem = async () => {
        const item = await getIncident(id)
        try { item.action_steps = JSON.parse(item.action_steps) } catch(err) { }
        let firstChecked = -1

        for(let i = 0; i < Object.keys(item.action_steps).length; i++) {
            console.log(item.action_steps[i], i)
            if(item.action_steps[i].checked){
                firstChecked = i
                break
            }
        }
        console.log('firstChecked', firstChecked)
        setItem(item)
        setIndexChecked(firstChecked)
    }

    useEffect(() => {
        fetchItem()
    }, [])

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        internal: true,
        assetId: '',
      },
      validationSchema: Yup.object({
        internal: Yup.boolean().required(t("required")),
        assetId: Yup.string().required(t("required")),
      }),
      onSubmit: async values => {
          const newItem = {
            evidences: [
              { ...values }
            ],
          }
          setLoadingBtn(true)
          const itemResponse = await addIncidentAttachments(item.id, newItem)
          fetchItem()
          setLoadingBtn(false)
          validation.resetForm()
          setSelectedFiles([])
          toggle()
      },
    })

    const validationComments = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        attach_comment: '',
      },
      validationSchema: Yup.object({
        attach_comment: Yup.string().test('len', t('validation_min_5'), (value) => { return value?.trim().length > 5 }).required(),
      }),
      onSubmit: async values => {
          const newItem = {
            ...values
          }
          setLoadingBtn(true)
          const itemResponse = await addIncidentComment(item.id, newItem)
          fetchItem()
          setLoadingBtn(false)
          validationComments.resetForm()
          textAreaRef.current.value = ""
      },
    })
    
    async function changeStatus(idx) {
        const newValue = {...item.action_steps}
        newValue[idx].checked = !item.action_steps[idx].checked
        const incident = await updateIncident(item.id, {action_steps: JSON.stringify(newValue)})
        incident.action_steps = JSON.parse(incident.action_steps)
        let firstChecked = -1
        for(let i = 0; i < Object.keys(item.action_steps).length; i++) {
            if(item.action_steps[i].checked){
              firstChecked = i
            } else {
              break
            }
        }
        setItem({...incident})
        setIndexChecked(firstChecked)
    }

    const handleAddAttachmentClick = () => {
        toggle()
    }

    const toggle = () => {
        setModal(!modal)
    }

    const [selectedFiles, setSelectedFiles] = useState([])

    async function handleAcceptedFiles(files) {
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      console.log('handleAcceptedFiles', files)
      setSelectedFiles(files)
      validation.setFieldValue('assetId','1',true)
    }
    
    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes"
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const handleUploadFile = async () => {
      if (selectedFiles.length == 0) {
        validation.setFieldValue('assetId', '')
        validation.setFieldTouched('assetId', true)
        validation.validateForm(validation.values)
        return
      }

      const file = await submissionFiles()
      validation.values.assetId = file.id
      //validation.setFieldValue('assetId', file.id)
      //validation.setFieldTouched('assetId', true)
      validation.submitForm()
    }

    const submissionFiles = async () => {
      let result = await postImage(selectedFiles[0])
      return result
    }

    const [activeTab, toggleTab] = useState("1");

    return (
         <React.Fragment>
            { item &&
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs titleLink={'/incidents/work-on'} title={t("Working on")} breadcrumbItem={t('Details of') + ' ' + (item.emergency ? t("Call me"):t("Incident"))} />
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="8" className="order-2 order-sm-1">
                                        <div className="d-flex align-items-start mt-3 mt-sm-0">
                                            <div className="flex-grow-1">
                                                <div>
                                                    <h5 className="font-size-16 mb-1">{item.subtype} ({t('Status')}: {t(item.status)})</h5>
                                                    <p className="text-muted font-size-13">
                                                      {t("Type")}: {item.type}<br />
                                                      
                                                    </p>
                                                    <Row>
                                                        <Col md="8" className="order-2 order-sm-1">
                                                            <p>
                                                              <b>{t('Id')}: </b>: {item.id.split('-')[4]} <br />
                                                              <b>{t('Reported by')}: </b> {item.userName} <br />
                                                              <b>{t('Owner')}: </b> {item.userOwner ? item.userOwner.first_name + ' ' + item.userOwner.last_name:'Sin asignar'} <br />
                                                              <b>{t('Comments')}: </b> {item.comments} <br />
                                                              <b>{t('Question')}: </b>
                                                              {
                                                                item && item.answers && ('0' in JSON.parse(item.answers)) &&
                                                                <i>{JSON.parse(item.answers)['0']} </i>
                                                              }
                                                              {
                                                                item && item.answers && ('1' in JSON.parse(item.answers)) &&
                                                                <>
                                                                  <span>{JSON.parse(item.answers)['1']}</span> 
                                                                </>
                                                              }
                                                              <br />
                                                              <b>{t('System creation time')}: </b> {moment.utc(item.created_at).local().format('YYYY-MM-DD HH:mm')}
                                                            </p>
                                                        </Col>
                                                        <Col md="4" className="order-2 order-sm-1">
                                                            <div><i className="mdi mdi-pin text-primary me-1"></i>{item.entityName} {item.location ? '|' + item.location:''}</div>
                                                            <div><i className="mdi mdi-calendar me-1 text-primary align-middle"></i>{item.date_event ? moment.utc(item.date_event).local().format('YYYY-MM-DD'):''}</div>
                                                            <div><i className="mdi mdi-clock-outline me-1 text-primary align-middle"></i>{item.date_event ? moment.utc(item.date_event).local().format('HH:mm'):''}</div>
                                                            <div><i className="fas fa-map-marker text-primary align-middle"></i> {item.locationGps}</div> 
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="4" className="order-2 order-sm-1">
                                        <div className="d-flex align-items-start justify-content-end gap-2">
                                            <button
                                              type='button'
                                              className="btn btn-primary btn-sm float-end"
                                              onClick={() => { fetchItem() }}  
                                            >
                                              <i className='fas fa-sync'></i> {t("Update")}
                                            </button>
                                            
                                            <div className='d-none'>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="btn btn-link font-size-16 shadow-none text-muted" tag="a">
                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <li><DropdownItem href="#">Action</DropdownItem></li>
                                                        <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                        <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({
                                                active: activeTab === "1",
                                            }, "px-3")}
                                            onClick={() => {
                                                toggleTab("1")
                                            }}>
                                            {t('Actions')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({
                                                active: activeTab === "2",
                                            }, "px-3")}
                                            onClick={() => {
                                                toggleTab("2")
                                            }}
                                        >{t('Attachments')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({
                                                active: activeTab === "4",
                                            }, "px-3")}
                                            onClick={() => {
                                                toggleTab("4")
                                            }}
                                        >{t('Comments')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={classnames({
                                                active: activeTab === "3",
                                            }, "px-3")}
                                            onClick={() => {
                                                toggleTab("3")
                                            }}
                                        >{t('History')}</NavLink>
                                    </NavItem>
                                </Nav>
                            </CardBody>
                        </Card>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Card>
                                    <CardBody>
                                        <div>
                                          { item.userOwner?.id == currentUser.id && Object.keys(item.action_steps).map((f, idx) => {
                                            return  ((item.status != 'CLOSED_COMPLETED') && idx == (indexChecked + 1)) ? (
                                                <div className="form-check" key={idx}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={"formCheck"+f}
                                                        checked={item.action_steps[f].checked}
                                                        onClick={e => {changeStatus(f)}}
                                                    />
                                                    <label className="form-check-label" htmlFor={"formCheck"+f}>
                                                       { item.action_steps[f].action }
                                                    </label>
                                                </div>
                                            ) :
                                            (
                                                <div key={idx}>
                                                    { !item.action_steps[f].checked ?  
                                                        <i className='fas fa-times text-danger'></i> : <i className='fas fa-check text-success'></i>
                                                    }
                                                    <label className="form-check-label ms-2" htmlFor="formCheck2">
                                                       { item.action_steps[f].action }
                                                    </label>
                                                </div>
                                            )
                                          })}

                                        { item.userOwner?.id != currentUser.id && Object.keys(item.action_steps).map((f, idx) => {
                                            return  (
                                                <div key={idx}>
                                                    { !item.action_steps[f].checked ?  
                                                        <i className='fas fa-times text-danger'></i> : <i className='fas fa-check text-success'></i>
                                                    }
                                                    <label className="form-check-label ms-2" htmlFor="formCheck2">
                                                       { item.action_steps[f].action }
                                                    </label>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId="2">
                                <Card>
                                    <CardBody>
                                        <Table>
                                          <thead>
                                          <tr>
                                            <th>{t('Internal')}</th>
                                            <th>{t('User')}</th>
                                            <th>{t('Attachment')}</th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {item.evidences.map((f, i) => {
                                            return (
                                              <tr key={i + "-file"}>
                                                <td>{f.attrs?.internal ? t('Yes'):t('No')}</td>
                                                <td>{f.attrs?.user ? f.attrs?.user:t('Incident creator')}</td>
                                                <td>
                                                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                    <div className="p-2">
                                                      <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                          <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f.name}
                                                            src={API_URL + '/' + f.path}
                                                          />
                                                        </Col>
                                                        <Col>
                                                            <Link to={{ pathname: API_URL + '/' + f.path }} target="_blank" rel="noopener noreferrer"> 
                                                                {f.name}
                                                            </Link>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Card>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                          </tbody>
                                        </Table>
                                        { true && (
                                        <div className="text-sm-center" style={{marginTop: '15px'}}>
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn btn-md mb-2 me-2"
                                                onClick={handleAddAttachmentClick}
                                            >
                                                <i className='fas fa-plus'></i> {t("Add attachment")}
                                            </Button>
                                        </div>
                                        )}
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId="3">
                                <Card>
                                    <CardBody>
                                        <ul className="verti-timeline list-unstyled">
                                            { item.logs.map((e,idx) => {
                                                return (
                                            <li className="event-list" key={idx}>
                                                <div className="event-timeline-dot">
                                                    <i className="bx bx-right-arrow-circle font-size-18"></i>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 me-3">
                                                        <h5 className="font-size-14">{moment.utc(e.created_at).local().format('YYYY-MM-DD HH:mm')} <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i></h5>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div>
                                                             {replaceTexts(e.description)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                                )
                                            }) }
                                        </ul>
                                    </CardBody>
                                </Card>
                            </TabPane>
                            <TabPane tabId="4">
                                <Card>
                                    <CardBody>
                                      {item.attach_comments.map((comment, i) => {
                                        return (
                                          <Row key={i} className="align-items-center">
                                            <Col md="8">
                                              <p className="alert alert-danger alert-top-border alert-dismissible fade show">
                                                <b>{t('Date')}:</b> {comment.created_at} <br />
                                                <b>{t('User')}:</b> {comment.commentator} <br />
                                                <b>{t('Comment')}:</b> {comment.comment}
                                              </p>
                                            </Col>
                                          </Row>  
                                        )
                                      })}
                                    
                                      <Form
                                        onSubmit={e => {
                                          e.preventDefault()
                                          validationComments.handleSubmit()
                                          return false
                                        }}
                                      >
                                        <Row className='pt-5'>
                                          <Col xs={8}>
                                              <div className={classNames({"d-none": false, "is-invalid": validationComments.touched.attach_comment && validationComments.errors.attach_comment ? true:false})}>
                                                <label>{t('Add_Comment')}</label>
                                                <textarea 
                                                  onChange={ (evt) => { validationComments.setFieldValue('attach_comment', evt.target.value) } }
                                                  id='attach_comment'
                                                  name='attach_comment'
                                                  className='form-control'
                                                  maxLength={255}
                                                  value={validationComments.attach_comment}
                                                  ref={textAreaRef}
                                                  ></textarea>
                                              </div>
                                              {validationComments.touched.attach_comment &&
                                                  validationComments.errors.attach_comment ? (
                                                  <FormFeedback type="invalid" className="invalid">
                                                    {validationComments.errors.attach_comment}
                                                  </FormFeedback>
                                                ) : null}
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={8}>
                                            <div className="text-center" style={{paddingTop: '25px'}}  >
                                              <button
                                                type="submit"
                                                className="btn btn-primary btn-md save-user"
                                              >
                                                <i className="fas fa-save"></i> {t("Save")}
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </CardBody>
                                </Card>
                            </TabPane>
                        </TabContent>
                        <Modal isOpen={modal}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {t("Add Attachment")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                    <div className={classNames({"d-none": false, "is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                                      { selectedFiles.length == 0 && <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onFileDialogOpen={() => {
                                          validation.setFieldTouched('assetId', true, true)
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone" style={{minHeight: '120px'}}>
                                            <div
                                              className="text-center needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-5 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h5>{ t('Drop files here or click to upload') }</h5>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      }
                                      <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    {f.name}
                                                    <button 
                                                      type="button"
                                                      className="btn btn-danger btn-sm float-end"
                                                      onClick={() => { setSelectedFiles([]) }}
                                                    >
                                                      <i className="fa fa-times"></i>
                                                    </button>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          )
                                        })}
                                      </div>
                                    </div>
                                    {validation.touched.assetId &&
                                        validation.errors.assetId ? (
                                        <FormFeedback type="invalid" className="invalid">
                                          {validation.errors.assetId}
                                        </FormFeedback>
                                      ) : null}
                                    
                                    <div className="pt-2 mb-3">
                                      <Label className="form-label">{t("Internal")}</Label> <Input
                                          name="internal"
                                          label="internal"
                                          type="checkbox"
                                          onChange={(evt) => {}}
                                          onClick={(evt) => { validation.setFieldValue('internal', !evt.target.checked , true)}}
                                          checked={validation.values.internal}
                                          invalid={
                                            validation.touched.internal &&
                                              validation.errors.internal
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center" style={{paddinTop: '15px'}}  >
                                    <button
                                      type="button"
                                      onClick={handleUploadFile}
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>

                </Container>
            </div>
            }
        </React.Fragment>
    );
};

export default withTranslation()(IncidentDetail)


                 