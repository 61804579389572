import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';

import classnames from "classnames"
import { withTranslation } from 'react-i18next';
import DivisionsTab from './DivisionsTab';
import IncidentTypesTab from './IncidentTypesTab';
import InternalUsersTab from './InternalUsersTab';
import LocationTab from './LocationTab';
import IncidentTypesTabNew from './IncidentTypesTabNew';
import IncidentSubTypestabNew from './IncidentSubTypesTabNew';
import QrTab from './qrTab';
import IncidentSubtypesLocationsTab from './IncidentSubtypesLocationsTab';

const EntityProfileTab = ({entity, t}) => {
    const [activeTab, toggleTab] = useState("1");

    console.log('activeTab',activeTab)
    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col-sm order-2 order-sm-1">
                                    <div className="d-flex align-items-start mt-3 mt-sm-0">
                                        <div className="flex-grow-1">
                                            <div>
                                                <h5 className="font-size-16 mb-1 d-none">{t('Entity')} { entity?.name }</h5>
                                                <p className='d-flex flex-wrap align-items-start gap-3'>
                                                    <div><b>{t('Description')}:</b> {entity.description}</div>
                                                    <div><b>{t('Customer')}:</b> {entity.client.name}</div>
                                                    <div><b>{t('Email')}:</b> {entity.email}</div>
                                                </p>

                                                <div className="d-flex flex-wrap align-items-start gap-3 gap-lg-3 text-muted font-size-13">
                                                    <div>{t('Active')}: <i className="fa fa-check text-success align-middle"></i></div>
                                                    <div>{t('AdBlocking')}: <i className="fa fa-times text-danger align-middle"></i></div>
                                                    <div>{t('Allow Emergency Selection T3 user')}: <i className="fa fa-times text-danger align-middle"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-auto order-1 order-sm-2">
                                    <div className="d-flex align-items-start justify-content-end gap-2 d-none">
                                        <div>
                                            <button type="button" className="btn btn-soft-light"><i className="me-1"></i> Message</button>
                                        </div>
                                        <div>
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="btn btn-link font-size-16 shadow-none text-muted" tag="a">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </div>
                            </Row>

                            <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "1",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("1")
                                        }}>
                                        {t('Divisions')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "4",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("4")
                                        }}
                                    >{t('Locations')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "6",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("6")
                                        }}>
                                        {t('Incident Types')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "7",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("7")
                                        }}>
                                        {t('Incident Subtypes')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "9",
                                            "d-none": true
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("9")
                                        }}>
                                        {t('Incident Subtypes Locations')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "3",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("3")
                                        }}
                                    >{t('Internal Users')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "5",
                                            "d-none": true
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("5")
                                        }}
                                    >{t('Configuration')}</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        href="javascript://"
                                        className={classnames({
                                            active: activeTab === "8",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("8")
                                        }}
                                    >{t('Link Users')}</NavLink>
                                </NavItem>

                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            { activeTab=="1" && <DivisionsTab entity={entity} />}
                        </TabPane>
                        <TabPane tabId="2">
                            { activeTab=="2" && <IncidentTypesTab entity={entity}/>}
                        </TabPane>
                        <TabPane tabId="3">
                            { activeTab=="3" && <InternalUsersTab entity={entity}/>}
                        </TabPane>
                        <TabPane tabId="4">
                            { activeTab=="4" && <LocationTab entity={entity}></LocationTab> }
                        </TabPane>
                        <TabPane tabId="5">
                            
                        </TabPane>
                        <TabPane tabId="6">
                            { activeTab=="6" && <IncidentTypesTabNew entity={entity}></IncidentTypesTabNew>}
                        </TabPane>
                        <TabPane tabId="7">
                            { activeTab=="7" && <IncidentSubTypestabNew entity={entity}></IncidentSubTypestabNew>}
                        </TabPane>
                        <TabPane tabId="8">
                            { activeTab=="8" && <QrTab entity={entity}></QrTab>}
                        </TabPane>
                        <TabPane tabId="9">
                            { activeTab=="9" && <IncidentSubtypesLocationsTab entity={entity}></IncidentSubtypesLocationsTab>}
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
}

EntityProfileTab.propTypes = {
    entity: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(EntityProfileTab)