import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import DeleteModal from "../../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { addLanguage, deleteLanguage, getLanguages, updateLanguage } from '../../../../helpers/backend_helper';
import TableContainer2 from '../../../../components/Common/TableContainer2';

import '../../../../components/Common/custom.css';

const CountriesList = props => {
  const t = props.t;
  const masterItemName = t('Language');
  const masterItemNamePlural = t('Languages');

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);

  const [tableOpts, setTableOpts] = useState({
    order: 'ASC',
    orderField: 'name',
    page: 1,
    take: 15,
    search: null
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getLanguages({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (item && item.name) || "",
      code: (item && item.code) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("required")),
      code: Yup.string().required(t("required")),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          name: values.name,
          code: values.code,
        };

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateLanguage(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm();
      } else {
        const newItem = {
          name: values["name"],
          code: values["code"],
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addLanguage(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm();
      }
      toggle();
    },
  });

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t('Edit')}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row); } }
          title={t('Delete')}>
            <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'name',
      text: t('Name'),
      sort: true
    }, {
      dataField: 'code',
      text: t('Code'),
      sort: true
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];


  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteLanguage(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleItemCreateClick = () => {
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const handleItemEditClick = (row) => {
    setItem(row);
    setIsEdit(true);
    toggle();
  };

  console.log('tableOpts', tableOpts)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">
                     
                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }
                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal} >
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Name")}</Label>
                                    <Input
                                      name="name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name &&
                                          validation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.name &&
                                      validation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">{t("Code")}</Label>
                                      <Input
                                        name="code"
                                        label="code"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.code || ""}
                                        invalid={
                                          validation.touched.code &&
                                            validation.errors.code
                                            ? true
                                            : false
                                        }
                                        maxLength={2}
                                      />
                                      {validation.touched.code &&
                                        validation.errors.code ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.code}
                                        </FormFeedback>
                                      ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CountriesList)

