import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { addLocation, deleteLocation, getLocations, updateLocation } from '../../../helpers/backend_helper';
import TableContainer2 from '../../../components/Common/TableContainer2';

import '../../../components/Common/custom.css';
import getAuthUser from "../../../components/helpers/auth";
import TableContainer4 from "../../../components/Common/TableContainer4";
import QRCode from "react-qr-code";

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const QrTab = props => {
  const t = props.t;
  const entity = props.entity;
  const currentUser = getAuthUser()
  const masterItemName = t('QR');
  const masterItemNamePlural = t('QRs');

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [teams, setTeams] = useState([]);
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);
  const [updateAsset, setUpdateAsset] = useState (false);


  const fetchData = async () => {
    setLoading(true)
    const response = await getLocations({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  /*
  useEffect(() => {
    fetchData()
  }, [tableOpts])
*/
  
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col xl="4" className="text-center">
              <h4 className="mb-3"><b>{t('Link user t1')}</b></h4>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                  <QRCode
                    id="t1"
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={entity.id + '.' + 1}
                    viewBox={`0 0 256 256`}
                  />
              </div>
              <button
                className="btn btn-primary btn-md mt-3"
                type="button"
                onClick={() => {
                  console.log(document.getElementById("t1"))
                  var svgData = document.getElementById("t1").outerHTML;
                  var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
                  var svgUrl = URL.createObjectURL(svgBlob);
                  var downloadLink = document.createElement("a");
                  downloadLink.href = svgUrl;
                  downloadLink.download = "qr_t1.svg";
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }}
              >
                <i className="fas fa-download"></i> Descargar
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withTranslation()(QrTab)
