import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Col, Row, Form, Label, Input, Card, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from "react-i18next";
import {
  addSponsorsAdvertisments,
  getSponsorsContractsSelect,
  postImage,
  updateSponsorsAdvertisments,
} from "../../../helpers/backend_helper";

import NibbleInput from "../../../components/Fields/NibbleInput";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";

const API_URL = process.env.REACT_APP_TELLIT_API_URL;

const SponsorNoticeForm = ({ t, isEdit, item, onComplete, sponsor }) => {
  //const [countries, setCountries] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entityGroups, setEntityGroups] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateAsset, setUpdateAsset] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  let currentContract;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...item,
      replaceLogo: (item && item.replaceLogo) || false,
    },
    validationSchema: Yup.object({
      contractId: Yup.string().nullable().required(t("required")),
      name: Yup.string().nullable().required(t("required")),
      start_day: Yup.date()
        .nullable()
        .test("start_day", (value, testContext) => {
          if (
            testContext.parent.contractId &&
            currentContract?.start_day != null &&
            dayjs(value).isBefore(
              dayjs(currentContract?.start_day).startOf("day")
            )
          ) {
            return testContext.createError({
              message:
                t("Must be later or equal ") +
                dayjs(currentContract?.start_day).format("DD/MM/YYYY"),
              path: "start_day", // Fieldname
            });
          } else {
            return true;
          }
        }),
      end_day: Yup.date()
        .nullable()
        .test("end_day", (value, testContext) => {
          if (
            testContext.parent.contractId &&
            currentContract?.end_day != null &&
            dayjs(value).isAfter(dayjs(currentContract?.end_day).endOf("day"))
          ) {
            return testContext.createError({
              message:
                t("Must be before or equal ") +
                dayjs(currentContract?.end_day).format("DD/MM/YYYY"),
              path: "end_day", // Fieldname
            });
          } else {
            return true;
          }
        }),
      message: Yup.string().when("contractId", {
        is: (contractId) => {
          return currentContract?.advertising_type === "Level 2";
        },
        then: Yup.string().nullable().required("required"),
        otherwise: Yup.string().nullable(),
      }),
      logoId: Yup.string().nullable().required(t("required")),
      entities: Yup.array()
        .min(1, "Debe seleccionar por lo menos 1 entidad")
        .required(t("required")),
    }),
    onSubmit: async (values) => {
      const newItem = isEdit ? { id: item.id, ...values } : { ...values };

      console.log(newItem);
      setLoadingBtn(true);
      const itemResponse = isEdit
        ? await updateSponsorsAdvertisments(item.id, newItem)
        : await addSponsorsAdvertisments(newItem);
      setLoadingBtn(false);
      onComplete(itemResponse);
    },
  });

  currentContract = contracts.find((e) => {
    return e.id == validation.values.contractId;
  });

  const setEntitiesAndGroups = () => {
    if (currentContract) {
      const groups = {};
      let entities = currentContract.entities.map((e) => {
        e.groupId = e.groups[0]?.id;
        e.groupName = e.groups[0]?.name;
        groups[e.groupId] = e.groupName;
        return e;
      });

      setEntities(entities);
      setEntityGroups(groups);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await getSponsorsContractsSelect({
      params: { sponsorId: sponsor.id },
    });
    setContracts(response);
    setLoading(false);
    setEntitiesAndGroups();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (validation?.values?.contractId) {
      setEntitiesAndGroups();
      validation.setFieldValue(
        "entities",
        currentContract?.entities.map((e) => e.id),
        true
      );
    }
  }, [validation?.values?.contractId, contracts]);

  async function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    const file = await submissionFiles(files);
    console.log(file.id);
    validation.setFieldValue("logoId", file.id, true);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submissionFiles = async (files) => {
    let result = await postImage(files[0]);
    return result;
  };

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col xs={12}>
            <div className="d-none mb-3" style={{ wordBreak: "break-word" }}>
              {JSON.stringify(validation.errors)}
            </div>

            <NibbleInput
              md={12}
              label={t("Contract")}
              fieldName="contractId"
              fieldType="select"
              selectItems={contracts}
              validation={validation}
              selectOptionLabel={(option) =>
                `${option.advertising_type} - ${option.name}`
              }
            />

            <NibbleInput
              md={12}
              label={t("Name")}
              fieldName="name"
              fieldType="tet"
              validation={validation}
            />

            <div
              className={classNames({
                "is-invalid":
                  validation.touched.logoId && validation.errors.logoId
                    ? true
                    : false,
              })}
            >
              {validation.values.logoId && item?.logo?.path && (
                <div
                  className={classNames({
                    "d-none":
                      updateAsset || validation.values.logoId == ""
                        ? true
                        : false,
                    "pt-2": true,
                    "mb-3": true,
                  })}
                >
                  <Label className="form-label">{t("Current Logo")}</Label>
                  <br />
                  <img height="100" src={API_URL + "/" + item.logo.path}></img>
                  <br />
                  <button
                    type="button"
                    onClick={() => {
                      validation.setFieldValue("logoId", "");
                      setUpdateAsset(true);
                    }}
                    className="btn btn-primary btn-sm save-user mt-1"
                  >
                    <i className="fas fa-sync"></i> {t("Change")}
                  </button>
                </div>
              )}
              <Label className="form-label mt-2">{t("Logo")}</Label>
              <br />
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
                onFileDialogOpen={() => {
                  validation.setFieldTouched("logoId", true, true);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone" style={{ minHeight: "120px" }}>
                    <div
                      className="text-center needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-1">
                        <i className="display-5 text-muted bx bx-cloud-upload" />
                      </div>
                      <h5>{t("Drop files here or click to upload")}</h5>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
            {validation.touched.logoId && validation.errors.logoId ? (
              <FormFeedback type="invalid" className="invalid">
                {validation.errors.logoId}
              </FormFeedback>
            ) : null}

            {currentContract?.advertising_type == "Level 2" && (
              <NibbleInput
                md={12}
                label={t("Advertising text")}
                fieldName="message"
                fieldType="textarea"
                maxLength={250}
                validation={validation}
              />
            )}
            <NibbleInput
              md={12}
              label={t("Start Day")}
              fieldName="start_day"
              fieldType="date"
              validation={validation}
            />
            <NibbleInput
              md={12}
              label={t("End Day")}
              fieldName="end_day"
              fieldType="date"
              validation={validation}
            />

            <Row className="pt-3">
              <Col>
                <Label>{t("Customer")}</Label>
                <div>{currentContract?.client?.name}</div>
              </Col>
            </Row>

            <Row className="mb-4 pt-3">
              <Label className="form-label fw-bold">{t("Entities")}</Label>
              {Object.keys(entityGroups).map((gId, ie) => (
                <Col key={gId} className="mt-2" md={6}>
                  <Input
                    type="checkbox"
                    id={gId}
                    name={gId}
                    onClick={(event) => {
                      console.log(event.target.checked, event.target.value);
                      let newValue = [...validation.values["entities"]];
                      if (!event.target.checked) {
                        const newEntities = entities.filter(
                          (e) => e.groupId == event.target.value
                        );
                        console.log(newValue);
                        newEntities.forEach((e) => {
                          let obj = newValue.find((c) => c == e.id);
                          if (!obj) newValue.push(e.id);
                        });
                      } else {
                        const deleteEntities = entities
                          .filter((e) => e.groupId == event.target.value)
                          .map((e) => e.id);
                        newValue = newValue.filter(
                          (e) => !deleteEntities.includes(e)
                        );
                      }
                      validation.setFieldValue("entities", newValue, true);
                    }}
                    onBlur={validation.handleBlur}
                    value={gId}
                    checked={
                      validation.values["entities"].filter(
                        (e) => entities.find((o) => o.id == e).groupId == gId
                      ).length > 0
                    }
                  />{" "}
                  <Label for={gId} className="form-label fw-bold">
                    {entityGroups[gId]}
                  </Label>
                  <div className="ps-4">
                    <NibbleInput
                      key={gId}
                      md={12}
                      label={null}
                      fieldName={"entities"}
                      fieldType="checkbox"
                      selectItems={entities.filter((c) => c.groupId == gId)}
                      validation={validation}
                    />
                  </div>
                </Col>
              ))}
            </Row>

            <NibbleInput
              md={12}
              label={t("Active")}
              fieldName="active"
              fieldType="boolean"
              validation={validation}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-md save-user"
              >
                <i className="fas fa-save"></i> {t("Save")}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

SponsorNoticeForm.propTypes = {
  item: PropTypes.object.required,
  clients: PropTypes.array.required,
  t: PropTypes.any,
  onComplete: PropTypes.function,
  isEdit: PropTypes.bool,
};

export default withTranslation()(SponsorNoticeForm);
