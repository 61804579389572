import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import getAuthUser from "../../components/helpers/auth";

const UserProfile = props => {
  const currentUser = getAuthUser()
  const t = props.t;
  //meta title
  document.title = t("Profile") + " | Tellit";

  const dispatch = useDispatch()

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const [user, setUser] = useState({})
  const [idx, setidx] = useState(1)

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);
      setUser(obj)
      
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      new_password: '',
      repassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string(),
      new_password: Yup.string().trim().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\\.#$@!%&*?])[A-Za-z\d#$\\.@!%&*?]{8,30}$/, t('Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character') + ' ' + t('(the special characters supported are .#$@!%&*?)')).required(t("required")),
      repassword:  Yup.string().oneOf([Yup.ref('new_password'), null], t('Passwords must match')).required(t("required"))
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Tellit" breadcrumbItem={t("Profile")} />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{user.first_name} {user.last_name}</h5>
                        <p className="mb-1">{user.email}</p>
                        <p className="mb-0">{t("Role")}: {t(user.role)}</p>
                        {
                          user.role == 'SPONSOR' && 
                          <>
                          <p className="mb-0">{t("Contact person")} {t("First name")}: {t(user.sponsor.contact_fname)}</p>
                          <p className="mb-0">{t("Contact person")} {t("Last name")}: {t(user.sponsor.contact_lname)}</p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">{t("Change Password")}</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">{t("Current Password")}</Label>
                  <Input
                    name="password"
                    className="form-control"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password ? true : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>

                <div className="form-group mt-2">
                  <Label className="form-label">{t("New Password")}</Label>
                  <Input
                    name="new_password"
                    className="form-control"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.new_password || ""}
                    invalid={
                      validation.touched.new_password && validation.errors.new_password ? true : false
                    }
                  />
                  {validation.touched.new_password && validation.errors.new_password ? (
                    <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>

                <div className="form-group mt-2">
                  <Label className="form-label">{t("Repeat New Password")}</Label>
                  <Input
                    name="repassword"
                    className="form-control"
                    type="password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.repassword || ""}
                    invalid={
                      validation.touched.repassword && validation.errors.repassword ? true : false
                    }
                  />
                  {validation.touched.repassword && validation.errors.repassword ? (
                    <FormFeedback type="invalid">{validation.errors.repassword}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" />
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    {t("Update Password")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation ()(UserProfile)