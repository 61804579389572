import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//import images
import logo from "../../assets/images/tellit_logo.png"
import getAuthUser from '../../components/helpers/auth';
import CarouselPage from './CarouselPage';

window.onpopstate = function(event) {
    history.go(1);
};

const LockScreen = ({t}) => {
    const user = getAuthUser()

    const [ locationKeys, setLocationKeys ] = useState([])
    const history = useHistory()

    useEffect(() => {
        alert('1');
        return history.listen(location => {
            
            if (history.action === 'PUSH') {
                setLocationKeys([ location.key ])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([ _, ...keys ]) => keys)

                    // Handle forward event

                } else {
                    setLocationKeys((keys) => [ location.key, ...keys ])

                    // Handle back event
                    history.go(1);
                }
            }
        })
    }, [ history ])

    //meta title
    document.title = "Lock Screen | Tellit";
    return (
        <React.Fragment>
            <div className="auth-page">
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col className="col-xxl-3 col-lg-4 col-md-5">
                            <div className="auth-full-page-content d-flex p-sm-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="mb-4 mb-md-5 text-center">
                                            <Link to="/dashboard" className="d-block auth-logo">
                                                <img src={logo} alt="" height="100" />
                                            </Link>
                                        </div>
                                        <div className="auth-content my-auto">
                                            <div className="text-center">
                                                <h5 className="mb-0">{t('Locked screen')}</h5>
                                                <p className="text-muted mt-2">{t('Enter your password to unlock the screen')}!</p>
                                            </div>
                                            <div className="user-thumb text-center mb-4 mt-4 pt-2">
                                                <h5 className="font-size-15 mt-3">{user.email}</h5>
                                            </div>
                                            <form className="custom-form mt-4">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="userpassword">{t('Password')}</label>
                                                    <input type="password" className="form-control" id="userpassword" />
                                                </div>
                                                <div className="mb-3 mt-4">
                                                    <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">{t('Unlock')}</button>
                                                </div>
                                            </form>

                                            <div className="mt-5 text-center">
                                                <p className="text-muted mb-0">{t('Not you? return')} <Link to="/login"
                                                    className="text-primary fw-semibold"> {t('Sign In')} </Link> </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 mt-md-5 text-center">
                                            <p className="mb-0">© {new Date().getFullYear()} Tellit .</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <CarouselPage />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation ()(LockScreen)