import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Col, Row, Form, Label, Input } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from "react-i18next";
import {
  addSponsorsContracts,
  getEntitiesSelect,
  updateSponsorsContracts,
} from "../../../helpers/backend_helper";

import NibbleInput from "../../../components/Fields/NibbleInput";

const SponsorContractForm = ({ t, isEdit, item, onComplete, clients }) => {
  //const [countries, setCountries] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entityGroups, setEntityGroups] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: { ...item },
    validationSchema: Yup.object({
      //countryId: Yup.object().required(t("required")),
      name: Yup.string().nullable().required(t("required")),
      advertising_type: Yup.string().nullable().required(t("required")),
      clientId: Yup.string().nullable().required(t("required")),
      entities: Yup.array().required(t("required")),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values,
        };
        console.log(updateItem);

        setLoadingBtn(true);
        const itemResponse = await updateSponsorsContracts(item.id, updateItem);
        setLoadingBtn(false);

        validation.resetForm();
      } else {
        const newItem = {
          ...values,
        };
        setLoadingBtn(true);
        const itemResponse = await addSponsorsContracts(newItem);
        setLoadingBtn(false);
        validation.resetForm();
      }
      onComplete();
    },
  });

  const fetchEntitiesByClient = async (clientId) => {
    let entities = [];
    if (clientId) {
      entities = await getEntitiesSelect({ params: { clientId } });

      const groups = {};
      entities = entities.map((e) => {
        e.groupId = e.groups[0]?.id;
        e.groupName = e.groups[0]?.name;
        groups[e.groupId] = e.groupName;
        return e;
      });

      setEntityGroups(groups);
      setEntities(entities);
      validation.setFieldValue(
        "entities",
        entities.map((e) => e.id)
      );
    }
    return entities;
  };

  useEffect(() => {
    fetchEntitiesByClient(validation?.values?.clientId);
  }, [validation?.values?.clientId]);

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col xs={12}>
            <div className="d-none mb-3" style={{ wordBreak: "break-word" }}>
              {JSON.stringify(validation.values)}
            </div>
            <NibbleInput
              md={12}
              label={t("Name")}
              fieldName="name"
              fieldType="tet"
              validation={validation}
            />
            <NibbleInput
              md={12}
              placeHolder={t("Select")}
              label={t("Advertising type")}
              fieldName="advertising_type"
              fieldType="select"
              selectItems={[
                { name: "Level 1", id: "Level 1" },
                { name: "Level 2", id: "Level 2" },
              ]}
              validation={validation}
            />
            <NibbleInput
              md={12}
              label={t("Start Day")}
              fieldName="start_day"
              fieldType="date"
              validation={validation}
            />
            <NibbleInput
              md={12}
              label={t("End Day")}
              fieldName="end_day"
              fieldType="date"
              validation={validation}
            />
            <NibbleInput
              md={12}
              placeHolder={t("Select")}
              label={t("Client")}
              fieldName="clientId"
              fieldType="select"
              selectItems={clients ?? []}
              validation={validation}
            />

            <Row className="mb-4 pt-3">
              <Label className="form-label fw-bold">{t("Entities")}</Label>
              {Object.keys(entityGroups).map((gId, ie) => (
                <Col key={gId} className="mt-2" md={6}>
                  <Input
                    type="checkbox"
                    id={gId}
                    name={gId}
                    onClick={(event) => {
                      console.log(event.target.checked, event.target.value);
                      let newValue = [...validation.values["entities"]];
                      if (!event.target.checked) {
                        const newEntities = entities.filter(
                          (e) => e.groupId == event.target.value
                        );
                        console.log(newValue);
                        newEntities.forEach((e) => {
                          let obj = newValue.find((c) => c == e.id);
                          if (!obj) newValue.push(e.id);
                        });
                      } else {
                        const deleteEntities = entities
                          .filter((e) => e.groupId == event.target.value)
                          .map((e) => e.id);
                        newValue = newValue.filter(
                          (e) => !deleteEntities.includes(e)
                        );
                      }
                      validation.setFieldValue("entities", newValue, true);
                    }}
                    onBlur={validation.handleBlur}
                    value={gId}
                    checked={
                      validation.values["entities"].filter(
                        (e) => entities.find((o) => o.id == e).groupId == gId
                      ).length > 0
                    }
                  />{" "}
                  <Label for={gId} className="form-label fw-bold">
                    {entityGroups[gId]}
                  </Label>
                  <div className="ps-4">
                    <NibbleInput
                      key={gId}
                      md={12}
                      label={null}
                      fieldName={"entities"}
                      fieldType="checkbox"
                      selectItems={entities.filter((c) => c.groupId == gId)}
                      validation={validation}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-md save-user"
              >
                <i className="fas fa-save"></i> {t("Save")}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

SponsorContractForm.propTypes = {
  item: PropTypes.object.required,
  clients: PropTypes.array.required,
  t: PropTypes.any,
  onComplete: PropTypes.function,
  isEdit: PropTypes.bool,
};

export default withTranslation()(SponsorContractForm);
