import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

//redux
import { withTranslation } from "react-i18next";
import {
  addSponsor,
  deleteProvider,
  deleteSponsor,
  getSectionsSelect,
  getSponsors,
  putUserResetPassword,
  updateSponsor,
} from "../../helpers/backend_helper";
import TableContainer2 from "../../components/Common/TableContainer2";

import "../../components/Common/custom.css";
import classNames from "classnames";
import ConfirmModal from "../../components/Common/ConfirmModal";
import getAuthUser from "../../components/helpers/auth";
import ConfirmResetPasswordModal from "../../components/Common/ConfirmResetPasswordModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SponsorsList = (props) => {
  const t = props.t;
  const masterItemName = t("Sponsor");
  const masterItemNamePlural = t("Sponsors");
  const currentUser = getAuthUser();

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  //const [countries, setCountries] = useState([]);
  const [sections, setSections] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
    order: "ASC",
    orderField: "section.name",
  });
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0,
    search: null,
  });

  const fetchData = async () => {
    setLoading(true);
    const response = await getSponsors({ params: tableOpts });
    setItems(response.data);
    setTableMeta({ ...tableMeta, ...response.meta });
    setLoading(false);
  };

  const fetchSections = async () => {
    const response = await getSectionsSelect();
    setSections(
      response.map((e) => {
        return { value: e.id, label: e.name };
      })
    );

    validation.setFieldValue("sectionId", currentUser.sectionId || '');
  };

  useEffect(() => {
    fetchSections();
  }, [0]);

  useEffect(() => {
    fetchData();
  }, [tableOpts]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      //countryId: (item && item.country.id) ? {value: item.country.id, label: item.country.code + ' | ' + item.country.name} : "",
      sectionId: (item && item.sectionId) || null,
      contact_fname: (item && item.contact_fname) || "",
      contact_lname: (item && item.contact_lname) || "",

      first_name: (item && item.masterUser?.first_name) || "",
      last_name: (item && item.masterUser?.last_name) || "",
      email: (item && item.masterUser?.email) || "",
      active: (item && item.masterUser?.active) || true,
    },
    validationSchema: Yup.object({
      //countryId: Yup.object().required(t("required")),
      sectionId: Yup.string().nullable().required(t("required")),
      first_name: Yup.string().nullable(),
      last_name: Yup.string().required(t("required")),
      contact_fname: Yup.string().nullable(),
      contact_lname: Yup.string().nullable(),
      email: Yup.string().required(t("required")),
      active: Yup.bool(),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values,
          masterUser: {
            id: item.masterUser.id,
            ...values
          }
        };
        console.log(updateItem)
        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true);
        const itemResponse = await updateSponsor(item.id, updateItem);
        fetchData();
        setLoadingBtn(false);

        validation.resetForm();
      } else {
        const newItem = {
          ...values,
          masterUser: {
            ...values,
          }
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true);
        const itemResponse = await addSponsor(newItem);
        fetchData();
        setLoadingBtn(false);
        validation.resetForm();
      }
      toggle();
    },
  });

  const history = useHistory()
  const handleItemDetailClick = row => {
    history.push("/managment/sponsors/detail/" + row.id)
  }

  function buttonActions(cell, row, rIdx) {
    return (
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDetailClick(row); } }
          title={t("Details")}>
            <i className="fas fa-eye"></i>
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => {
            handleItemEditClick(row);
          }}
          title={t("Edit")}
        >
          <i className="fas fa-edit"></i>
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => {
            handleItemDeleteClick(row);
          }}
          title={t("Delete")}
        >
          <i className="fas fa-times"></i>
        </Button>
        <Button
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => {
            handleResetPasswordClick(row);
          }}
          title={t("Reset Password")}
        >
          <i className="fas fa-sync"></i>
        </Button>
      </span>
    );
  }

  const columns = [
    {
      dataField: "section.name",
      text: t("Section"),
      sort: true,
    },
    {
      dataField: "masterUser.first_name",
      text: t("First name"),
      sort: true,
    },
    {
      dataField: "masterUser.last_name",
      text: t("Last name") + ' / ' + t("Bussines Name"),
      sort: true,
    },
    {
      dataField: "masterUser.email",
      text: t("Email"),
      sort: true,
    },
    {
      dataField: "user.active",
      text: t("Active"),
      sort: true,
      formatter: (a, row) => {
        return row.masterUser?.active ? t("Yes") : t("No");
      },
    },
    {
      dataField: "id",
      text: t("Actions"),
      formatter: buttonActions,
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteSponsor(item.id);
    fetchData();
    setDeleteModal(false);
  };

  const handleResetPass = async () => {
    const response = await putUserResetPassword(item.masterUser.id);
    setConfirmModal(false);
  };

  const handleItemCreateClick = () => {
    setItem(null);
    setIsEdit(false);
    validation.resetForm();
    toggle();
  };

  const handleItemDeleteClick = (row) => {
    setItem(row);
    setDeleteModal(true);
  };

  const handleItemEditClick = (row) => {
    setItem(row);
    setIsEdit(true);
    validation.resetForm();
    toggle();
  };

  const handleResetPasswordClick = (row) => {
    setItem(row);
    setConfirmModal(true);
  };

  console.log("tableOpts", tableOpts);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ConfirmResetPasswordModal
        show={confirmModal}
        onConfirmClick={handleResetPass}
        onCloseClick={() => setConfirmModal(false)}
        text={t("Do you want reset password?")}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={masterItemNamePlural}
            breadcrumbItem={masterItemName + " " + t("List")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <TableContainer2
                        columns={columns}
                        data={items}
                        handleNewItemClick={() => {
                          handleItemCreateClick();
                        }}
                        addBtnText={t("Add") + " " + masterItemName}
                        loading={loading}
                        sortable={true}
                        handleSearchBtnClick={(search) => {
                          setTableOpts({ ...tableOpts, search: search });
                        }}
                        handlePageBtnClick={(page) => {
                          setTableOpts({ ...tableOpts, page: page });
                        }}
                        handleColumnSort={(evt, colName, direction) => {
                          setTableOpts({
                            ...tableOpts,
                            order: direction,
                            orderField: colName,
                          });
                        }}
                        pageOptions={{
                          sizePerPage: tableMeta.take,
                          page: tableMeta.page,
                          totalSize: tableMeta.itemCount,
                          custom: true,
                          hideSizePerPage: true,
                        }}
                      />

                      <Modal isOpen={modal}>
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit
                            ? t("Edit") + " " + masterItemName
                            : t("Add") + " " + masterItemName}
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col xs={12}>
                                <div className="d-none mb-3" style={{wordBreak: 'break-word'}}>
                                {JSON.stringify(validation.values)}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-Label">
                                    {t("Section")}
                                  </Label>
                                  <Select
                                    className={classNames({
                                      "is-invalid": validation.errors.sectionId && validation.touched.sectionId
                                        ? true
                                        : false,
                                    })}
                                    name="sectionId"
                                    options={sections}
                                    value={validation.values.sectionId ? sections.filter((e) => e.value == validation.values.sectionId)[0]:null}
                                    onChange={(option, actionMeta) => {
                                      validation.setFieldValue(
                                        "sectionId",
                                        option.value,
                                        true
                                      );
                                    }}
                                    onBlur={() => {
                                      console.log("blur");
                                      validation.setFieldTouched(
                                        "sectionId",
                                        true,
                                        true
                                      );
                                    }}
                                    placeholder={t("Select") + "..."}
                                  />
                                  <FormFeedback type="invalid">
                                    {validation.errors.sectionId}
                                  </FormFeedback>
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">
                                    {t("First name")}
                                  </Label>
                                  <Input
                                    name="first_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.first_name}
                                    invalid={
                                      validation.touched.first_name &&
                                      validation.errors.first_name
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.first_name &&
                                  validation.errors.first_name ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.first_name}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    {t("Last name")} / {t("Bussines Name")}
                                  </Label>
                                  <Input
                                    name="last_name"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.last_name || ""}
                                    invalid={
                                      validation.touched.last_name &&
                                      validation.errors.last_name
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.last_name &&
                                  validation.errors.last_name ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.last_name}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">
                                    {t("Contact Person")} - {t("First name")}
                                  </Label>
                                  <Input
                                    name="contact_fname"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contact_fname || ""}
                                    invalid={
                                      validation.touched.contact_fname &&
                                      validation.errors.contact_fname
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.contact_fname &&
                                  validation.errors.contact_fname ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.contact_fname}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <Label className="form-label">
                                  {t("Contact Person")} - {t("Last name")}
                                  </Label>
                                  <Input
                                    name="contact_lname"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.contact_lname || ""}
                                    invalid={
                                      validation.touched.contact_lname &&
                                      validation.errors.contact_lname
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.contact_lname &&
                                  validation.errors.contact_lname ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.contact_lname}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3">
                                  <Label className="form-label">
                                    {t("Email")}
                                  </Label>
                                  <Input
                                    name="email"
                                    type="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email &&
                                      validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.email &&
                                  validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.email}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                                <div className="pt-2 mb-3">
                                    <Label className="form-label">{t("Active")}</Label> <Input
                                        name="active"
                                        label="active"
                                        type="checkbox"
                                        onClick={(evt) => { console.log(!evt.target.checked); validation.setFieldValue('active', !evt.target.checked , true)}}
                                        checked={validation.values.active}
                                        invalid={
                                          validation.touched.active &&
                                            validation.errors.active
                                            ? true
                                            : false
                                        }
                                      />
                                </div>
                                
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Alert color="info">
                                  {t(
                                    'After pressing the "save" Button an email will be sent with the credentials. Please check the spam folder too.'
                                  )}
                                </Alert>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-md save-user"
                                  >
                                    <i className="fas fa-save"></i> {t("Save")}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SponsorsList);
