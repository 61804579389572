const UserRole = {
  SYS_ADMIN: "SYS_ADMIN",
  MAIN_PROVIDER: "MAIN_PROVIDER",
  MASTER_ADMIN: "MASTER_ADMIN",
  SUB_ADMIN: "SUB_ADMIN",
  SPONSOR: "SPONSOR",
  INCIDENT_RESPONDER : "INCIDENT_RESPONDER",
  REGISTERED: "REGISTERED",
  TYPE1 : "TYPE1",
  TYPE2 : "TYPE2",
  TYPE3 : "TYPE3",
  ANONYMOUS: "ANONYMOUS"
}

export default UserRole