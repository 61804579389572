import React from 'react';
import ReactApexChart from "react-apexcharts"
import { withTranslation } from 'react-i18next';

const OverviewCharts = ({t}) => {
    const barchartColors = ["#EB1D36", "#34c38f"];
    const series = [{
        name: t('Customers'),
        data: [230, 124, 88, 120]
    }, {
        name: t('Sponsors'),
        data: [168, 34, 60, 101]
    }];
    const options = {
        chart: {
            type: 'bar',
            height: 400,
            stacked: false,
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '20%',
            },
        },
        colors: barchartColors,
        fill: {
            opacity: 1
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        yaxis: {
            labels: {
                formatter: function (y) {
                    return y.toFixed(0);
                }
            }
        },
        xaxis: {
            categories: ['US', 'México', 'Argentina', 'Perú'],
            labels: {
                rotate: -90
            }
        }
    };
    return (
        <ReactApexChart
            options={options}
            series={series}
            type="bar"
            className="apex-charts"
        />
    );
}

export default withTranslation()(OverviewCharts)