import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { postImage, getIncidentSubtypes, getLocationSelect, getTeamsSelect, addIncidentSubtype, deleteIncidentSubtype, getIncidentTypesSelect, updateIncidentSubtype } from '../../helpers/backend_helper';
import TableContainer2 from '../../components/Common/TableContainer2';

import '../../components/Common/custom.css';
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import classNames from "classnames";
import getAuthUser from "../../components/helpers/auth";
import ReactSelect from "react-select";
import CreatableSelect from 'react-select/creatable';

import Dropzone from "react-dropzone";
import * as _ from "lodash";
import SortItemsModal from "../Entities/Details/components/SortItemsModal";
import ConfirmModalYesNo from "../../components/Common/ConfirmModalYesNo";

const API_URL = process.env.REACT_APP_TELLIT_API_URL
const CALL_SELECTION = [{label:'none', value:'none'}, {label: 'call me', 'value': 'call me'}, {label: 'hear me', value:'hear me'}]

const IncidentSubTypesList = props => {
  const t = props.t
  const masterItemName = t('Incident Subtype')
  const masterItemNamePlural = t('Incident Subtypes')
  const currentUser = getAuthUser()
  const typeOptions = [
    {value: 'STANDAR', label: 'STANDAR'},
    {value: 'INDIVIDUAL', label: 'INDIVIDUAL'}
  ]
  //meta title
  document.title = masterItemName + "List | Tellit"

  const [locations, setLocations] = useState([])
  const [defaultLocations, setDefaultLocations] = useState([])
  const [incidentTypes, setIncidentTypes] = useState([])
  const [teams, setTeams] = useState([])

  const [items, setItems] = useState([])
  const [item, setItem] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false)
  const [loadingBtn, setLoadingBtn] = useState (false)
  const [updateAsset, setUpdateAsset] = useState (false)
  const [modalConfirmUpdate, setModalConfirmUpdate] = useState(false)


  const [steps, setSteps] = useState ([])
  

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getIncidentSubtypes({params: tableOpts}) 
    response.data.forEach( e=> { e.action_steps = JSON.parse(e?.action_steps) })
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  const fetchIncidenTypesSelect = async () => {
    setLoading(true)
    const response = await getIncidentTypesSelect()
    setIncidentTypes(response.map(e => { return {value: e.id, label: e.name}} ))
    setLoading(false)
  }

  setIncidentTypes
  const fetchLocationsSelect = async () => {
    setLoading(true)
    const response = await getLocationSelect()
    setLocations(response.map(e => { return {value: e.id, label: e.name, sort_order: e.sort_order}} ))
    setDefaultLocations(response.filter(e => { return e.type == 'STANDAR' && e.incidentSubtypeRelated == true }).map(e => { return {value: e.id, label: e.name, sort_order: e.sort_order} }))
    setLoading(false)
  }

  const fetchResponseTeamsSelect = async () => {
    setLoading(true)
    const response = await getTeamsSelect() 
    setTeams(response.map(e => { return {value: e.id, label: e.name}} ))
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  useEffect(() => {
    fetchIncidenTypesSelect()
    fetchLocationsSelect()
    fetchResponseTeamsSelect()
  }, [])

  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      incidentType: (item?.incidentType && {value: item?.incidentType.id, label: item?.incidentType.name}) || "",
      name: (item && item.name) || "",
      sort_order: (item?.sort_order) || "",
      time_backup_response: (item?.time_backup_response) || "",
      max_time_to_response: (item?.max_time_to_response) || "",
      responseTeams: (item?.responseTeams.length > 0 && item.responseTeams.map(e => { return {value: e.id, label: e.name}})) || [],
      backResponseTeam: (item?.backResponseTeam && { value: item?.backResponseTeam.id, label: item?.backResponseTeam.name}) || null,
      entity_locations: (item?.entity_locations) || false,
      locations: (item?.entity_locations ? item.locations.map(e => { return {value: e.parentLocationId, label: e.name}}) : (item?.locations.length > 0 && item.locations.map(e => { return {value: e.parentLocationId, label: e.name}})) || defaultLocations),
      assetId: (item?.assetId) || '',
      action: '',
      action_steps: (item?.action_steps) || {},
      supportMedia: (item) ? (item?.supportMedia) : true,
      requiredSupportMedia: (item?.requiredSupportMedia) || false,
      supportText: (item) ? (item?.supportText) : true,
      requiredSupportText: (item?.requiredSupportText) || false,
      askQuestion: (item?.askQuestion) || false,
      askQuestionMandatory: (item?.askQuestionMandatory) || false,
      specificQuestion: (item?.specificQuestion) || "",
      skipScreenLocation: (item?.skipScreenLocation) || false,
      skipScreenMap: (item?.skipScreenMap) || false,
      skipScreenDate: (item?.skipScreenDate) || false,
      skipScreenSummary: (item?.skipScreenSummary) || false,
      callSelection: (item?.callSelection) || '',
      openChat: (item?.openChat) || false,
      geoFollow: (item?.geoFollow) || false,
      includeExternals: (item?.includeExternals) || false,
      includeEndUsers: (item?.includeEndUsers) || false,
    },
    validationSchema: Yup.object({
      incidentType: Yup.object().shape({value: Yup.string().required(t("required"))}),
      name: Yup.string().required(t("required")),
      sort_order: Yup.string().required(t("required")),
      time_backup_response: Yup.number().required(t("required")),
      max_time_to_response: Yup.number().required(t("required")),
      responseTeams: Yup.array().min(1).of(Yup.object().shape({
        value: Yup.string().required(t("required")),
      })),
      backResponseTeam: Yup.object().nullable().shape({value: Yup.string()}),
      entity_locations: Yup.bool(),
      locations: Yup.array().when('entity_locations', {
        is: false,
        then: Yup.array().min(1).of(Yup.object().shape({
          value: Yup.string().required(t("required")),
        })),
        otherwise: Yup.array()
      }),
      assetId: Yup.string().required(t("required")),
      action_steps: Yup.object().shape({'0': Yup.string().required(t("required"))}),
      supportMedia: Yup.bool(),
      requiredSupportMedia: Yup.bool(),
      supportText: Yup.bool(),
      requiredSupportText: Yup.bool(),
      askQuestion: Yup.bool(),
      askQuestionMandatory: Yup.bool(),
      specificQuestion: Yup.string().when('askQuestion', {
        is: true,
        then: Yup.string().required(t("required")).min(4),
        otherwise: Yup.string().nullable()
      }),
      skipScreenLocation: Yup.bool(),
      skipScreenMap: Yup.bool(),
      skipScreenDate: Yup.bool(),
      skipScreenSummary: Yup.bool(),
      callSelection: Yup.string().required(t("required")),
      openChat: Yup.bool(),
      geoFollow: Yup.bool(),
      includeExternals: Yup.bool(),
      includeEndUsers: Yup.bool(),
    }),
    onSubmit: async values => {
      console.log('values',values)
      
      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values,
          incidentTypeId: values.incidentType.value,
          backResponseTeamId: values.backResponseTeam?.value ?? null,
          responseTeams: values.responseTeams.map( e => { return {id: e.value}}),
          locations: values.locations.map( e => { return {id: e.value}}),
          entity_locations: values?.entity_locations ? values?.entity_locations : false,
          action_steps: JSON.stringify(values.action_steps),
          supportMedia: values.supportMedia ? true : false,
          requiredSupportMedia: values.requiredSupportMedia ? true : false,
          supportText: values.supportText ? true : false,
          requiredSupportText: values.requiredSupportText ? true : false,
          askQuestion: values.askQuestion ? true : false,
          askQuestionMandatory: values.askQuestionMandatory ? true : false,
          skipScreenLocation: values.skipScreenLocation ? true : false,
          skipScreenMap: values.skipScreenMap ? true : false,
          skipScreenDate: values.skipScreenDate ? true : false,
          skipScreenSummary: values.skipScreenSummary ? true : false,
          includeExternals: values.includeExternals ? true : false,
          includeEndUsers: values.includeEndUsers ? true : false,
          openChat: values.openChat ? true:false,
          geoFollow: values.geoFollow ? true:false,
        }

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateIncidentSubtype(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm()
        setUpdateAsset(false)
        setselectedFiles([])
      } else {
        const newItem = {
          ...values,
          incidentTypeId: values.incidentType.value,
          backResponseTeamId: values.backResponseTeam?.value ?? null,
          responseTeams: values.responseTeams.map( e => { return {id: e.value}}),
          locations: values.locations.map( e => { return {id: e.value}}),
          entity_locations: values?.entity_locations ? values?.entity_locations : false,
          action_steps: JSON.stringify(values.action_steps),
          supportMedia: values.supportMedia ? true : false,
          requiredSupportMedia: values.requiredSupportMedia ? true : false,
          supportText: values.supportText ? true : false,
          requiredSupportText: values.requiredSupportText ? true : false,
          askQuestion: values.askQuestion ? true : false,
          askQuestionMandatory: values.askQuestionMandatory ? true : false,
          skipScreenLocation: values.skipScreenLocation ? true : false,
          skipScreenMap: values.skipScreenMap ? true : false,
          skipScreenDate: values.skipScreenDate ? true : false,
          skipScreenSummary: values.skipScreenSummary ? true : false,
          includeExternals: values.includeExternals ? true : false,
          includeEndUsers: values.includeEndUsers ? true : false,
          openChat: values.openChat ? true:false,
          geoFollow: values.geoFollow ? true:false,
        }
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addIncidentSubtype(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm()
        setUpdateAsset(false)
        setselectedFiles([])
      }
      toggle()
    },
  })

  const handleUploadFile = async () => {
    if (!isEdit || updateAsset) {
      validation.setTouched({...validation.touched, assetId: true})
      if (selectedFiles.length == 0) {
        validation.setValues({...validation.values, assetId: ''})
        validation.validateForm(validation.values)
        return;
      }
      const file = await submissionFiles()
      validation.values.assetId = file.id
    }
    validation.submitForm()
  }

  const handleItemSortLocations = row => {
    setItem(row)
    setSortItemsModal(true)
  }

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row) } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row) } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemSortLocations(row); } }
          title={t("Sort locations")}>
            <i className="fas fa-list"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'asset.path',
      text: t('Asset'),
      formatter: (cell, row, rIdx) => (<img height="50" src={API_URL + '/' + row.asset.path}></img>),
      sort: false
    }, {
      dataField: 'incidentType.name',
      text: t('Incident Type'),
      sort: true
    }, {
      dataField: 'name',
      text: t('Incident Subtype'),
      sort: true
    }, {
      dataField: 'sort_order',
      text: t('Sort'),
      sort: true
    }, {
      dataField: 'responseTeam.name',
      text: t('Response Team'),
      formatter: (cell, row, rIdx) => { return row.responseTeams.map( e => e.name).join(', ') }
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [sortItemsModal, setSortItemsModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteIncidentSubtype(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleItemCreateClick = () => {
    setUpdateAsset(false)
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const history = useHistory();
  const handleItemDetailClick = row => {
    history.push("/managment/clients/detail/" + row.id);
  };

  const handleItemEditClick = (row) => {
    validation.resetForm()
    setUpdateAsset(false)
    validation.resetForm()
    setItem(row);
    setIsEdit(true);
    toggle();
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    validation.setFieldValue('assetId','1',true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submissionFiles = async () => {
    let result = await postImage(selectedFiles[0])
    return result;
	};

  function addAction() {
    if (validation.values.action.trim().length > 0) {
      let idx = Object.keys(validation.values.action_steps).length
      let newValue = {...validation.values.action_steps }
      newValue[idx] = validation.values.action.trim()
      console.log(newValue)
      validation.setFieldValue('action_steps', newValue)
      validation.setFieldValue('action', '')
    }
  }

  function delAction(key) {
    delete validation.values.action_steps[key]
    const temp = Object.values(validation.values.action_steps)
    validation.setFieldValue('action_steps', {...temp})
  }

  const fieldsMarks = [
   'incident_type',
   'name',
   'sort_order',
   'time_backup_response',
   'max_time_to_response',
   'responseTeams',
   'backResponseTeam',
   'backResponseTeamId',
   'entity_locations',
   'locations',
   'assetId',
   'action_steps',
   'supportMedia',
   'requiredSupportMedia',
   'supportText',
   'requiredSupportText',
   'askQuestion',
   'askQuestionMandatory',
   'specificQuestion',
   'skipScreenLocation',
   'skipScreenMap',
   'skipScreenSummary'
  ]
  const checkAllUpdateFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_up',true] }))
    })
  }
  
  const uncheckAllUpdateFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_up',false] }))
    })
  }

  const checkAllOverwriteFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_over',true] }))
    })
  }

  const uncheckAllOverwriteFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_over',false] }))
    })
  }

  const checkIfModalConfirmUpdate = () => {
    const anyUpdate = fieldsMarks.filter(e => { return validation.values[e+'_up'] === true || validation.values[e+'_over'] === true }).length > 0
    if(anyUpdate) {
      setModalConfirmUpdate(true)
      return
    }
    handleUploadFile()
  }

  console.log('tableOpts', tableOpts)
  console.log('teams', teams)
  console.log('validation.values.action_steps', validation.values.action_steps)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        message={t('delete_catalog_subtype_ask_confirm')}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }

                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal} style={{maxWidth: '60%'}}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row className={{"border-bottom":true, "border-1":true , "pb-2":true, 'd-none': !isEdit}}>
                                <Col xs={isEdit ? 8:12}></Col>
                                <Col xs={2} className="text-center">
                                  {t('Update entities')}<br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={checkAllUpdateFields}
                                  >{t('Select all')}</button><br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={uncheckAllUpdateFields}
                                  >{t('Unselect all')}</button>
                                </Col>
                                <Col xs={2} className="text-center">
                                  {t('Update and overwrite entities')}<br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={checkAllOverwriteFields}
                                  >{t('Select all')}</button><br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={uncheckAllOverwriteFields}
                                  >{t('Unselect all')}</button>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Incident Type')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation.errors.incidentType?true:false})}
                                      name="incident_type"
                                      options={incidentTypes}
                                      value={validation.values.incidentType || null}
                                      onChange={(option, actionMeta) => { validation.setFieldValue('incidentType', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('incidentType', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation.touched.incidentType &&
                                      validation.errors.incidentType ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.incidentType.value}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="incident_type_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('incident_type_up', !evt.target.checked , true)}}
                                      checked={validation.values.incident_type_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="incident_type_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('incident_type_over', !evt.target.checked , true)}}
                                      checked={validation.values.incident_type_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Name")}</Label>
                                    <Input
                                      name="name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name &&
                                          validation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.name &&
                                      validation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="name_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('name_up', !evt.target.checked , true)}}
                                      checked={validation.values.name_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="name_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('name_over', !evt.target.checked , true)}}
                                      checked={validation.values.name_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                    <Label className="form-label">{t("Sort Order")}</Label>
                                    <Input
                                      name="sort_order"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.sort_order || ""}
                                      invalid={
                                        validation.touched.sort_order &&
                                          validation.errors.sort_order
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.sort_order &&
                                      validation.errors.sort_order ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sort_order}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="sort_order_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('sort_order_up', !evt.target.checked , true)}}
                                      checked={validation.values.sort_order_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="sort_order_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('sort_order_over', !evt.target.checked , true)}}
                                      checked={validation.values.sort_order_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">  
                                  <Label className="form-label">{t("Time to pass until backup response team informed")}</Label>
                                    <Input
                                      name="time_backup_response"
                                      type="number"
                                      min={0}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.time_backup_response || ""}
                                      invalid={
                                        validation.touched.time_backup_response &&
                                          validation.errors.time_backup_response
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.time_backup_response &&
                                      validation.errors.time_backup_response ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.time_backup_response}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="time_backup_response_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('time_backup_response_up', !evt.target.checked , true)}}
                                      checked={validation.values.time_backup_response_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="time_backup_response_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('time_backup_response_over', !evt.target.checked , true)}}
                                      checked={validation.values.time_backup_response_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">  
                                  <Label className="form-label">{t("Max time the incident is open before to send notification")}</Label>
                                    <Input
                                      name="max_time_to_response"
                                      type="number"
                                      min={0}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.max_time_to_response || ""}
                                      invalid={
                                        validation.touched.max_time_to_response &&
                                          validation.errors.max_time_to_response
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.max_time_to_response &&
                                      validation.errors.max_time_to_response ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.max_time_to_response}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="max_time_to_response_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('max_time_to_response_up', !evt.target.checked , true)}}
                                      checked={validation.values.max_time_to_response_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="max_time_to_response_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('max_time_to_response_over', !evt.target.checked , true)}}
                                      checked={validation.values.max_time_to_response_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Response Teams')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation.errors.responseTeams?true:false})}
                                      name="responseTeams"
                                      options={teams}
                                      isMulti
                                      value={validation.values.responseTeams || null}
                                      onChange={(option, actionMeta) => { validation.setFieldValue('responseTeams', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('responseTeams', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation.touched.responseTeams &&
                                      validation.errors.responseTeams ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.responseTeams}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="responseTeams_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('responseTeams_up', !evt.target.checked , true)}}
                                      checked={validation.values.responseTeams_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="responseTeams_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('responseTeams_over', !evt.target.checked , true)}}
                                      checked={validation.values.responseTeams_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Backup Response Team')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation.errors.backResponseTeam?true:false})}
                                      name="backResponseTeam"
                                      options={teams}
                                      isClearable={true}
                                      value={validation.values.backResponseTeam || null}
                                      onChange={(option, actionMeta) => { validation.setFieldValue('backResponseTeam', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('backResponseTeam', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation.touched.backResponseTeam &&
                                      validation.errors.backResponseTeam ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.backResponseTeam}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="backResponseTeam_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('backResponseTeam_up', !evt.target.checked , true)}}
                                      checked={validation.values.backResponseTeam_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="backResponseTeam_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('backResponseTeam_over', !evt.target.checked , true)}}
                                      checked={validation.values.backResponseTeam_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Include external incident responders")}</Label> <Input
                                        name="includeExternals"
                                        label="includeExternals"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('includeExternals', !validation.values.includeExternals , true)}}
                                        checked={validation.values.includeExternals}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="includeExternals_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('includeExternals_up', !evt.target.checked , true)}}
                                      checked={validation.values.includeExternals_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="includeExternals_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('includeExternals_over', !evt.target.checked , true)}}
                                      checked={validation.values.includeExternals_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Include end user contacts")}</Label> <Input
                                        name="includeEndUsers"
                                        label="includeEndUsers"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('includeEndUsers', !validation.values.includeEndUsers , true)}}
                                        checked={validation.values.includeEndUsers}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="includeEndUsers_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('includeEndUsers_up', !evt.target.checked , true)}}
                                      checked={validation.values.includeEndUsers_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="includeEndUsers_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('includeEndUsers_over', !evt.target.checked , true)}}
                                      checked={validation.values.includeEndUsers_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                        <Label className="form-Label">{t('Locations')}</Label>
                                        <CreatableSelect
                                          className={classNames({'is-invalid': validation.touched.locations && validation.errors.locations?true:false})}
                                          name="groups"
                                          options={locations}
                                          isMulti
                                          formatCreateLabel={(inputValue) => {
                                            return t("Create") + " \"".concat(inputValue, "\"");
                                          }}
                                          value={validation.values.locations || []}
                                          onChange={(option, actionMeta) => { console.log(option), validation.setFieldValue('locations', _.sortBy(option, 'sort_order'), true)}}
                                          onBlur={ () => { console.log('blur'); validation.setFieldTouched('locations', true, true)} }
                                          placeholder={t('Select or type new')+'...'}
                                        />
                                        {validation.touched.locations &&
                                          (Array.isArray(validation.errors.locations) && validation.errors.locations.length > 0) ? (
                                          <FormFeedback type="invalid">
                                            {Array.isArray(validation.errors.locations) ? validation.errors.locations.join(', '):validation.errors.locations}
                                          </FormFeedback>
                                        ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="locations_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('locations_up', !evt.target.checked , true)}}
                                      checked={validation.values.locations_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="locations_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('locations_over', !evt.target.checked , true)}}
                                      checked={validation.values.locations_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Use Entity Related Locations")}</Label> <Input
                                        name="entity_locations"
                                        label="entity_locations"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('entity_locations', !validation.values.entity_locations , true)}}
                                        checked={validation.values.entity_locations}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="entity_locations_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('entity_locations_up', !evt.target.checked , true)}}
                                      checked={validation.values.entity_locations_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="entity_locations_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('entity_locations_over', !evt.target.checked , true)}}
                                      checked={validation.values.entity_locations_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Support Media")}</Label> <Input
                                        name="supportMedia"
                                        label="supportMedia"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('supportMedia', !evt.target.checked , true)}}
                                        checked={validation.values.supportMedia}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="supportMedia_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('supportMedia_up', !evt.target.checked , true)}}
                                      checked={validation.values.supportMedia_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="supportMedia_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('supportMedia_over', !evt.target.checked , true)}}
                                      checked={validation.values.supportMedia_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              { validation.values.supportMedia &&
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Support Media is Required")}</Label> <Input
                                        name="requiredSupportMedia"
                                        label="requiredSupportMedia"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('requiredSupportMedia', !evt.target.checked , true)}}
                                        checked={validation.values.requiredSupportMedia}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="requiredSupportMedia_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('requiredSupportMedia_up', !evt.target.checked , true)}}
                                      checked={validation.values.requiredSupportMedia_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="requiredSupportMedia_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('requiredSupportMedia_over', !evt.target.checked , true)}}
                                      checked={validation.values.requiredSupportMedia_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              }
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Support Comments")}</Label> <Input
                                        name="supportText"
                                        label="supportText"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('supportText', !evt.target.checked , true)}}
                                        checked={validation.values.supportText}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="supportText_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('supportText_up', !evt.target.checked , true)}}
                                      checked={validation.values.supportText_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="supportText_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('supportText_over', !evt.target.checked , true)}}
                                      checked={validation.values.supportText_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              { validation.values.supportText &&
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Support Comments is Required")}</Label> <Input
                                        name="requiredSupportText"
                                        label="requiredSupportText"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('requiredSupportText', !evt.target.checked , true)}}
                                        checked={validation.values.requiredSupportText}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="requiredSupportText_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('requiredSupportText_up', !evt.target.checked , true)}}
                                      checked={validation.values.requiredSupportText_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                   <Input
                                      name="requiredSupportText_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('requiredSupportText_over', !evt.target.checked , true)}}
                                      checked={validation.values.requiredSupportText_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              }
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Ask specific question")}</Label> <Input
                                        name="askQuestion"
                                        label="askQuestion"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('askQuestion', !evt.target.checked , true)}}
                                        checked={validation.values.askQuestion}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    <div className="mt-2">
                                      <Input
                                        name="askQuestion_up"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('askQuestion_up', !evt.target.checked , true)}}
                                        checked={validation.values.askQuestion_up || ""}
                                      />
                                    </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                    <div className="mt-2">
                                      <Input
                                        name="askQuestion_over"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('askQuestion_over', !evt.target.checked , true)}}
                                        checked={validation.values.askQuestion_over || ""}
                                      />
                                    </div>
                                </Col>
                                
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  { validation.values.askQuestion &&
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Answer specific question mandatory")}</Label> <Input
                                        name="askQuestionMandatory"
                                        label="askQuestionMandatory"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('askQuestionMandatory', !evt.target.checked , true)}}
                                        checked={validation.values.askQuestionMandatory}
                                      />
                                  </div>
                                  }
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  { validation.values.askQuestion &&
                                    <div className="mt-2">
                                      <Input
                                        name="askQuestionMandatory_up"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('askQuestionMandatory_up', !evt.target.checked , true)}}
                                        checked={validation.values.askQuestionMandatory_up || ""}
                                      />
                                    </div>
                                  }
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  { validation.values.askQuestion &&
                                    <div className="mt-2">
                                      <Input
                                        name="askQuestionMandatory_over"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('askQuestionMandatory_over', !evt.target.checked , true)}}
                                        checked={validation.values.askQuestionMandatory_over || ""}
                                      />
                                    </div>
                                  }
                                </Col>
                              </Row>
                              
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  { validation.values.askQuestion && 
                                  <div className="mt-2 mb-3">
                                    <Label className="form-label">{t("Specific Question")}</Label>
                                    <Input
                                      name="specificQuestion"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.specificQuestion || ""}
                                      invalid={
                                        validation.touched.specificQuestion &&
                                          validation.errors.specificQuestion
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.specificQuestion &&
                                      validation.errors.specificQuestion ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.specificQuestion}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  }
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  { validation.values.askQuestion && 
                                    <div className="mt-2">
                                      <Input
                                        name="specificQuestion_up"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('specificQuestion_up', !evt.target.checked , true)}}
                                        checked={validation.values.specificQuestion_up || ""}
                                      />
                                    </div>
                                  }
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  { validation.values.askQuestion && 
                                    <div className="mt-2">
                                      <Input
                                        name="specificQuestion_over"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('specificQuestion_over', !evt.target.checked , true)}}
                                        checked={validation.values.specificQuestion_over || ""}
                                      />
                                    </div>
                                  }
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Skip Selection of Location")}</Label> <Input
                                        name="skipScreenLocation"
                                        label="skipScreenLocation"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('skipScreenLocation', !evt.target.checked , true)}}
                                        checked={validation.values.skipScreenLocation}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenLocation_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenLocation_up', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenLocation_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenLocation_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenLocation_over', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenLocation_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Skip map")}</Label> <Input
                                        name="skipScreenMap"
                                        label="skipScreenMap"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('skipScreenMap', !evt.target.checked , true)}}
                                        checked={validation.values.skipScreenMap}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenMap_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenMap_up', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenMap_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenMap_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenMap_over', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenMap_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Skip datetime")}</Label> <Input
                                        name="skipScreenDate"
                                        label="skipScreenDate"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('skipScreenDate', !evt.target.checked , true)}}
                                        checked={validation.values.skipScreenDate}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenDate_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenDate_up', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenDate_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenDate_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenDate_over', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenDate_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Skip summary before sending")}</Label> <Input
                                        name="skipScreenSummary"
                                        label="skipScreenSummary"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('skipScreenSummary', !evt.target.checked , true)}}
                                        checked={validation.values.skipScreenSummary}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenSummary_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenSummary_up', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenSummary_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="skipScreenSummary_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('skipScreenSummary_over', !evt.target.checked , true)}}
                                      checked={validation.values.skipScreenSummary_over || ""}
                                    />
                                  </div>
                                  </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  { isEdit &&
                                  <div className={classNames({"d-none": updateAsset || validation.values.assetId == '' ? true:false, "pt-2": true, "mb-3":true })}>
                                    <Label className="form-label">{t("Current Asset")}</Label><br />
                                    <img height="100" src={API_URL + '/' + item.asset.path}></img><br />
                                    <button
                                      type="button"
                                      onClick={() => { validation.setFieldValue('assetId', ''); setUpdateAsset(true) }}
                                      className="btn btn-primary btn-sm save-user mt-1"
                                    >
                                      <i className="fas fa-refresh"></i> {t("Change")}
                                    </button>
                                  </div>
                                  }
                                  <div className={classNames({"d-none": isEdit && !updateAsset ? true:false, "is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                                      <Label className="form-label">{t("Icon")}</Label><br />
                                      <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onFileDialogOpen={() => {
                                          validation.setFieldTouched('assetId', true, true)
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone" style={{minHeight: '120px'}}>
                                            <div
                                              className="text-center needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-5 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h5>{t('Drop files here or click to upload')}.</h5>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted font-weight-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>                                                    
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                  </div>
                                  {validation.touched.assetId &&
                                        validation.errors.assetId ? (
                                        <FormFeedback type="invalid" className="invalid">
                                          {validation.errors.assetId}
                                        </FormFeedback>
                                      ) : null}
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="assetId_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('assetId_up', !evt.target.checked , true)}}
                                      checked={validation.values.assetId_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="assetId_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('assetId_over', !evt.target.checked , true)}}
                                      checked={validation.values.assetId_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                      <Label className="form-Label">{t('Action Step')}</Label>
                                      <InputGroup>
                                        <Input
                                          className={classNames({'is-invalid': validation.touched.action_steps && 'action_steps' in validation.errors && Object.keys(validation.errors.action_steps).length > 0 ?true:false})}
                                          name="action"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.action || ""}
                                          placeholder={t('Add next action step here...')}
                                        />
                                        <Button type="button" onClick={() => addAction() }>
                                          {t('Add')}
                                        </Button>
                                      </InputGroup>
                                      <br />
                                      <ol 
                                        className={classNames({'is-invalid': validation.touched.action_steps && 'action_steps' in validation.errors && Object.keys(validation.errors.action_steps).length ?true:false})}
                                      >
                                        { Object.keys(validation.values.action_steps).map( (e) => { return (
                                        <li className="p-2" key={e}>
                                          {validation.values.action_steps[e]}
                                          <button 
                                            type="button"
                                            className="btn btn-danger btn-sm float-end"
                                            onClick={() => { delAction(e) }}
                                          >
                                            <i className="fa fa-times"></i>
                                          </button>  
                                        </li>)})
                                        }
                                      </ol>

                                      {validation.touched.action_steps && 'action_steps' in validation.errors && 
                                        Object.keys(validation.errors.action_steps).length > 0 ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.action_steps[0]}
                                        </FormFeedback>
                                      ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="action_steps_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('action_steps_up', !evt.target.checked , true)}}
                                      checked={validation.values.action_steps_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="action_steps_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('action_steps_over', !evt.target.checked , true)}}
                                      checked={validation.values.action_steps_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Call Selection')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation.errors.callSelection?true:false})}
                                      name="callSelection"
                                      options={CALL_SELECTION}
                                      value={CALL_SELECTION.find( e => e.value == validation.values.callSelection) || null}
                                      onChange={(option, actionMeta) => { validation.setFieldValue('callSelection', option.value, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('callSelection', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation.touched.callSelection &&
                                      validation.errors.callSelection ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.callSelection.value}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="incident_type_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('incident_type_up', !evt.target.checked , true)}}
                                      checked={validation.values.incident_type_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="incident_type_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('incident_type_over', !evt.target.checked , true)}}
                                      checked={validation.values.incident_type_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Open chat")}</Label> <Input
                                        name="openChat"
                                        label="openChat"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('openChat', !evt.target.checked , true)}}
                                        checked={validation.values.openChat}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="openChat_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('openChat_up', !evt.target.checked , true)}}
                                      checked={validation.values.openChat_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="openChat_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('openChat_over', !evt.target.checked , true)}}
                                      checked={validation.values.openChat_over || ""}
                                    />
                                  </div>
                                  </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-1">
                                    <Label className="form-label">{t("Geo follow")}</Label> <Input
                                        name="geoFollow"
                                        label="geoFollow"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('geoFollow', !evt.target.checked , true)}}
                                        checked={validation.values.geoFollow}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="geoFollow_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('geoFollow_up', !evt.target.checked , true)}}
                                      checked={validation.values.geoFollow_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-2">
                                    <Input
                                      name="geoFollow_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('geoFollow_over', !evt.target.checked , true)}}
                                      checked={validation.values.geoFollow_over || ""}
                                    />
                                  </div>
                                  </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center mt-2">
                                    <button
                                      type="button"
                                      onClick={checkIfModalConfirmUpdate}
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>

                        <SortItemsModal
                          show={sortItemsModal}
                          onSaveHandler={(items) => { setItem({...item, locations: items}); fetchData() }}
                          onCloseClick={() => setSortItemsModal(false)}
                          subtype={item}
                        />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ConfirmModalYesNo
        setShow={setModalConfirmUpdate}
        show={modalConfirmUpdate}
        text={t('update_cascade_incident_subtype_confirm')}
        onConfirmClick={() => {handleUploadFile(); setModalConfirmUpdate(false) }}
        onCloseClick={() => { uncheckAllUpdateFields(); uncheckAllUpdateFields(); handleUploadFile(); setModalConfirmUpdate(false) } }
      />

    </React.Fragment>
  )
}

export default withTranslation()(IncidentSubTypesList)
