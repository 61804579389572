import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";

//redux
import { withTranslation } from "react-i18next";
import {
  deleteUser,
  putUserResetPassword,
  getSponsorsContracts,
  getClientsSelect,
} from "../../../helpers/backend_helper";
import TableContainer2 from "../../../components/Common/TableContainer2";

import "../../../components/Common/custom.css";
import { useHistory } from "react-router-dom";
import SponsorContractForm from "./SponsorContractForm";
import dayjs from "dayjs";
import SponsorContractView from "./SponsorContractView";

const SponsorContracts = (props) => {
  const t = props.t;
  const readonly = props.readonly ?? false;
  const sponsor = props.sponsor;
  const masterItemName = t("Contract");
  const masterItemNamePlural = t("Contracts");

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState({});
  const [clients, setClients] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
    sponsorId: sponsor.id,
  });
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0,
    search: null,
  });

  const fetchDataClients = async () => {
    const response = await getClientsSelect();
    setClients(response);
  };

  useEffect(() => {
    fetchDataClients();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await getSponsorsContracts({ params: tableOpts });
    setItems(response.data);
    setTableMeta({ ...tableMeta, ...response.meta });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [tableOpts]);

  function buttonActions(cell, row, rIdx) {
    return (
      <span>
        <Button
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => {
            handleItemViewClick(row);
          }}
          title={t("Detail")}
        >
          <i className="fas fa-eye"></i>
        </Button>
        {!readonly && (
          <Button
            type="button"
            className="btn btn-sm btn-primary ms-1"
            onClick={(evt) => {
              handleItemEditClick(row);
            }}
            title={t("Edit")}
          >
            <i className="fas fa-edit"></i>
          </Button>
        )}
        {!readonly && (
          <Button
            type="button"
            className="btn btn-sm btn-primary ms-1"
            onClick={(evt) => {
              handleItemDeleteClick(row);
            }}
            title={t("Delete")}
          >
            <i className="fas fa-times"></i>
          </Button>
        )}
      </span>
    );
  }

  const defaultNewItem = {
    id: null,
    name: null,
    advertising_type: null,
    start_day: null,
    end_day: null,
    active: true,
    entities: [],
    clientId: null,
    sponsorId: sponsor?.id,
  };

  const columns = [
    {
      dataField: "name",
      text: t("Name"),
      sort: true,
    },
    {
      dataField: "client",
      text: t("Client"),
      formatter: (e, row) => {
        return row.client?.name;
      },
      sort: true,
    },
    {
      dataField: "advertising_type",
      text: t("Advertising type"),
      formatter: (e, row) => {
        return t(row.advertising_type);
      },
      sort: true,
    },
    {
      dataField: "period",
      text: t("Period"),
      formatter: (e, row) => {
        return (
          (row.start_day ? dayjs(row.start_day).format("DD/MM/YYYY") : "") +
          " - " +
          (row.end_day ? dayjs(row.end_day).format("DD/MM/YYYY") : "")
        );
      },
      sort: true,
    },
    {
      dataField: "id",
      text: t("Actions"),
      formatter: buttonActions,
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const handleItemCreateClick = () => {
    setItem(defaultNewItem);
    setIsEdit(false);
    toggle();
  };

  const handleItemViewClick = (row) => {
    setItem({
      ...row,
      start_day: row.start_day ? dayjs(row.start_day).toDate() : null,
      end_day: row.end_day ? dayjs(row.end_day).toDate() : null,
      entities: row.entities.map((e) => {
        return e.id;
      }),
    });
    setViewModal(true);
  };

  const handleItemEditClick = (row) => {
    setItem({
      ...row,
      start_day: row.start_day ? dayjs(row.start_day).toDate() : null,
      end_day: row.end_day ? dayjs(row.end_day).toDate() : null,
      entities: row.entities.map((e) => {
        return e.id;
      }),
    });
    setIsEdit(true);
    toggle();
  };

  const handleItemDeleteClick = (row) => {
    setItem(row);
    setDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    const response = await deleteUser(item.id);
    fetchData();
    setDeleteModal(false);
  };

  console.log("tableOpts", tableOpts);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardBody>
          <Row>
            <Col xl="12">
              <TableContainer2
                columns={columns}
                data={items}
                handleNewItemClick={() => {
                  handleItemCreateClick();
                }}
                addBtnText={!readonly ? t("Add") + " " + masterItemName : null}
                loading={loading}
                handleSearchBtnClick={(search) => {
                  setTableOpts({ ...tableOpts, search: search });
                }}
                handlePageBtnClick={(page) => {
                  setTableOpts({ ...tableOpts, page: page });
                }}
                pageOptions={{
                  sizePerPage: tableMeta.take,
                  page: tableMeta.page,
                  totalSize: tableMeta.itemCount,
                  custom: true,
                  hideSizePerPage: true,
                }}
              />

              {item && (
                <Modal isOpen={modal}>
                  <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit
                      ? t("Edit") + " " + masterItemName
                      : t("Add") + " " + masterItemName}
                  </ModalHeader>
                  <ModalBody>
                    <SponsorContractForm
                      item={item}
                      sponsor={sponsor}
                      clients={clients}
                      onComplete={(e) => {
                        fetchData();
                        toggle();
                      }}
                      isEdit={isEdit}
                    ></SponsorContractForm>
                  </ModalBody>
                </Modal>
              )}
              {item && (
                <Modal isOpen={viewModal}>
                  <ModalHeader toggle={()=>{setViewModal(!viewModal)}} tag="h4">
                    {t("Detail") + " " + masterItemName}
                  </ModalHeader>
                  <ModalBody>
                    <SponsorContractView
                      item={item}
                      sponsor={sponsor}
                      clients={clients}
                    ></SponsorContractView>
                  </ModalBody>
                </Modal>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withTranslation()(SponsorContracts);
