import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { addEntity, addIncident, deleteEntity, getCountriesPhoneSelect, getEntitiesSelect, getEntityIncidentTypesWithSubtypesSelect, getIncidents, getIncidentsActive, getIncidentTypesSelect, getResponseTeamUsersSelect, postImage, putUserResetPassword, updateEntity, updateIncident } from '../../helpers/backend_helper';
import TableContainer2 from '../../components/Common/TableContainer2';

import '../../components/Common/custom.css';
import classNames from "classnames";
import ConfirmModal from "../../components/Common/ConfirmModal";
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import getAuthUser from "../../components/helpers/auth";
import Dropzone from "react-dropzone";
import TableContainer3 from "../../components/Common/TableContainer3";
import UserRole from '../../common/UserRole';
import * as moment from 'moment-timezone';
const API_URL = process.env.REACT_APP_TELLIT_API_URL

const CurrentIncidentsList = props => {
  const currentUser = getAuthUser()
  const t = props.t;
  const masterItemName = t('Working on');
  const masterItemNamePlural = t('Working on');

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [users, setUsers] = useState([]);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [modal, setModal] = useState(false)
  const [modalAssing, setModalAssing] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);
  let timeTemp = new Date().toLocaleTimeString()
  const [iniDate, setIniDate] = useState (moment().format('YYYY-MM-DD'));
  const [iniTime, setIniTime] = useState (timeTemp.length == 7 ? '0'+timeTemp: timeTemp);
  const [entities, setEntities] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);

  const [types, setTypes] = useState([]);
  const [locations, setLocations] = useState([]);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getIncidentsActive({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      entity: (item?.entity && {value: item.entity.value , label: item.entity.name}) || '',
      incidentSubtype: (item?.incidentSubtype?.id && {value: item.incidentSubtype.id , label: item.incidentSubtype.name}) || '',
      location: (item?.location?.id && {value: item.location.id , label: item.location.name}) || '',
      latitude: (item && item.locationGps?.split(',').length > 0 && item.locationGps.split(',')[0]) || "",
      longitude: (item && item.locationGps?.split(',').length > 0 && item.locationGps.split(',')[1]) || "",
      comments: (item && item.comments) || "",
      evidences: (item?.evidences) || [],
      date: (item?.evidences) || iniDate,
      time: (item?.evidences) || iniTime,
    },
    validationSchema: Yup.object({
      latitude: Yup.string().required(t("required")),
      longitude: Yup.string().required(t("required")),
      entity: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      incidentSubtype: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      location: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      latitude: Yup.number(),
      longitude: Yup.number(),
      comments: Yup.string(),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          ...values,
          id: item.id,
          groups: values.groups.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          active: values.active ? values.active : false,
          adBlocking: values.adblocking ? values.adblocking : false,
          supportEmergency: values.supportEmergency ? values.supportEmergency : false,
          phone: {
            ...values.phone,
            area_code: values.phone.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone.country_code.value.toString(),
            number: values.phone.number.toString()
          }
        };

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateEntity(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm();
      } else {
        console.log(values.date + ' ' + values.time)
        
        const newItem = {
          ...values,
          locationGps: values.latitude + ',' + values.longitude,
          entityId: values.entity.value,
          subTypeId: values.incidentSubtype.value,
          locationId: values.location.value,
          evidences: values.evidences,
          date_event: moment(values.date + ' ' + values.time).toISOString(),
          answers: '{}',
          entityName: values.entity.label,
          type: values.incidentSubtype.label.split('|')[0].trim(),
          subtype: values.incidentSubtype.label.split('|')[1].trim(),
          location: values.location.label
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addIncident(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm();
      }
      toggle();
    },
  })

  const validation2 = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user: {},
    },
    validationSchema: Yup.object({
      user: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
    }),
    onSubmit: async values => {
      // save new user
      //dispatch(onAddNewUser(newUser));
      console.log('submit validation2')
      setLoadingBtn(true)
      await updateIncident(item.id, {userOwnerId: values.user.value})
      fetchData()
      setLoadingBtn(false)
      validation2.resetForm();
      
      toggleModalAssign();
    },
  });

  const fetchEntities = async () => {
    const response = await getEntitiesSelect()
    setEntities(response.map(e => {return {value: e.id, label: e.name };}))
  }

  const fetchTypesWithSubtypes = async (entityId) => {
    const response = await getEntityIncidentTypesWithSubtypesSelect(entityId)
    setIncidentTypes(response)

    const incidentSubtypes = []
    response.forEach(e => {
      e.incidentSubtypes.forEach( f => {
        incidentSubtypes.push({value: f.id, label: e.name + ' | ' + f.name })
      })
    });
    setTypes(incidentSubtypes)
  }

  const fetchUserTeams = async (subTypeId) => {
    const response = await getResponseTeamUsersSelect({params: {subTypeId: subTypeId}})
    setUsers(response.map(e => {return {value: e.id, label: e.first_name + ' ' + e.last_name  };}))
  }

  useEffect(() => {
    fetchEntities()
    fetchUserTeams()
  }, [])

  useEffect(() => {
    if(!validation?.values?.entity?.value) return;
    fetchTypesWithSubtypes(validation?.values?.entity?.value)
  }, [validation?.values?.entity])

  useEffect(() => {
    if (!incidentTypes || !validation?.values?.incidentSubtype) return
    
    incidentTypes.forEach(e=>{
      e.incidentSubtypes.forEach(f => {
        if (f.id == validation?.values?.incidentSubtype.value) {
          setLocations(f.locations.map(l => { return {value: l.id, label: l.name }; }))
        }
      })
    })
  }, [validation?.values?.incidentSubtype])

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log('Update data');
      await fetchData()
    }, 30000);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemViewClick(row); } }
          title={t("View Detail")}>
            <i className="fas fa-eye"></i>
        </Button>
        { currentUser.role == UserRole.INCIDENT_RESPONDER && row.userOwnerId == null &&
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemTakeClick(row); } }
          title={t("Take incident")}>
            <i className="fas fa-chalkboard-teacher"></i>
        </Button>
        }
        { [UserRole.MASTER_ADMIN, UserRole.SUB_ADMIN].includes(currentUser.role ) && 
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleAssingOwner(row); } }
          title={t("Assing Owner")}>
            <i className="fas fa-user-check"></i>
        </Button>
        }
      </span>
    )
  }

  const columns = [
    {
      dataField: 'id',
      text: t('Id'),
      formatter: (cell, row, rIdx) => { return row.id.split('-')[4] },
    }, {
      dataField: 'subtype',
      text: t('Subtype'),
      sort: true,
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'created_at',
      text: t('System Creation Time'),
      sort: true,
      formatter: (cell, row, rIdx) => { return moment(row.created_at).local().format('YYYY-MM-DD HH:mm') },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'date_event',
      text: t('User Specified Time'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.date_event ? moment(row.date_event).local().format('YYYY-MM-DD HH:mm'):'' },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'entityName',
      text: t('Entity'),
      sort: true,
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'status',
      text: t('Status'),
      sort: true,
      formatter: (cell, row, rIdx) => { return (<b>{t(row.status)}</b>); },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'emergency',
      text: t('External Responders'),
      sort: true,
      formatter: (cell, row, rIdx) => { return (<Badge className="rounded-pill bg-danger">{ props.t("No Available") }</Badge>) },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'userOwner.first_name',
      text: t('Owner'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.userOwner ? ((currentUser.id) == (row.userOwner?.id) ? 'myself':(row.userOwner?.first_name + " " + row.userOwner?.last_name)):'' },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'emergency',
      text: t('Call me'),
      sort: false,
      formatter: (cell, row, rIdx) => { return row.emergency ? t('Yes') : t('No') ; },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteEntity(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleItemCreateClick = () => {
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const handleTakeIncident = async () => {
    //setItem(row);
    setLoadingBtn(true)
    const itemResponse = await updateIncident(item.id, {userOwnerId: currentUser.id})
    fetchData()
    setLoadingBtn(false)
    setConfirmModal(false);
  };

  const history = useHistory();
  const handleItemViewClick = (row) => {
    history.push("/incidents/work-on/" + row.id);
  };

  const handleItemTakeClick = (row) => {

    setItem(row);
    setConfirmModal(true);
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  async function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    console.log('handleAcceptedFiles', files)
    const file = await postImage(files[0]);
    validation.setFieldValue('evidences', [...validation.values.evidences, file])
    validation.setFieldTouched('evidences', true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAssingOwner(item) {
    console.log(item)
    fetchUserTeams(item.subTypeId);
    setItem(item)
    toggleModalAssign()
  }

  function toggleModalAssign() {
    setModalAssing(!modalAssing)
  }
  
  console.log('tableOpts', tableOpts)
  console.log('locations', locations)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ConfirmModal
        show={confirmModal}
        onConfirmClick={handleTakeIncident}
        onCloseClick={() => setConfirmModal(false)}
        text={t('Do you want take de incident?')}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">
                        <TableContainer3
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + t('Incident')}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleUpdateClick={ () => { fetchData() }}
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }
                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal} style={{maxWidth: '80%', width: '100%'}}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <Row>
                                    <Col xs={6}>
                                    <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> {t('Incident')}</h5>
                                      <div className="mb-3">
                                        <Label className="form-Label">{t('Entidad')}</Label>
                                        <Select
                                          className={classNames({'is-invalid': validation.errors?.entity?.value ? true:false})}
                                          name="phone.country_code.value"
                                          options={entities}
                                          value={validation.values.entity || null}
                                          onChange={(option, actionMeta) => { validation.setFieldValue('entity', option, true)}}
                                          onBlur={ () => { validation.setFieldTouched('entity', true, true)} }
                                          placeholder={t('Select')+'...'}
                                        />
                                        {validation.touched.entity?.value &&
                                          validation.errors.entity?.value ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.entity?.value}
                                          </FormFeedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                        <Label className="form-Label">{t('Incident Subtype')}</Label>
                                        <Select
                                          className={classNames({'is-invalid': validation.errors.incidentSubtype ? true:false})}
                                          name="incidentSubtype"
                                          options={types}
                                          value={validation.values.incidentSubtype || null}
                                          onChange={(option, actionMeta) => { validation.setFieldValue('incidentSubtype', option, true)}}
                                          onBlur={ () => { validation.setFieldTouched('incidentSubtype', true, true)} }
                                          placeholder={t('Select')+'...'}
                                        />
                                        {validation.touched.incidentSubtype?.value &&
                                          validation.errors.incidentSubtype?.value ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.incidentSubtype?.value}
                                          </FormFeedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                        <Label className="form-Label">{t('Location')}</Label>
                                        <Select
                                          className={classNames({'is-invalid': validation.errors.location ? true:false})}
                                          name="location"
                                          options={locations}
                                          value={validation.values.location || null}
                                          onChange={(option, actionMeta) => { validation.setFieldValue('location', option, true)}}
                                          onBlur={ () => { validation.setFieldTouched('location', true, true)} }
                                          placeholder={t('Select')+'...'}
                                        />
                                        {validation.touched.location?.value &&
                                          validation.errors.location?.value ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.location?.value}
                                          </FormFeedback>
                                        ) : null}
                                      </div>

                                      <Row>
                                        <Col md={6}>
                                          <div className="mb-3">
                                            <Label className="form-label">{t("Date")}</Label>
                                            <Input
                                              name="date"
                                              type="date"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.date || ""}
                                              invalid={
                                                validation.touched.date &&
                                                  validation.errors.date
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.date &&
                                              validation.errors.date ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.date}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                        <Col md={6}>
                                          <div className="mb-3">
                                            <Label className="form-label">{t("Time")}</Label>
                                            <Input
                                              name="time"
                                              type="time"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.time || ""}
                                              invalid={
                                                validation.touched.time &&
                                                  validation.errors.time
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.time &&
                                              validation.errors.time ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.time}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={6}>
                                          <div className="mb-3">
                                            <Label className="form-label">{t("Location")} {t("Latitude")} GPS</Label>
                                              <Input
                                                name="latitude"
                                                type="number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.latitude || ""}
                                                invalid={
                                                  validation.touched.latitude &&
                                                    validation.errors.latitude
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.touched.latitude &&
                                                validation.errors.latitude ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.latitude}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                          <div className="mb-3">
                                            <Label className="form-label">{t("Location")} {t("Longitude")} GPS</Label>
                                              <Input
                                                name="longitude"
                                                type="number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.longitude || ""}
                                                invalid={
                                                  validation.touched.longitude &&
                                                    validation.errors.longitude
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validation.touched.longitude &&
                                                validation.errors.longitude ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.longitude}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                        </Col>
                                      </Row>
                                      
                                      <div className="mb-3">
                                        <Label className="form-label">{t("Comments")}</Label>
                                        <Input
                                          name="comments"
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.comments || ""}
                                          invalid={
                                            validation.touched.comments &&
                                              validation.errors.comments
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.comments &&
                                          validation.errors.comments ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.comments}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col xs={6}>
                                      <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> {t('Evidences')}</h5>
                                      <div className={classNames({"d-none": isEdit && !updateAsset ? true:false, "is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                                        <Label className="form-label">{t("Files")}</Label><br />
                                        <Dropzone
                                          onDrop={(acceptedFiles) => {
                                            handleAcceptedFiles(acceptedFiles);
                                          }}
                                          onFileDialogOpen={() => {
                                            validation.setFieldTouched('assetId', true, true)
                                          }}
                                        >
                                          {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone" style={{minHeight: '120px'}}>
                                              <div
                                                className="text-center needsclick mt-2"
                                                {...getRootProps()}
                                              >
                                                <input {...getInputProps()} />
                                                <div className="mb-1">
                                                  <i className="display-5 text-muted bx bx-cloud-upload" />
                                                </div>
                                                <h5>{ t('Drop files here or click to upload') }</h5>
                                              </div>
                                            </div>
                                          )}
                                        </Dropzone>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                          {validation.values.evidences.map((f, i) => {
                                            return (
                                              <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                              >
                                                <div className="p-2">
                                                  <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={API_URL + '/' + f.path}
                                                      />
                                                    </Col>
                                                    <Col>
                                                      {f.name}
                                                      <button 
                                                        type="button"
                                                        className="btn btn-danger btn-sm float-end"
                                                        onClick={() => { validation.setFieldValue(validation.values.evidences.splice(i, 1)) }}
                                                      >
                                                        <i className="fa fa-times"></i>
                                                      </button>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </Card>
                                            );
                                          })}
                                        </div>
                                    </div>
                                    {validation.touched.assetId &&
                                        validation.errors.assetId ? (
                                        <FormFeedback type="invalid" className="invalid">
                                          {validation.errors.assetId}
                                        </FormFeedback>
                                      ) : null}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                        <Modal isOpen={modalAssing} style={{maxWidth: '80%', width: '100%'}}>
                          <ModalHeader toggle={toggleModalAssign} tag="h4">
                            {t("Assign Owner")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation2.handleSubmit();
                                return false;
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('User')}</Label>
                                    <Select
                                      className={classNames({'is-invalid': validation2.errors?.user?.value ? true:false})}
                                      name="user"
                                      options={users}
                                      value={validation2.values.user || null}
                                      onChange={(option, actionMeta) => { validation2.setFieldValue('user', option, true)}}
                                      onBlur={ () => { validation2.setFieldTouched('user', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation2.touched.user?.value &&
                                      validation2.errors.user?.value ? (
                                      <FormFeedback type="invalid">
                                        {validation2.errors.user?.value}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Assign")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CurrentIncidentsList)
