import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { addIncidentType, deleteIncidentType, getIncidentTypes, postImage, updateIncidentType } from '../../helpers/backend_helper';
import TableContainer2 from '../../components/Common/TableContainer2';

import '../../components/Common/custom.css';
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import classNames from "classnames";
import getAuthUser from "../../components/helpers/auth";
import ReactSelect from "react-select";
import Dropzone from "react-dropzone";
import { result } from "lodash";
import ConfirmModal from "../../components/Common/ConfirmModal";
import ConfirmModalYesNo from "../../components/Common/ConfirmModalYesNo";

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const IncidentTypesList = props => {
  const t = props.t
  const masterItemName = t('Incident Type')
  const masterItemNamePlural = t('IncidentTypes')
  const currentUser = getAuthUser()
  const typeOptions = [
    {value: 'STANDAR', label: t('STANDAR')},
    {value: 'INDIVIDUAL', label: t('INDIVIDUAL')}
  ]
  //meta title
  document.title = masterItemName + "List | Tellit"

  const [items, setItems] = useState([])
  const [item, setItem] = useState()
  const [teams, setTeams] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false)
  const [loadingBtn, setLoadingBtn] = useState (false)
  const [updateAsset, setUpdateAsset] = useState (false)
  const [modalConfirmUpdate, setModalConfirmUpdate] = useState(false)

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getIncidentTypes({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (item && item.name) || "",
      sort_order: (item?.sort_order) || "",
      type: (item?.type) ? {value: item?.type, label: item?.type} : {value: '', label:''},
      emergency: (item?.emergency) || false,
      assetId: (item?.assetId) || '',
      autoSelectOnReportIncident: (item?.autoSelectOnReportIncident) || false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("required")).min(1),
      sort_order: Yup.string().required(t("required")),
      type: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      emergency: Yup.bool(),
      autoSelectOnReportIncident: Yup.bool(),
      assetId: Yup.string().required(t("required")),
    }),
    onSubmit: async values => {
      console.log('values',values)

      if (!isEdit) {
        const newItem = {
          ...values,
          emergency: values?.emergency ? values?.emergency:false,
          autoSelectOnReportIncident: values?.autoSelectOnReportIncident ? values?.autoSelectOnReportIncident:false,
          type: values.type?.value,
        }
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addIncidentType(newItem)
        fetchData()
        //fetchTeams()
        setLoadingBtn(false)
        setselectedFiles([])

        validation.resetForm()
      } else {
        const newItem = {
          id: item.id,
          ...values,
          emergency: values?.emergency ? values?.emergency:false,
          autoSelectOnReportIncident: values?.autoSelectOnReportIncident ? values?.autoSelectOnReportIncident:false,
          type: values.type?.value,
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await updateIncidentType(item.id, newItem)
        fetchData()
        //fetchTeams()
        setLoadingBtn(false)
        setselectedFiles([])

        validation.resetForm()
      }
      toggle()
    },
  });

  const handleUploadFile = async () => {
    if (!isEdit || updateAsset) {
      validation.setTouched({...validation.touched, assetId: true})
      if (selectedFiles.length == 0) {
        validation.setValues({...validation.values, assetId: ''})
        validation.validateForm(validation.values)
        return;
      }
      const file = await submissionFiles();
      validation.values.assetId = file.id
    }
    validation.submitForm()
  }

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row); } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row); } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'asset.path',
      text: t('Asset'),
      formatter: (cell, row, rIdx) => (<img width="100" src={API_URL + '/' + row.asset.path}></img>),
      sort: false
    }, {
      dataField: 'name',
      text: t('Name'),
      sort: true
    }, {
      dataField: 'type',
      text: t('Type'),
      sort: true,
      formatter: (cell, row, rIdx) => { return t(row.type)}
    }, {
      dataField: 'sort_order',
      text: t('Sort'),
      sort: true
    }, {
      dataField: 'emergency',
      text: t('Call me'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.emergency ? t('Yes'):t('No')}
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteIncidentType(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleItemCreateClick = () => {
    setUpdateAsset(false)
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const history = useHistory();

  const handleItemEditClick = (row) => {
    setUpdateAsset(false)
    validation.resetForm()
    setItem(row);
    setIsEdit(true);
    toggle();
  };

  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    validation.setFieldValue('assetId','1',true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const submissionFiles = async () => {
    result = await postImage(selectedFiles[0])
    return result
	}

  const fieldsMarks = ['name', 'emergency', 'sort_order', 'type', 'assetId']
  const checkAllUpdateFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_up',true] }))
    })
  }
  
  const uncheckAllUpdateFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_up',false] }))
    })
  }

  const checkAllOverwriteFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_over',true] }))
    })
  }

  const uncheckAllOverwriteFields = async () => {
    await validation.setValues({
      ...validation.values,
      ...Object.fromEntries(fieldsMarks.map((e) => { return [e+'_over',false] }))
    })
  }

  const checkIfModalConfirmUpdate = () => {
    const anyUpdate = fieldsMarks.filter(e => { return validation.values[e+'_up'] === true || validation.values[e+'_over'] === true }).length > 0
    if(anyUpdate) {
      setModalConfirmUpdate(true)
      return
    }
    handleUploadFile()
  }


  console.log('tableOpts', tableOpts)
  console.log('selectedFiles', selectedFiles)
  console.log('validation', validation.errors)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
        message={t('delete_incident_type').replace('INCIDENT_TYPE',item?.name)}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }
                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal} style={{maxWidth: '60%'}}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                              <Row className={{"border-bottom":true, "border-1":true , "pb-2":true, 'd-none': !isEdit}}>
                                <Col xs={isEdit ? 8:12}></Col>
                                <Col xs={2} className="text-center">
                                  {t('Update entities')}<br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={checkAllUpdateFields}
                                  >{t('Select all')}</button><br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={uncheckAllUpdateFields}
                                  >{t('Unselect all')}</button>
                                </Col>
                                <Col xs={2} className="text-center">
                                {t('Update and overwrite entities')}<br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={checkAllOverwriteFields}
                                  >{t('Select all')}</button><br />{' '}
                                  <button className="btn btn-sm btn-primary mt-2" type="button"
                                    onClick={uncheckAllOverwriteFields}
                                  >{t('Unselect all')}</button>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Name")}</Label>
                                    <Input
                                      name="name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name &&
                                          validation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.name &&
                                      validation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="name_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('name_up', !evt.target.checked , true)}}
                                      checked={validation.values.name_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="name_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('name_over', !evt.target.checked , true)}}
                                      checked={validation.values.name_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Sort Order")}</Label>
                                    <Input
                                      name="sort_order"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.sort_order || ""}
                                      invalid={
                                        validation.touched.sort_order &&
                                          validation.errors.sort_order
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.sort_order &&
                                      validation.errors.sort_order ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.sort_order}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="sort_order_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('sort_order_up', !evt.target.checked , true)}}
                                      checked={validation.values.sort_order_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="sort_order_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('sort_order_over', !evt.target.checked , true)}}
                                      checked={validation.values.sort_order_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="mb-3">
                                    <Label className="form-Label">{t('Type')}</Label>
                                    <ReactSelect
                                      className={classNames({'is-invalid': validation.errors?.type?.value ? true:false})}
                                      name="type"
                                      options={typeOptions}
                                      value={validation.values.type || null}
                                      onChange={(option, actionMeta) => { validation.setFieldValue('type', option, true)}}
                                      onBlur={ () => { console.log('blur'); validation.setFieldTouched('type', true, true)} }
                                      placeholder={t('Select')+'...'}
                                    />
                                    {validation.touched.type?.value &&
                                      validation.errors.type?.value ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.type?.value}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-3">
                                    <Label className="form-label">{t("Call me")}</Label> <Input
                                        name="emergency"
                                        label="emergency"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('emergency', !evt.target.checked , true)}}
                                        checked={validation.values.emergency}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="emergency_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('emergency_up', !evt.target.checked , true)}}
                                      checked={validation.values.emergency_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="emergency_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('emergency_over', !evt.target.checked , true)}}
                                      checked={validation.values.emergency_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="border-bottom border-1">
                                <Col xs={isEdit ? 8:12}>
                                  <div className="pt-2 mb-3">
                                    <Label className="form-label">{t("Skip and select incident subtype automatically if only one")}</Label> <Input
                                        name="autoSelectOnReportIncident"
                                        label="autoSelectOnReportIncident"
                                        type="checkbox"
                                        onClick={(evt) => { validation.setFieldValue('autoSelectOnReportIncident', !evt.target.checked , true)}}
                                        checked={validation.values.autoSelectOnReportIncident}
                                      />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="autoSelectOnReportIncident_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('autoSelectOnReportIncident_up', !evt.target.checked , true)}}
                                      checked={validation.values.autoSelectOnReportIncident_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="autoSelectOnReportIncident_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('autoSelectOnReportIncident_over', !evt.target.checked , true)}}
                                      checked={validation.values.autoSelectOnReportIncident_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={isEdit ? 8:12}>
                                  { isEdit &&
                                  <div className={classNames({"d-none": updateAsset || validation.values.assetId == '' ? true:false, "pt-2": true, "mb-3":true })}>
                                    <Label className="form-label">{t("Current Asset")}</Label><br />
                                    <img height="100" src={API_URL + '/' + item.asset.path}></img><br />
                                    <button
                                      type="button"
                                      onClick={() => { validation.setFieldValue('assetId', ''); setUpdateAsset(true) }}
                                      className="btn btn-primary btn-sm save-user mt-1"
                                    >
                                      <i className="fas fa-sync"></i> {t("Change")}
                                    </button>
                                  </div>
                                  }
                                  <div className={classNames({"d-none": isEdit && !updateAsset ? true:false, "is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                                      <Label className="form-label">{t("Icon")}</Label><br />
                                      <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                        onFileDialogOpen={() => {
                                          validation.setFieldTouched('assetId', true, true)
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone" style={{minHeight: '120px'}}>
                                            <div
                                              className="text-center needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-5 text-muted bx bx-cloud-upload" />
                                              </div>
                                              <h5>{ t('Drop files here or click to upload') }</h5>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted font-weight-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>{f.formattedSize}</strong>
                                                    </p>                                                    
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                  </div>
                                  {validation.touched.assetId &&
                                        validation.errors.assetId ? (
                                        <FormFeedback type="invalid" className="invalid">
                                          {validation.errors.assetId}
                                        </FormFeedback>
                                      ) : null}
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="assetId_up"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('assetId_up', !evt.target.checked , true)}}
                                      checked={validation.values.assetId_up || ""}
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} className={{"text-center": true, 'd-none': !isEdit}}>
                                  <div className="mt-4">
                                    <Input
                                      name="assetId_over"
                                      type="checkbox"
                                      onClick={(evt) => { validation.setFieldValue('assetId_over', !evt.target.checked , true)}}
                                      checked={validation.values.assetId_over || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="button"
                                      onClick={checkIfModalConfirmUpdate}
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ConfirmModalYesNo
        setShow={setModalConfirmUpdate}
        show={modalConfirmUpdate}
        text={t('update_cascade_incident_type_confirm')}
        onConfirmClick={() => {handleUploadFile(); setModalConfirmUpdate(false) }}
        onCloseClick={() => { uncheckAllUpdateFields(); uncheckAllUpdateFields(); handleUploadFile(); setModalConfirmUpdate(false) } }
      />
    </React.Fragment>
  )
}

export default withTranslation()(IncidentTypesList)
