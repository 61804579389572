import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { resetProfileFlag } from "../../store/actions"
import { withTranslation } from 'react-i18next'
import classNames from "classnames"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { getClient, postImage, updateClientConfig } from "../../helpers/backend_helper"

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const ClientConfig = props => {
  const t = props.t
  const masterItemName = t('Configuration')
  const masterItemNamePlural = t('Configurations')
  //meta title
  document.title = masterItemName + " | Tellit"

  const [item, setItem] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])
  const [loadingBtn, setLoadingBtn] = useState (false)
  const [loading, setLoading] = useState (false)
  const [updateAsset, setUpdateAsset] = useState (false)

  const dispatch = useDispatch()

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  const [user, setUser] = useState({})
  const [idx, setidx] = useState(1)

  const fetchData = async () => {
    const authUser = JSON.parse(localStorage.getItem("authUser"))
    setLoading(true)
    const item = await getClient(authUser.clientId)
    setItem(item)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      assetId: (item?.logoId) || '',
      usePassword: (item && item.usePassword) || false,
      supportEmergency: (item && item.supportEmergency) || false,
      replaceLogo: (item && item.replaceLogo) || false,
      autoSelectonReportIncident: (item && item.autoSelectonReportIncident) || false,
      autoSelectonTellit: (item && item.autoSelectonTellit) || false,
      incidentReportDelay: (item && item.incidentReportDelay) || false,
      incidentReportDelayTime: (item?.incidentReportDelayTime) || 3,
      tellitDelay: (item && item.tellitDelay) || false,
      tellitDelayTime: (item?.tellitDelayTime) || 3,
    },
    validationSchema: Yup.object({
      assetId: Yup.string().when("replaceLogo", {
        is: true,
        then: Yup.string().required(t("required"))
      }),
      supportEmergency: Yup.boolean().required(t("required")),
      autoSelectonReportIncident: Yup.boolean(),
      autoSelectonTellit: Yup.boolean(),
      replaceLogo: Yup.boolean().required(t("required")),
      incidentReportDelay: Yup.boolean().required(t("required")),
      incidentReportDelayTime: Yup.number().when("incidentReportDelay", {
        is: true,
        then: Yup.number().required(t("required"))
      }),
      tellitDelay: Yup.boolean().required(t("required")),
      tellitDelayTime: Yup.number().when("tellitDelay", {
        is: true,
        then: Yup.number().required(t("required"))
      }),
    }),
    onSubmit: async values => {
      console.log('values',values)
    
      const updateItem = {
        id: item.id,
        logoId: values['assetId'],
        supportEmergency: values.supportEmergency ?? false,
        replaceLogo: values.replaceLogo ?? false,
        autoSelectonReportIncident: values.autoSelectonReportIncident ?? false,
        autoSelectonTellit: values.autoSelectonTellit ?? false,
        incidentReportDelay: values.incidentReportDelay ?? false,
        incidentReportDelayTime: values.incidentReportDelayTime,
        tellitDelay: values.tellitDelay ?? false,
        tellitDelayTime: values.tellitDelayTime,
      }

      // update user
      //dispatch(onUpdateUser(updateUser))
      setLoadingBtn(true)
      const itemResponse = await updateClientConfig(item.id, updateItem)
      fetchData()
      setLoadingBtn(false)

      validation.resetForm()
    
      toggle()
    },
  })

  async function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
    const file = await submissionFiles(files)
    console.log(file.id)
    validation.setFieldValue('assetId', file.id, true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const submissionFiles = async (files) => {
    let result = await postImage(files[0])
    return result
	}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={t('Customer')} breadcrumbItem={masterItemName}/>

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{item?.name}</h5>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="pt-2 mb-3 d-none">
                  <Label className="form-label">{t("Enable Tellit Incident Types")}</Label> <Input
                      name="supportEmergency"
                      label="active"
                      type="checkbox"
                      onChange={(evt) => {}}
                      onClick={(evt) => { validation.setFieldValue('supportEmergency', !evt.target.checked , true)}}
                      checked={validation.values.supportEmergency}
                      invalid={
                        validation.touched.supportEmergency &&
                          validation.errors.supportEmergency
                          ? true
                          : false
                      }
                    />
                </div>
                <div className="pt-2 mb-3">
                  <Label className="form-label">{t("Report incidence") + ": " + t("skip and select incident type automatically if only one")}</Label> <Input
                      name="autoSelectonReportIncident"
                      label="active"
                      type="checkbox"
                      onChange={(evt) => {}}
                      onClick={(evt) => { validation.setFieldValue('autoSelectonReportIncident', !evt.target.checked , true)}}
                      checked={validation.values.autoSelectonReportIncident}
                      invalid={
                        validation.touched.autoSelectonReportIncident &&
                          validation.errors.autoSelectonReportIncident
                          ? true
                          : false
                      }
                    />
                </div>
                <div className="pt-2 mb-3">
                  <Label className="form-label">{t("Tellit") + ": " + t("skip and select incident type automatically if only one")}</Label> <Input
                      name="autoSelectonTellit"
                      label="active"
                      type="checkbox"
                      onChange={(evt) => {}}
                      onClick={(evt) => { validation.setFieldValue('autoSelectonTellit', !evt.target.checked , true)}}
                      checked={validation.values.autoSelectonTellit}
                      invalid={
                        validation.touched.autoSelectonTellit &&
                          validation.errors.autoSelectonTellit
                          ? true
                          : false
                      }
                    />
                </div>
                <div className="pt-2 mb-3">
                  <Label className="form-label">{t("Report incident delay")}</Label> <Input
                      name="incidentReportDelay"
                      label="active"
                      type="checkbox"
                      onChange={(evt) => {}}
                      onClick={(evt) => { validation.setFieldValue('incidentReportDelay', !evt.target.checked , true)}}
                      checked={validation.values.incidentReportDelay}
                      invalid={
                        validation.touched.incidentReportDelay &&
                          validation.errors.incidentReportDelay
                          ? true
                          : false
                      }
                    />
                </div>
                { validation.values.incidentReportDelay &&
                <div className="pt-2 mb-3">
                  <Label className="form-label">{t("Report incident waiting time (sec)")}</Label>
                  <Input
                      name="incidentReportDelayTime"
                      label="active"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.incidentReportDelayTime}
                      invalid={
                        validation.touched.incidentReportDelayTime &&
                          validation.errors.incidentReportDelayTime
                          ? true
                          : false
                      }
                    />
                </div>
                }
                
                <div className="pt-2 mb-3">
                  <Label className="form-label">{t("Tellit delay")}</Label> <Input
                      name="tellitDelay"
                      label="active"
                      type="checkbox"
                      onChange={(evt) => {}}
                      onClick={(evt) => { validation.setFieldValue('tellitDelay', !evt.target.checked , true)}}
                      checked={validation.values.tellitDelay}
                      invalid={
                        validation.touched.tellitDelay &&
                          validation.errors.tellitDelay
                          ? true
                          : false
                      }
                    />
                </div>
                { validation.values.tellitDelay &&
                <div className="pt-2 mb-3">
                  <Label className="form-label">{t("Tellit waiting time (sec)")}</Label>
                  <Input
                      name="tellitDelayTime"
                      label="active"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.tellitDelayTime}
                      invalid={
                        validation.touched.tellitDelayTime &&
                          validation.errors.tellitDelayTime
                          ? true
                          : false
                      }
                    />
                    {validation.touched.tellitDelayTime &&
                      validation.errors.tellitDelayTime ? (
                      <FormFeedback type="invalid" className="invalid">
                        {validation.errors.tellitDelayTime}
                      </FormFeedback>
                    ) : null}
                </div>
                }
                <div className="pt-2 mb-3">
                  <Label className="form-label">{t("Replace Logo")}</Label> <Input
                      name="replaceLogo"
                      label="active"
                      type="checkbox"
                      onChange={(evt) => {}}
                      onClick={(evt) => { validation.setFieldValue('replaceLogo', !evt.target.checked , true)}}
                      checked={validation.values.replaceLogo}
                      invalid={
                        validation.touched.replaceLogo &&
                          validation.errors.replaceLogo
                          ? true
                          : false
                      }
                    />
                </div>
                <div className={classNames({"is-invalid": validation.touched.assetId && validation.errors.assetId ? true:false})}>
                  { validation.values.assetId && item?.logo?.path &&
                  <div className={classNames({"d-none": updateAsset || validation.values.assetId == '' ? true:false, "pt-2": true, "mb-3":true })}>
                    <Label className="form-label">{t("Current Logo")}</Label><br />
                    <img height="100" src={API_URL + '/' + item.logo.path}></img><br />
                    <button
                      type="button"
                      onClick={() => { validation.setFieldValue('assetId', ''); setUpdateAsset(true) }}
                      className="btn btn-primary btn-sm save-user mt-1"
                    >
                      <i className="fas fa-sync"></i> {t("Change")}
                    </button>
                  </div>
                  }
                  <Label className="form-label">{t("Logo")}</Label><br />
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                    onFileDialogOpen={() => {
                      validation.setFieldTouched('assetId', true, true)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone" style={{minHeight: '120px'}}>
                        <div
                          className="text-center needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-1">
                            <i className="display-5 text-muted bx bx-cloud-upload" />
                          </div>
                          <h5>{ t('Drop files here or click to upload') }</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>                                                    
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                  </div>
                </div>
                {validation.touched.assetId &&
                      validation.errors.assetId ? (
                      <FormFeedback type="invalid" className="invalid">
                        {validation.errors.assetId}
                      </FormFeedback>
                    ) : null}

                <div className="text-center mt-4">
                  <Button type="submit" color="primary">
                    {t("Update")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation ()(ClientConfig)