import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  Table,
  Button,
  Input,
} from "reactstrap";

//redux
import Piet from "../../Charts/piechart";
import * as moment from "moment";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import {
  getViewsFilters,
  getReportIncidentsTypeByEntity,
  getReportViews,
} from "../../../helpers/backend_helper";
import ReactSelect from "react-select";
import ReactDatePicker from "react-datepicker";
import LineChartDate from "../../Charts/linechartDate";
import LineChartTime from "../../Charts/linechartTime";

const ViewsReport = (props) => {
  const t = props?.t;
  const masterItemName = t("Views Report");
  const masterItemNamePlural = t("Views Report");
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [report, setReport] = useState([]);
  const [clients, setClients] = useState([]);
  const [entities, setEntities] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [advertisementsTypes, setAdvertisementsTypes] = useState([
    {name: 'Level 1'},
    {name: 'Level 2'}
  ]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState([]);
  const [selectedContract, setSelectedContract] = useState([]);
  const [selectedAdvertismetType, setSelectedAdvertismetType] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(1);
  const [selectedPeriod, setSelectedPeriod] = useState({
    start: moment().startOf("day").toDate(),
    end: moment().endOf("day").toDate(),
  });
  const [touched, setTouched] = useState(false);

  const filterPassedTime = (time) => {
    const startDate = selectedPeriod.start;
    const selectedDate = new Date(time);

    return selectedDate.getTime() > startDate.getTime();
  };

  //meta title
  document.title = masterItemName + " | Tellit";

  const fetchIncidentsFilters = async () => {
    console.log("selectedEntity", selectedEntity);
    const response = await getViewsFilters();
    setClients(response.filters.clients);
    setContracts(response.filters.contracts);
    let entities = response.filters.clients.map(e=>e.entities);
    entities = entities.reduce((arr, val) => {
      return arr.concat(val)
     }, [])
    setEntities(Array.from(new Set(entities)))

    return response;
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await fetchIncidentsFilters();
      setLoading(false);
    };

    load();
  }, []);

  const fetchReport = async () => {
    setLoadingReport(true);
    try {
      const response = await getReportViews({
        periodType: parseInt(selectedRadio) == 1 ? "date" : "time",
        client:
          selectedClient?.length == 0
            ? []
            : selectedClient.map((e) => e.id),
        entity:
          selectedEntity?.length == 0
            ? []
            : selectedEntity.map((e) => e.id),
        contract:
          selectedContract?.length == 0
            ? []
            : selectedContract.map((e) => e.id),
        type:
          selectedAdvertismetType?.length == 0
            ? []
            : selectedAdvertismetType.map((e) => e.name),
        period:
          parseInt(selectedRadio) == 1
            ? {
                start: moment(selectedPeriod.start).format("Y-MM-DD HH:mm"),
                end: moment(selectedPeriod.end).format("Y-MM-DD HH:mm"),
              }
            : {
                start: moment(selectedPeriod.start).format("Y-MM-DD HH:mm"),
                end: moment(selectedPeriod.end).format("Y-MM-DD HH:mm"),
              },
      });
      response.entity.summary.forEach(e=>{
        e.name = entities.find(i=>i.id==e.entityId)?.name ?? ''
      })
      response.entity.summaryByPeriod.forEach(e=>{
        e.name = entities.find(i=>i.id==e.entityId)?.name ?? ''
      })

      response.contract.summary.forEach(e=>{
        e.name = contracts.find(i=>i.id==e.contractId)?.name ?? ''
      })
      response.contract.summaryByPeriod.forEach(e=>{
        e.name = contracts.find(i=>i.id==e.contractId)?.name ?? ''
      })

      response.type.summary.forEach(e=>{
        e.name = advertisementsTypes.find(i=>i.name==e.type)?.name ?? ''
      })
      response.type.summaryByPeriod.forEach(e=>{
        e.name = advertisementsTypes.find(i=>i.name==e.type)?.name ?? ''
      })
      
      setReport(response);
    } catch (err) {
      console.log(err);
    }
    setLoadingReport(false);
  };

  function resetFields() {
    setSelectedEntity([]);
    setSelectedRadio(1);
    setSelectedPeriod({ start: null, end: null });
    setTouched(false);
    setReport([]);
  }

  // console.log(selectedEntity, selectedPeriod)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t(masterItemName)}
            breadcrumbItem={masterItemNamePlural}
          />
          <Row>
            <Col lg="12">
              <Card style={{ minHeight: "400px" }}>
                <CardBody className="">
                  <Row className="mb-2">
                    <Col md="3">
                      <Label className="form-Label">{t("Customer")}</Label>
                      <ReactSelect
                        name="customer_id"
                        options={clients ?? []}
                        value={selectedClient}
                        onChange={(option) => {
                          setSelectedClient(option);
                        }}
                        isClearable={true}
                        getOptionValue={(v) => v.id}
                        getOptionLabel={(v) => v.name}
                        placeholder={t("Select") + "..."}
                        isMulti={true}
                      />
                      {touched && selectedClient == null && (
                        <div className="text-danger">{t("Required")}</div>
                      )}
                    </Col>

                    <Col md="3">
                      <Label className="form-Label">{t("Entity")}</Label>
                      <ReactSelect
                        name="entity_id"
                        options={entities}
                        value={selectedEntity}
                        onChange={(option) => {
                          setSelectedEntity(option);
                        }}
                        isClearable={true}
                        getOptionValue={(v) => v.id}
                        getOptionLabel={(v) => v.name}
                        placeholder={t("Select") + "..."}
                        isMulti={true}
                      />
                      {touched && selectedEntity == null && (
                        <div className="text-danger">{t("Required")}</div>
                      )}
                    </Col>

                    <Col md="3">
                      <Label className="form-Label">{t("Contract")}</Label>
                      <ReactSelect
                        name="incident_type"
                        options={contracts ?? []}
                        value={selectedContract}
                        isClearable={true}
                        onChange={(option) => {
                          setSelectedContract(option);
                        }}
                        getOptionValue={(v) => v.id}
                        getOptionLabel={(v) => v.name}
                        placeholder={t("Select") + "..."}
                        isMulti={true}
                      />
                      {touched && selectedContract == null && (
                        <div className="text-danger">{t("Required")}</div>
                      )}
                    </Col>

                    <Col md="3">
                      <Label className="form-Label">
                        {t("Advertisement type")}
                      </Label>
                      <ReactSelect
                        name="incident_type"
                        options={advertisementsTypes ?? []}
                        value={selectedAdvertismetType}
                        isClearable={true}
                        onChange={(option) => {
                          setSelectedAdvertismetType(option);
                        }}
                        placeholder={t("Select") + "..."}
                        getOptionValue={(v) => v.name}
                        getOptionLabel={(v) => v.name}
                        isMulti={true}
                      />
                      {touched && selectedAdvertismetType == null && (
                        <div className="text-danger">{t("Required")}</div>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="3">
                      <div className="form-check mt-2">
                        <Input
                          name="type"
                          id="type1"
                          className="radio"
                          type="radio"
                          checked={selectedRadio == 1}
                          onClick={() => setSelectedRadio(1)}
                        />
                        <Label className="form-Label" for="type1">
                          {t("Report by days")}
                        </Label>
                      </div>
                      <div className="form-check">
                        <Input
                          name="type"
                          id="type2"
                          className="radio"
                          type="radio"
                          checked={selectedRadio == 2}
                          onClick={() => setSelectedRadio(2)}
                        />
                        <Label className="form-Label" for="type2">
                          {t("Report by hours")}
                        </Label>
                      </div>
                    </Col>
                    {selectedRadio == 1 && (
                      <>
                        <Col md="3">
                          <Label className="form-Label">
                            {t("Start date")}
                          </Label>
                          <ReactDatePicker
                            className="form-control"
                            selected={selectedPeriod.start}
                            onChange={(date) =>
                              setSelectedPeriod({
                                ...selectedPeriod,
                                start: date,
                              })
                            }
                            dateFormat="dd/MM/yyyy"
                          />
                          {touched && selectedPeriod.start == null && (
                            <div className="text-danger">{t("Required")}</div>
                          )}
                        </Col>
                        <Col md="3">
                          <Label className="form-Label">{t("End date")}</Label>
                          <ReactDatePicker
                            className="form-control"
                            selected={selectedPeriod.end}
                            onChange={(date) =>
                              setSelectedPeriod({
                                ...selectedPeriod,
                                end: date,
                              })
                            }
                            dateFormat="dd/MM/yyyy"
                            filterTime={filterPassedTime}
                          />
                          {touched && selectedPeriod.end == null && (
                            <div className="text-danger">{t("Required")}</div>
                          )}
                        </Col>
                      </>
                    )}
                    {selectedRadio == 2 && (
                      <>
                        <Col md="3">
                          <Label className="form-Label">
                            {t("Start date")}
                          </Label>
                          <ReactDatePicker
                            className="form-control"
                            selected={selectedPeriod.start}
                            onChange={(date) =>
                              setSelectedPeriod({
                                ...selectedPeriod,
                                start: date,
                              })
                            }
                            dateFormat="dd/MM/yyyy"
                          />
                          {touched && selectedPeriod.start == null && (
                            <div className="text-danger">{t("Required")}</div>
                          )}
                        </Col>
                        <Col md="3">
                          <Label className="form-Label">{t("End date")}</Label>
                          <ReactDatePicker
                            className="form-control"
                            selected={selectedPeriod.end}
                            onChange={(date) =>
                              setSelectedPeriod({
                                ...selectedPeriod,
                                end: date,
                              })
                            }
                            dateFormat="dd/MM/yyyy"
                            filterTime={filterPassedTime}
                          />
                          {touched && selectedPeriod.end == null && (
                            <div className="text-danger">{t("Required")}</div>
                          )}
                        </Col>
                      </>
                    )}

                    <Col md="3" className="text-right">
                      <Button
                        className="btn btn-danger btn-block mt-4 me-2"
                        type="button"
                        onClick={fetchReport}
                        disabled={loadingReport}
                      >
                        <i className="fa fa-list-alt"></i> {t("Create Report")}
                      </Button>
                      <Button
                        className="btn btn-danger btn-block mt-4"
                        type="button"
                        onClick={resetFields}
                      >
                        <i className="fa fa-redo"></i> {t("Reset")}
                      </Button>
                    </Col>
                  </Row>

                  {report.entity?.summary?.length == 0 &&
                    report.contract?.summary?.length == 0 &&
                    report.type?.summary?.length == 0 && (
                      <div className="mt-5 alert alert-dark text-center">
                        {t("No data available for this selection")}
                      </div>
                    )}

                  {report.entity?.summary?.length > 0 && (
                    <>
                      <h4 className="mt-5 text-center">
                        {t("Entities")}
                      </h4>
                      <Row className="mt-2">
                        <Col md="6">
                          <div id="pie-chart" className="e-chart pt-3">
                            {<Piet data={report.entity.summary} label="name" />}
                          </div>
                        </Col>
                        <Col md="6">
                          <div id="pie-chart" className="e-chart">
                            {selectedRadio == 1 ? (
                              <LineChartDate
                                data={report?.entity?.summaryByPeriod}
                                period={selectedPeriod}
                                label="name"
                              />
                            ) : (
                              <LineChartTime
                                data={report?.entity?.summaryByPeriod}
                                period={selectedPeriod}
                                label="name"
                              />
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl="12">
                          <Table className="table table-sm table-bordered table-striped mb-0">
                            <thead className="table-light table-sm">
                              <tr>
                                <th style={{width: '100px'}}>#</th>
                                <th>{t("Entity")}</th>
                                <th>{t("Views")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(report?.entity?.summary ?? []).map((e, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{e.name}</td>
                                    <td>{e.total}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}

                  {report.contract?.summary?.length > 0 && (
                    <>
                      <h4 className="mt-5 text-center">
                        {t("Contracts")}
                      </h4>
                      <Row className="mt-2">
                        <Col md="6">
                          <div id="pie-chart" className="e-chart pt-3">
                            {
                              <Piet
                                data={report.contract.summary}
                                label="name"
                              />
                            }
                          </div>
                        </Col>
                        <Col md="6">
                          <div id="pie-chart" className="e-chart">
                            {selectedRadio == 1 ? (
                              <LineChartDate
                                data={report?.contract?.summaryByPeriod}
                                period={selectedPeriod}
                                label="name"
                              />
                            ) : (
                              <LineChartTime
                                data={report?.contract?.summaryByPeriod}
                                period={selectedPeriod}
                                label="name"
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col xl="12">
                          <Table className="table table-sm table-bordered table-striped mb-0">
                            <thead className="table-light ">
                              <tr>
                                <th style={{width: '100px'}}>#</th>
                                <th>{t("Contract")}</th>
                                <th>{t("Views")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(report?.contract?.summary ?? []).map(
                                (e, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>{idx + 1}</td>
                                      <td>{e.name}</td>
                                      <td>{e.total}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}

                  {report.type?.summary?.length > 0 && (
                    <>
                      <h4 className="mt-5 text-center">{t("Types")}</h4>
                      <Row className="mt-2">
                        <Col md="6">
                          <div id="pie-chart" className="e-chart pt-3">
                            {
                              <Piet
                                data={report.type.summary}
                                label="name"
                              />
                            }
                          </div>
                        </Col>
                        <Col md="6">
                          <div id="pie-chart" className="e-chart">
                            {selectedRadio == 1 ? (
                              <LineChartDate
                                data={report?.type?.summaryByPeriod}
                                period={selectedPeriod}
                                label="name"
                              />
                            ) : (
                              <LineChartTime
                                data={report?.type?.summaryByPeriod}
                                period={selectedPeriod}
                                label="name"
                              />
                            )}
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl="12">
                          <Table className="table table-sm table-bordered table-striped mb-0">
                            <thead className="table-light table-sm">
                              <tr>
                                <th style={{width: '100px'}}>#</th>
                                <th>{t("Advertisement type")}</th>
                                <th>{t("Reports")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(report?.type?.summary ?? []).map(
                                (e, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>{idx + 1}</td>
                                      <td>
                                        {e.name}
                                      </td>
                                      <td>{e.total}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ViewsReport);
