import React, { useEffect, useState, useRef, useMemo } from "react";
import {  Link } from "react-router-dom"
import TableContainer from "../../../../components/Common/TableContainer";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import DeleteModal from "../../../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Badge,
} from "reactstrap"
import { isEmpty } from "lodash";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Email, Tags, Projects, Img } from "./history-report-col";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "../../../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { withTranslation } from 'react-i18next';

const HistoryReport = (props) => {
  
  const t = props.t;
  const masterItemName = props.t('History Incident Report');
  const masterItemNamePlural = props.t('History Incident Report');

  //meta title
  document.title = masterItemName + " List | Tellit";

  const dispatch = useDispatch()

  const { users } = useSelector(state => ({
    users: [
      //{ id:1, entity: "SCHOOL AMERICA", type: t('Abuse'), date:'23/09/2022 10:23', emergency: true, status: t("Accepted"), user_owner: "Jhon Smith"},
      //{ id:2, entity: "SCHOOL AMERICA", type: t('Lamp bulb defect'), date:'212/09/2022 18:01',  emergency: false, status: t("Accepted"), user_owner: "Joanne Sho"}
    ]
  }));

  const [contact, setContact] = useState();
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      designation: (contact && contact.designation) || "",
      tags: (contact && contact.tags) || "",
      email: (contact && contact.email) || "",
      projects: (contact && contact.projects) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      tags: Yup.array().required("Please Enter Tag"),
      email: Yup.string().required("Please Enter Your Email"),
      projects: Yup.number().required("Please Enter Your Project"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: contact.id,
          name: values.name,
          designation: values.designation,
          tags: values.tags,
          email: values.email,
          projects: values.projects,
        };

        // update user
        dispatch(onUpdateUser(updateUser));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: t("Entity"),
        accessor: "entity",
      },
      {
        Header: t("Incident Type"),
        accessor: "type"
      },
      {
        Header: t("Date"),
        accessor: "date",
        width: "20%"
      },
      {
        Header: t("Call me"),
        accessor: "emergency",
        width: "20%",
        Cell: (params) => {
          return (
            <>
              { params.row.original.emergency ? t('Yes'):t('No') }
            </>
          );
        },
      },
      {
        Header: t("Entity Status"),
        width: "20%",
        Cell: (user) => {
          return (
            <div>
              <Badge className="rounded-pill bg-success">{(user.row.original.status) }</Badge>
            </div>
          );
        },
      },
      {
        Header: t("Owner"),
        accessor: "user_owner",
        width: "20%"
      },
    ],
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, []);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>
        <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t(masterItemName)} breadcrumbItem={masterItemNamePlural} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">
                     
                        <TableContainer
                          columns={columns}
                          data={users}
                          isGlobalFilter={true}
                          isAddUserList={true}
                          handleUserClick={handleUserClicks}
                          customPageSize={10}
                          className="custom-header-css"
                          addBtnText={props.t('Add') + " " + props.t(masterItemName)}
                          disableAdd={true}
                        />

                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(HistoryReport)
