import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import UserRole from "../../common/UserRole";

const SidebarContent = (props) => {
  const ref = useRef();
  const [user, setUser] = useState({});

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);
      setUser(obj);
    }
  }, []);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      let maxSize = 0;
      console.log(items.length);
      for (let i = 0; i < items.length; ++i) {
        if (pathName.includes(items[i].pathname)) {
          if (maxSize < items[i].pathname.length) {
            matchingMenuItem = items[i];
            maxSize = items[i].pathname.length;
          }
        }
      }
      console.log("matchingMenuItem", matchingMenuItem);
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    if (user.id) initMenu();
  }, [props.location.pathname, user]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard" className="">
                <FeatherIcon icon="home" />
                <span>{props.t("Home")}</span>
              </Link>
            </li>
            <li>
              {[
                UserRole.SYS_ADMIN,
                UserRole.MAIN_PROVIDER,
                UserRole.MASTER_ADMIN,
              ].includes(user.role) && (
                <Link to="/#" className="has-arrow">
                  <FeatherIcon icon="grid" />
                  <span>{props.t("Managment")}</span>
                </Link>
              )}
              <ul className="sub-menu">
                {[UserRole.SYS_ADMIN].includes(user.role) && (
                  <li>
                    <Link to="/managment/sys-configuration">
                      {props.t("System configuration")}
                    </Link>
                  </li>
                )}
                {[UserRole.SYS_ADMIN, UserRole.MAIN_PROVIDER].includes(
                  user.role
                ) && (
                  <li>
                    <Link to="/managment/providers">
                      {props.t("Representatives")}
                    </Link>
                  </li>
                )}
                {[UserRole.SYS_ADMIN, UserRole.MAIN_PROVIDER].includes(
                  user.role
                ) && (
                  <li>
                    <Link className="" to="/managment/sponsors">
                      {props.t("Sponsors")}
                    </Link>
                  </li>
                )}
                {[UserRole.MASTER_ADMIN].includes(user.role) && (
                  <li>
                    <Link className="" to="/managment/configuration">
                      {props.t("Configuration")}
                    </Link>
                  </li>
                )}
                {[UserRole.SYS_ADMIN, UserRole.MAIN_PROVIDER].includes(
                  user.role
                ) && (
                  <li>
                    <Link to="/managment/clients">{props.t("Customers")}</Link>
                  </li>
                )}
                {[UserRole.MASTER_ADMIN].includes(user.role) && (
                  <li>
                    <Link to="/managment/subadmins">
                      {props.t("SubAdmins")}
                    </Link>
                  </li>
                )}
                {[UserRole.MASTER_ADMIN, UserRole.SUB_ADMIN].includes(
                  user.role
                ) && (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      {props.t("Response Teams")}
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/managment/response-teams">
                          {props.t("Teams")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/managment/response-team-users">
                          {props.t("Incident Responders")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                {[UserRole.MASTER_ADMIN, UserRole.SUB_ADMIN].includes(
                  user.role
                ) && (
                  <li>
                    <Link to="/managment/locations">
                      {props.t("Locations")}
                    </Link>
                  </li>
                )}
                {[UserRole.MASTER_ADMIN, UserRole.SUB_ADMIN].includes(
                  user.role
                ) && (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      {props.t("Incidents Configuration")}
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/managment/incident-types">
                          {props.t("Incident Types")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/managment/incident-subtypes">
                          {props.t("Incident Subtypes")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                {[UserRole.MASTER_ADMIN, UserRole.SUB_ADMIN].includes(
                  user.role
                ) && (
                  <li>
                    <Link to="/managment/entities">{props.t("Entities")}</Link>
                  </li>
                )}
                {[UserRole.MASTER_ADMIN, UserRole.SUB_ADMIN].includes(
                  user.role
                ) && (
                  <li>
                    <Link to="/managment/notices">{props.t("Notices")}</Link>
                  </li>
                )}
              </ul>
            </li>

            {[UserRole.SPONSOR].includes(user.role) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <FeatherIcon icon="grid" />
                  <span>{props.t("Managment")}</span>
                </Link>

                <ul className="sub-menu">
                  <li>
                    <Link to="/managment/sponsors/contracts">
                      {props.t("Contracts")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/managment/sponsors/advertisements">
                      {props.t("Advertisements")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {["MASTER_ADMIN", "INCIDENT_RESPONDER"].includes(user.role) && (
              <li>
                <Link to="/#" className="has-arrow">
                  <FeatherIcon icon="list" />
                  <span>{props.t("Incidents")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/incidents/work-on">{props.t("Working on")}</Link>
                  </li>
                  <li>
                    <Link to="/incidents/history">{props.t("History")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {["MASTER_ADMIN"].includes(user.role) && (
              <li className="d-none">
                <Link to="/#" className="has-arrow">
                  <FeatherIcon icon="zap" />
                  <span>{props.t("Call me")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/#">{props.t("Working on")}</Link>
                  </li>
                  <li>
                    <Link to="/#">{props.t("History")}</Link>
                  </li>
                </ul>
              </li>
            )}

            <li>
              <Link to="/#" className="has-arrow ">
                <FeatherIcon icon="file-text" />
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                {![UserRole.SPONSOR].includes(user.role) && (
                  <li>
                    <Link to="/reports/incidents-by-entity">
                      {props.t("Report by Filter")}
                    </Link>
                  </li>
                )}
                {[UserRole.SPONSOR, UserRole.SYS_ADMIN].includes(user.role) && (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <span>{props.t("Sponsoring")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/reports/visits-by-entity">{props.t("Views")}</Link>
                      </li>
                      <li>
                        <Link to="/reports/visits-by-entity">
                          {props.t("Clicks")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </li>

            {[UserRole.SYS_ADMIN].includes(user.role) && (
              <li>
                <Link to="/#" className="has-arrow ">
                  <FeatherIcon icon="folder" />
                  <span>{props.t("Catalogs")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/catalogs/countries">{props.t("Countries")}</Link>
                  </li>
                  <li>
                    <Link to="/catalogs/languages">{props.t("Languages")}</Link>
                  </li>
                  <li>
                    <Link to="/catalogs/translations">
                      {props.t("Translations")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/catalogs/email-templates">
                      {props.t("Email Templates")}
                    </Link>
                  </li>
                  {[UserRole.SYS_ADMIN].includes(user.role) && (
                    <>
                      <li>
                        <Link to="/managment/sections">
                          {props.t("Sections")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/catalogs/client-areas">
                          {props.t("Customer Areas")}
                        </Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link className="d-none" to="/catalogs/installations">
                      {props.t("Installations")}
                    </Link>
                  </li>
                  <li>
                    <Link className="d-none" to="/#">
                      {props.t("Presets")}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
