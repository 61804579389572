import React, { useEffect, useState } from "react"
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import CreatableSelect from 'react-select/creatable'

//redux
import { withTranslation } from 'react-i18next'
import { addResponseTeam, deleteResponseTeam, deleteUser, getResponseTeams, getTeamsSelect, putUserResetPassword, updateResponseTeam } from '../../helpers/backend_helper'
import TableContainer2 from '../../components/Common/TableContainer2'

import '../../components/Common/custom.css'
import ConfirmModal from "../../components/Common/ConfirmModal"
import { useHistory } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import classNames from "classnames"
import getAuthUser from "../../components/helpers/auth"

const ResponseTeamsList = props => {
  const t = props.t
  const masterItemName = t('Response Team')
  const masterItemNamePlural = t('Response Teams')
  const currentUser = getAuthUser()

  //meta title
  document.title = masterItemName + "List | Tellit"

  const [items, setItems] = useState([])
  const [item, setItem] = useState()
  const [teams, setTeams] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false)
  const [loadingBtn, setLoadingBtn] = useState (false)

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null,
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getResponseTeams({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (item && item.name) || "",
      description: (item?.description) || "",
      min_internal: item?.min_internal ?? 1,
      max_internal: item?.max_internal ?? 1,
      min_external: item?.min_external ?? 0,
      max_external: item?.max_external ?? 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("required")).min(1),
      description: Yup.string().required(t("required")),
      min_internal: Yup.number().min(0).required(t("required")),
      max_internal: Yup.number().min(0).required(t("required")),
      min_external: Yup.number().min(0).required(t("required")),
      max_external: Yup.number().min(0).required(t("required")),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          ...values
        }

        // update user
        //dispatch(onUpdateUser(updateUser))
        setLoadingBtn(true)
        const itemResponse = await updateResponseTeam(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm()
      } else {
        console.log(values)
        const newItem = {
          ...values
        }
        // save new user
        //dispatch(onAddNewUser(newUser))
        setLoadingBtn(true)
        const itemResponse = await addResponseTeam(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm()
      }
      toggle()
    },
  })

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemEditClick(row) } }
          title={t("Edit")}>
            <i className="fas fa-edit"></i>
        </Button>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemDeleteClick(row) } }
          title={t("Delete")}>
            <i className="fas fa-times"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'name',
      text: t('Name'),
      sort: true
    }, {
      dataField: 'min_internal',
      text: t('Minimum internal'),
      sort: true
    }, {
      dataField: 'max_internal',
      text: t('Maximum internal'),
      sort: true
    }, {
      dataField: 'min_external',
      text: t('Minimum external'),
      sort: true
    }, {
      dataField: 'max_external',
      text: t('Maximum external'),
      sort: true
    }, {
      dataField: 'responder.name',
      text: t('Responders'),
      formatter: (cell, row, rIdx) => { return row.responders.map( t => t.first_name + ' ' + t.last_name ).join(', ') },
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ]

  const toggle = () => {
    setModal(!modal)
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const handleDeleteUser = async () => {
    const response = await deleteResponseTeam(item.id)
    fetchData()
    setDeleteModal(false)
  }

  const handleResetPass = async () => {
    const response = await putUserResetPassword(item.masterUser.id)
    setConfirmModal(false)
  }

  const handleItemCreateClick = () => {
    setItem(null)
    setIsEdit(false)
    toggle()
    validation.resetForm()
  }

  const handleItemDeleteClick = row => {
    setItem(row)
    setDeleteModal(true)
  }

  const history = useHistory()
  const handleItemDetailClick = row => {
    history.push("/managment/clients/detail/" + row.id)
  }

  const handleItemEditClick = (row) => {
    validation.resetForm()
    setItem(row)
    setIsEdit(true)
    toggle()
  }

  const handleResetPasswordClick = (row) => {
    setItem(row)
    setConfirmModal(true)
  }

  console.log('tableOpts', tableOpts)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ConfirmModal
        show={confirmModal}
        onConfirmClick={handleResetPass}
        onCloseClick={() => setConfirmModal(false)}
        text={t('Do you want reset password?')}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">

                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick() }}
                          addBtnText={t("Add") + " " + masterItemName}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }

                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                        <Modal isOpen={modal}>
                          <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? t("Edit") + " " + masterItemName : t("Add") + " " + masterItemName}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              <Row>
                                <Col xs={12}>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Name")}</Label>
                                    <Input
                                      name="name"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                        validation.touched.name &&
                                          validation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.name &&
                                      validation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Description")}</Label>
                                    <Input
                                      name="description"
                                      type="text"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.description || ""}
                                      invalid={
                                        validation.touched.description &&
                                          validation.errors.description
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.description &&
                                      validation.errors.description ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.description}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Minimum Internal Members")}</Label>
                                    <Input
                                      name="min_internal"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.min_internal || ""}
                                      invalid={
                                        validation.touched.min_internal &&
                                          validation.errors.min_internal
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.min_internal &&
                                      validation.errors.min_internal ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.min_internal}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Maximum Internal Members")}</Label>
                                    <Input
                                      name="max_internal"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.max_internal || ""}
                                      invalid={
                                        validation.touched.max_internal &&
                                          validation.errors.max_internal
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.max_internal &&
                                      validation.errors.max_internal ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.max_internal}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Minimum External Members")}</Label>
                                    <Input
                                      name="min_external"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.min_external || ""}
                                      invalid={
                                        validation.touched.min_external &&
                                          validation.errors.min_external
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.min_external &&
                                      validation.errors.min_external ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.min_external}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="mb-3">
                                  <Label className="form-label">{t("Maximum External Members")}</Label>
                                    <Input
                                      name="max_external"
                                      type="number"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.max_external || ""}
                                      invalid={
                                        validation.touched.max_external &&
                                          validation.errors.max_external
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.max_external &&
                                      validation.errors.max_external ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.max_external}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-center">
                                    <button
                                      type="submit"
                                      className="btn btn-primary btn-md save-user"
                                    >
                                      <i className="fas fa-save"></i> {t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ResponseTeamsList)
