import React, { useEffect, useState } from "react";
//Import Breadcrumb
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { withTranslation } from 'react-i18next';
import { addEntity, addIncident, deleteEntity, getCountriesPhoneSelect, getEntitiesSelect, getEntityIncidentTypesWithSubtypesSelect, getIncidents, getIncidentsActive, getIncidentsInactive, getIncidentTypesSelect, postImage, putUserResetPassword, updateEntity } from '../../helpers/backend_helper';
import TableContainer2 from '../../components/Common/TableContainer2';

import '../../components/Common/custom.css';
import classNames from "classnames";
import ConfirmModal from "../../components/Common/ConfirmModal";
import { Link, useHistory } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import getAuthUser from "../../components/helpers/auth";
import Dropzone from "react-dropzone";
import * as moment from 'moment';
const API_URL = process.env.REACT_APP_TELLIT_API_URL

const ClosedIncidentsList = props => {
  const currentUser = getAuthUser()
  const t = props.t;
  const masterItemName = t('Incident');
  const masterItemNamePlural = t('Incidents');

  //meta title
  document.title = masterItemName + "List | Tellit";

  const [items, setItems] = useState([]);
  const [item, setItem] = useState();
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState (false);
  const [loadingBtn, setLoadingBtn] = useState (false);


  const [iniDate, setIniDate] = useState (new Date().toISOString().substring(0,10));
  const [iniTime, setIniTime] = useState (new Date().toLocaleString().substring(12,20));
  
  const [entities, setEntities] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);

  const [types, setTypes] = useState([]);
  const [locations, setLocations] = useState([]);

  const [tableOpts, setTableOpts] = useState({
    page: 1,
    take: 15,
    search: null
  })
  const [tableMeta, setTableMeta] = useState({
    page: 1,
    take: 15,
    itemCount: 0, 
    search: null
  })

  const fetchData = async () => {
    setLoading(true)
    const response = await getIncidentsInactive({params: tableOpts}) 
    setItems(response.data)
    setTableMeta({...tableMeta, ...response.meta})
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [tableOpts])



  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      entity: (item?.entity && {value: item.entity.value , label: item.entity.name}) || '',
      incidentSubtype: (item?.incidentSubtype?.id && {value: item.incidentSubtype.id , label: item.incidentSubtype.name}) || '',
      location: (item?.location?.id && {value: item.location.id , label: item.location.name}) || '',
      latitude: (item && item.locationGps.split(',')[0]) || "",
      longitude: (item && item.locationGps.split(',')[1]) || "",
      comments: (item && item.locationGps.split(',')[1]) || "",
      evidences: (item?.evidences) || [],
      date: (item?.evidences) || iniDate,
      time: (item?.evidences) || iniTime,
    },
    validationSchema: Yup.object({
      latitude: Yup.string().required(t("required")),
      longitude: Yup.string().required(t("required")),
      entity: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      incidentSubtype: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      location: Yup.object().shape({
        value: Yup.string().required(t("required")),
      }),
      latitude: Yup.number(),
      longitude: Yup.number(),
      comments: Yup.string(),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          ...values,
          id: item.id,
          groups: values.groups.map(e => { return {id: e.__isNew__ ? null:e.value, name:e.label}}),
          active: values.active ? values.active : false,
          adBlocking: values.adblocking ? values.adblocking : false,
          supportEmergency: values.supportEmergency ? values.supportEmergency : false,
          phone: {
            ...values.phone,
            area_code: values.phone.area_code ? values.phone.area_code.toString():'',
            country_code: values.phone.country_code.value.toString(),
            number: values.phone.number.toString()
          }
        };

        // update user
        //dispatch(onUpdateUser(updateUser));
        setLoadingBtn(true)
        const itemResponse = await updateEntity(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm();
      } else {
        const newItem = {
          ...values,
          locationGps: values.latitude + ',' + values.longitude,
          entityId: values.entity.value,
          subTypeId: values.incidentSubtype.value,
          locationId: values.location.value,
          evidences: values.evidences,
          date_event: values.date + ' ' + values.time,
          answers: '{}',
          entityName: values.entity.label,
          type: values.incidentSubtype.label.split('|')[0].trim(),
          subtype: values.incidentSubtype.label.split('|')[1].trim(),
          location: values.location.label
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addIncident(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm();
      }
      toggle();
    },
  });

  function buttonActions(cell, row, rIdx) {
    return (    
      <span>
        <Button 
          type="button"
          className="btn btn-sm btn-primary ms-1"
          onClick={(evt) => { handleItemViewClick(row); } }
          title={t("View Detail")}>
            <i className="fas fa-eye"></i>
        </Button>
      </span>
    )
  }

  const columns = [
    {
      dataField: 'subtype',
      text: t('Subtype'),
      sort: true,
    }, {
      dataField: 'created_at',
      text: t('System Creation Time'),
      sort: true,
      formatter: (cell, row, rIdx) => { return moment(row.created_at).local().format('YYYY-MM-DD HH:mm') },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'date_event',
      text: t('User Specified Time'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.date_event ? moment(row.date_event).local().format('YYYY-MM-DD HH:mm'):'' },
      classes: (cell, row, rIdx) => { return classNames({'text-danger': row.emergency}) }
    }, {
      dataField: 'entityName',
      text: t('Entity'),
      sort: true,
    }, {
      dataField: 'status',
      text: t('Status'),
      sort: true,
      formatter: (cell, row, rIdx) => { return (<b>{t(row.status)}</b>); }
    }, {
      dataField: 'external_responder_status',
      text: t('External Responders'),
      formatter: (cell, row, rIdx) => { return (<Badge className="rounded-pill bg-danger">{ props.t("No Available") }</Badge>) }
    }, {
      dataField: 'userOwner',
      text: t('Owner'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.userOwner.first_name + ' ' + row.userOwner.last_name }
    }, {
      dataField: 'emergency',
      text: t('Call me'),
      sort: true,
      formatter: (cell, row, rIdx) => { return row.emergency ? t('Yes') : t('No') ; }
    }, {
      dataField: 'id',
      text: t('Actions'),
      formatter: buttonActions
    }
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleDeleteUser = async () => {
    const response = await deleteEntity(item.id)
    fetchData()
    setDeleteModal(false);
  };

  const handleResetPass = async () => {
    const response = await putUserResetPassword(item.masterUser.id)
    setConfirmModal(false);
  };

  const handleItemCreateClick = () => {
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  const handleItemDeleteClick = row => {
    setItem(row);
    setDeleteModal(true);
  };

  const handleItemEditClick = (row) => {
    setItem(row);
    setIsEdit(true);
    toggle();
  };

  const history = useHistory();
  const handleItemViewClick = (row) => {
    history.push("/incidents/work-on/" + row.id);
  };


  const [selectedFiles, setselectedFiles] = useState([]);

  async function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    console.log('handleAcceptedFiles', files)
    const file = await postImage(files[0]);
    validation.setFieldValue('evidences', [...validation.values.evidences, file])
    validation.setFieldTouched('evidences', true)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  
  console.log('tableOpts', tableOpts)
  console.log('locations', locations)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />

      <ConfirmModal
        show={confirmModal}
        onConfirmClick={handleResetPass}
        onCloseClick={() => setConfirmModal(false)}
        text={t('Do you want reset password?')}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={masterItemNamePlural} breadcrumbItem={masterItemName + " " + t("List")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                    <Col xl="12">
                        <TableContainer2
                          columns={columns}
                          data={items}
                          handleNewItemClick={() => { handleItemCreateClick(); }}
                          loading={loading}
                          sortable={true}
                          handleSearchBtnClick={ (search) => {setTableOpts({...tableOpts, search: search})} }
                          handlePageBtnClick={ (page) => {setTableOpts({...tableOpts, page: page})} }
                          handleColumnSort={ (evt, colName, direction) => { setTableOpts({...tableOpts, order: direction, orderField: colName})} }

                          pageOptions={
                            {
                              sizePerPage: tableMeta.take,
                              page: tableMeta.page,
                              totalSize: tableMeta.itemCount,
                              custom: true,
                              hideSizePerPage: true
                            }
                          }
                        />

                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ClosedIncidentsList)
