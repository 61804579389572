import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from 'react-select';

//redux
import { withTranslation } from 'react-i18next';
import { addProvider, getCountriesPhoneSelect, getCountriesSelect, getInstallation, getInstallations, getProviders, updateProvider } from '../../helpers/backend_helper';

import '../../components/Common/custom.css';
import classNames from "classnames";
import getAuthUser from "../../components/helpers/auth";

const InstallationEdit = props => {
  const t = props.t;
  const masterItemName = t('Installation');
  const currentUser = getAuthUser()

  console.log(currentUser)
  //meta title
  document.title = masterItemName + t('Config') + " | Tellit";

  const [item, setItem] = useState();
  const [dialings, setDialings] = useState([]);
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const response = await getInstallation(currentUser?.installation?.id)
    setItem(response.data)
    setLoading(false)
  }

  const fetchDialings = async () => {
    const response = await getCountriesPhoneSelect()
    setDialings(response.map(e => {return {value: e.id, label: '+' + e.dialing_prefix + " | " + e.name };}))
    
    validation.setFieldValue('countryId', {
      value: currentUser.countryId,
      label: response.find( e => { return e.id == currentUser.countryId}).name
    })
  }

  useEffect(() => {
    fetchData()
    fetchDialings()
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      dialings: item?.dialings
    },
    validationSchema: Yup.object({
      dialings: Yup.array().min(1).of(Yup.object().shape({
        value: Yup.string().required(t("required")),
      })),
    }),
    onSubmit: async values => {
      if (isEdit) {
        const updateItem = {
          id: item.id,
          dialings: values.dialings.map(e => { return {id: e.value} }),
        };

        setLoadingBtn(true)
        const itemResponse = await updateProvider(item.id, updateItem)
        fetchData()
        setLoadingBtn(false)

        validation.resetForm();
      } else {
        const newItem = {
          countryId: values["countryId"]['value'],
          first_name: values["first_name"],
          last_name: values["last_name"],
          email: values["email"],
          active: values["active"] ? values['active']:false,
        };
        // save new user
        //dispatch(onAddNewUser(newUser));
        setLoadingBtn(true)
        const itemResponse = await addProvider(newItem)
        fetchData()
        setLoadingBtn(false)
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleItemCreateClick = () => {
    setItem(null);
    setIsEdit(false);
    toggle();
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={masterItemName} breadcrumbItem={masterItemName + " " + t("Edit")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-Label">{t('Dialing codes')}</Label>
                          <Select
                            className={classNames({'is-invalid': validation.errors.dialings?true:false})}
                            name="type"
                            options={dialings}
                            isMulti
                            value={validation.values.type || null}
                            onChange={(option, actionMeta) => { validation.setFieldValue('dialings', option, true)}}
                            onBlur={ () => { console.log('blur'); validation.setFieldTouched('dialings', true, true)} }
                            placeholder={t('Select')+'...'}
                          />
                          {validation.touched.dialings &&
                            validation.errors.dialings ? (
                            <FormFeedback type="invalid">
                              {validation.errors.dialings}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-md save-user"
                          >
                            <i className="fas fa-save"></i> {t("Save")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(InstallationEdit)
