import React, { useState, useEffect } from "react"
import {
  Container,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumb from "../components/Common/Breadcrumb"

// actions
import { withTranslation } from 'react-i18next'
import { getClient, getConfigs, setConfigs, updateClientConfig } from "../helpers/backend_helper"

const API_URL = process.env.REACT_APP_TELLIT_API_URL

const SysadminConfig = props => {
  const t = props.t
  const masterItemName = t('System configuration')
  const masterItemNamePlural = t('System configurations')
  //meta title
  document.title = masterItemName + " | Tellit"

  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState (false)
  const [loadingBtn, setLoadingBtn] = useState (false)

  const fetchData = async () => {
    setLoading(true)
    const item = await getConfigs()
    const config = item.filter(e => e.name=='MIN_PHONE_DIGITS')
    console.log('config',config)
    setItem(config.length == 0 ? {}:config[0])
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      min_phone_digits: (item?.value) || 8,
    },
    validationSchema: Yup.object({
      min_phone_digits: Yup.number().required(t("required")),
    }),
    onSubmit: async values => {
      console.log('values',values)
    
      const updateItem = [
        {
          name: "MIN_PHONE_DIGITS",
          value: values.min_phone_digits
        }
      ]

      setLoadingBtn(true)
      const itemResponse = await setConfigs({configs: updateItem})
      fetchData()
      setLoadingBtn(false)

      validation.resetForm()
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={t('System')} breadcrumbItem={masterItemName}/>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">{t("Min phone digits")}</Label>
                  <Input
                    name="min_phone_digits"
                    type="number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.min_phone_digits || ""}
                    invalid={
                      validation.touched.min_phone_digits &&
                        validation.errors.min_phone_digits
                        ? true
                        : false
                    }
                  />
                  {validation.touched.min_phone_digits &&
                    validation.errors.min_phone_digits ? (
                    <FormFeedback type="invalid">
                      {validation.errors.min_phone_digits}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    {t("Save")}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation ()(SysadminConfig)