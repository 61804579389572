import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';

import classnames from "classnames"
import { withTranslation } from 'react-i18next';
import SubAdminTab from './SubAdminTab';
import MasterAdminTab from './MasterAdminTab';

const ClientProfileTab = ({client, t}) => {
    const [activeTab, toggleTab] = useState("0");

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <div className="col-sm order-2 order-sm-1">
                                    <div className="d-flex align-items-start mt-3 mt-sm-0">
                                        <div className="flex-grow-1">
                                            <div>
                                                <h5 className="font-size-16 mb-1">{t('Customer')} { client?.name }</h5>
                                                { client.masterUser && 
                                                <p className='d-flex flex-wrap align-items-start gap-3'>
                                                    <div><b>{t('User')}:</b> {client.masterUser.email}</div> <div><b>{t('Section')}:</b> {client.section?.name}</div>
                                                </p>
                                                }

                                                <div className="d-flex flex-wrap align-items-start gap-3 gap-lg-3 text-muted font-size-13">
                                                    <div>{t('Active')}: <i className="fa fa-check text-success align-middle"></i></div>
                                                    <div>{t('AdBlocking')}: <i className="fa fa-times text-danger align-middle"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-auto order-1 order-sm-2">
                                    <div className="d-flex align-items-start justify-content-end gap-2 d-none">
                                        <div>
                                            <button type="button" className="btn btn-soft-light"><i className="me-1"></i> Message</button>
                                        </div>
                                        <div>
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="btn btn-link font-size-16 shadow-none text-muted" tag="a">
                                                    <i className="bx bx-dots-horizontal-rounded"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </div>
                            </Row>

                            <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "0",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("0")
                                        }}>
                                        {t('Master Admin')}</NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "1",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("1")
                                        }}>
                                        {t('SubAdmin')}</NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "3",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("3")
                                        }}
                                    >{t('Entities')}</NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "6",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("6")
                                        }}
                                    >{t('Teams')}</NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "2",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("2")
                                        }}>
                                        {t('Incident Responders')}</NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "4",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("4")
                                        }}
                                    >{t('Locations')}</NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "7",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("7")
                                        }}
                                    >{t('Notices')}</NavLink>
                                </NavItem>
                                <NavItem className='d-none'>
                                    <NavLink
                                        href="#"
                                        className={classnames({
                                            active: activeTab === "5",
                                        }, "px-3")}
                                        onClick={() => {
                                            toggleTab("5")
                                        }}
                                    >{t('Configurations')}</NavLink>
                                </NavItem>
                            </Nav>
                        </CardBody>
                    </Card>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="0">
                            { activeTab=="0" && <MasterAdminTab client={client} /> }
                        </TabPane>
                        <TabPane tabId="1">
                            { activeTab=="1" && <SubAdminTab client={client} /> }
                        </TabPane>
                        <TabPane tabId="2">
                            
                        </TabPane>
                        <TabPane tabId="3">
                            
                        </TabPane>
                        <TabPane tabId="4">
                            
                        </TabPane>
                        <TabPane tabId="5">
                            
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
}

ClientProfileTab.propTypes = {
    client: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(ClientProfileTab)