import React from 'react';

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {withTranslation} from 'react-i18next';
import {Container, Row} from "reactstrap";
import MarketOverview from './MarketOverview';
import Locations from './Locations';

const Dashboard = (props) => {
    const t = props.t
    document.title = t("Home") + " | Tellit";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={t("Home")} breadcrumbItem={t("")} />
                </Container>
                <Row>
                    { false && <MarketOverview />}
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Dashboard)