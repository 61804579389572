import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

//redux
import { withTranslation } from "react-i18next";
import "../../components/Common/custom.css";
import getAuthUser from "../../components/helpers/auth";
import SponsorNotices from "./Details/SponsorNotices";

const SponsorsAdvertsementsList = (props) => {
  const t = props.t;
  const user = getAuthUser();
  const sponsor = user.sponsor;
  const masterItemName = t("Advertisment");
  const masterItemNamePlural = t("Advertisments");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={masterItemNamePlural}
            breadcrumbItem={masterItemName + " " + t("List")}
          />
          <SponsorNotices sponsor={sponsor} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SponsorsAdvertsementsList);
