import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { useTranslation } from 'react-i18next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import BootstrapTable from "react-bootstrap-table-next"
import ClipLoader from "react-spinners/ClipLoader"
import './custom.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

const TableContainer6 = ({
  columns,
  data,
  handleNewItemClick,
  handleNewItemClick2,
  handleSearchBtnClick,
  handlePageBtnClick,
  addBtnText,
  loading,
  pageOptions,
  handleColumnSort,
  sortable
}) => {
  const { t, i18n } = useTranslation()
  
  sortable = sortable ? true:false
  const disableAdd = addBtnText ? false:true
  const [search, setSearch] = useState('');
  const [color, setColor] = useState("#f46a6a");

  return (
    <React.Fragment>

      <Row className="mb-2">
        <Col sm="4">
        <div className="app-search d-none d-lg-block" style={{paddingTop: '5px'}}>
          <div className="position-relative">
            <input type="text" className="form-control" placeholder={t("Search")+'...'} 
                onKeyUp={(evt) => { setSearch(evt.currentTarget.value) }}
                onKeyPress={(evt) => { if (event.key === 'Enter') { handleSearchBtnClick(search) }}}
            />
            <button className="btn btn-primary" type="button" onClick={() => { handleSearchBtnClick(search) } }>
              <i className="bx bx-search-alt align-middle"></i>
            </button>
          </div>
        </div>
        </Col>
        { !disableAdd &&
        <Col sm="8" className=" pt-1">
          <div className="float-end">
            <UncontrolledDropdown className="mt-4 mt-sm-0 text-sm-end">
              <DropdownToggle tag="a" className="btn btn-primary">
                <i className="fas fa-plus"></i> {t('Add')} <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>

              <DropdownMenu className="">
                <DropdownItem to="#" onClick={handleNewItemClick}>{addBtnText}</DropdownItem>
                <DropdownItem to="#" onClick={handleNewItemClick2}>{t('External Incident Responder')}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
        }
      </Row>

      <Row className="mb-4">
        <Col xl="12">
          <div className="">
            <BootstrapTable
              remote
              sortable={sortable}
              keyField={"id"}
              data = {data}
              columns={columns}
              responsive
              bordered={true}
              striped={true}
              headerClasses="table-light"
              pagination={ paginationFactory({ 
                page: pageOptions.page,
                sizePerPage: pageOptions.sizePerPage,
                totalSize: pageOptions.totalSize,
                hideSizePerPage: true,
                showTotal: true,
                paginationTotalRenderer: (from, to, size) => (
                  <span className="react-bootstrap-table-pagination-total">
                    {t("Showing")} { from } {t("to")} { to } {t("of")} { size } {t("results")}
                  </span>
                )
              })}
              onTableChange={ (type, { page, sortField, sortOrder  }) => { if(type==='sort') handleColumnSort(page, sortField, sortOrder); else handlePageBtnClick(page); } }
              noDataIndication={ loading ? <ClipLoader color={color} loading={loading} size={30} /> : t('No data') }
              showRightSidebarAction={false}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

TableContainer6.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  handleNewItemClick: PropTypes.func,
  handleNewItemClick2: PropTypes.func,
  handleSearchBtnClick: PropTypes.func,
  handlePageBtnClick: PropTypes.func,
  addBtnText: PropTypes.string,
  loading: PropTypes.bool,
  pageOptions: PropTypes.object,
  handleColumnSort: PropTypes.func,
  sortable: PropTypes.bool,
}

export default TableContainer6
