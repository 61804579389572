import React, { useState } from 'react'
import { GoogleMap, StandaloneSearchBox, useJsApiLoader, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types'
import { useEffect } from 'react';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {lat: 19.4009907, lng: -99.1767005}

const libraries = ["places"]

function MapPicker(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBp4hFLEm-fccD3xD6VLKV-PACSQIaOt8M",
    libraries: libraries,
  })

  const [map, setMap] = React.useState(null)
  const [searchBox, setSearchBox] = React.useState(null)
  const [places, setPlaces] = useState([])

  const position = props.position
  const changePosition = props.onChange

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);
    console.log('loaded map')
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onLoadSearchbox = React.useCallback(function callback(searchBox) {
    setSearchBox(searchBox)
  }, [])

  const updatePosition = (latlng) => {
    map.setCenter(latlng)
    changePosition(latlng)
  }

  console.log('places', places)
  console.log('position', position)
  return isLoaded ? (
    <GoogleMap
        clickableIcons={false}
        options={{
            disableDefaultUI: true,
        }}
        mapContainerStyle={containerStyle}
        center={position}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
    >
        <StandaloneSearchBox
            onPlacesChanged={
                () => { setPlaces(searchBox.getPlaces()) }
            }
            onLoad={onLoadSearchbox}
        >
            <>
            <input
                type="text"
                placeholder="Find address and [Enter]"
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-120px",
                    top: '5px'
                }}
            />
            {places.length > 0 &&
            <ol 
                style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `auto`,
                    height: `auto`,
                    padding: `8px`,
                    borderRadius: `3px`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "relative",
                    top: "34px",
                    textAlign: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.3)"
                }}
            >
            {places.map(({ place_id, formatted_address, geometry: { location } }) =>
                <li key={place_id}
                    onClick={()=>{ updatePosition({lat: location.lat(), lng: location.lng() }); setPlaces([]); }}
                    style={{
                        display: `block`,
                        cursor: 'pointer',
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        margin: '3px 0 0 0',
                    }}
                >
                    {formatted_address}
                </li>
            )}
            </ol>
            }
            </>
        </StandaloneSearchBox>
        <Marker
            position={position}
            draggable={true}
            onDragEnd={(evt) => { console.log(evt); updatePosition({lat: evt.latLng.lat(), lng: evt.latLng.lng()})}}
            visible={true}
        />        
    </GoogleMap>
  ) : <></>
}

MapPicker.propTypes = {
    position: PropTypes.object,
    onChange: PropTypes.function
  }

export default React.memo(MapPicker)