import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";
import ClipLoader from "react-spinners/ClipLoader";
import './custom.css';
import { showRightSidebarAction } from '../../store/layout/actions';

const TableContainer5 = ({
  columns,
  data,
  handleNewItemClick,
  handleSearchBtnClick,
  handlePageBtnClick,
  handleButton2Click,
  addBtnText,
  addBtn2Text,
  loading,
  pageOptions,
  handleColumnSort,
  sortable
}) => {
  const { t, i18n } = useTranslation()
  
  const disableAdd = addBtnText ? false:true
  const disableButton2 = addBtn2Text ? false:true
  const [search, setSearch] = useState('');
  const [color, setColor] = useState("#f46a6a");

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  return (
    <React.Fragment>

      <Row className="mb-2">
        <Col sm="4">

        </Col>
        
        <Col sm="8">
          <div className="text-sm-end" style={{paddingTop: '5px'}}>
          { !disableButton2 &&
            <Button
              type="button"
              color="primary"
              className="btn btn-md mb-2 me-2"
              onClick={handleButton2Click}
            >
              <i className="fas fa-plus"></i> { addBtn2Text }
            </Button>
          }
          { !disableAdd &&
            <Button
              type="button"
              color="primary"
              className="btn btn-md mb-2 me-2"
              onClick={handleNewItemClick}
            >
              <i className="fas fa-plus"></i> { addBtnText }
            </Button>

          }
          </div>
        </Col>
        
      </Row>

      <Row className="mb-4">
        <Col xl="12">
          <div className="">
            <BootstrapTable
              sortable={sortable}
              keyField={"id"}
              data = {data}
              columns={columns}
              responsive
              bordered={true}
              striped={true}
              headerClasses="table-light"
              noDataIndication={ loading ? <ClipLoader color={color} loading={loading} size={30} /> : t('No data') }
              showRightSidebarAction={false}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

TableContainer5.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  handleNewItemClick: PropTypes.func,
  handleButton2Click: PropTypes.func,
  handleSearchBtnClick: PropTypes.func,
  handlePageBtnClick: PropTypes.func,
  addBtnText: PropTypes.string,
  addBtn2Text: PropTypes.string,
  loading: PropTypes.bool,
  pageOptions: PropTypes.object,
  handleColumnSort: PropTypes.func,
  sortable: PropTypes.bool,
};

export default TableContainer5;
