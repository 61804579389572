import React from "react";
import { Col, FormFeedback, Input, InputGroup, Label } from "reactstrap";
import Select from "react-select";
import classNames from "classnames";
import NibbleInputImage from "./NibbleInputImage";
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";

const NibbleInput = ({
  md = 6,
  label = "label",
  fieldName = "field_name",
  validation,
  fieldType = "text",
  placeHolder = "",
  selectItems = [],
  selectIsClearable = true,
  selectOptionValue = (option) => option.id,
  selectOptionLabel = (option) => `${option.name}`,
  selectIsSearchable = true,
  selectStringify = null,
  postFileFunction = () => {},
  itemFile = null,
  maxLength = null,
  readOnly = false,
  getOptionValue = (option) => option.id,
}) => {
  if (fieldType == "image")
    return (
      <NibbleInputImage
        md={md}
        label={label}
        fieldName={fieldName}
        validation={validation}
        postFileFunction={postFileFunction}
        itemFile={itemFile}
      />
    );

  if (fieldType == "radio")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-label">{label}</Label> <br />
        {selectItems.map((e, idx) => (
          <div key={idx} className="d-inline-block me-3 ">
            <Input
              type="radio"
              id={fieldName + idx}
              name={fieldName}
              maxLength={255}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={selectOptionValue(e)}
              invalid={
                validation.touched[fieldName] && validation.errors[fieldName]
              }
              checked={
                String(selectOptionValue(e)) ==
                String(validation.values[fieldName])
              }
            />{" "}
            <Label for={fieldName + idx} className="form-label fw-normal">
              {selectOptionLabel(e)}
            </Label>
          </div>
        ))}
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
        
      </Col>
    );

  if (fieldType == "checkbox")
    return (
      <Col className="mt-2" md={md}>
        {label && <><Label className="form-label">{label}</Label> <br /></>}
        {selectItems.map((e, idx) => (
          <div key={idx} className="d-block me-3 ">
            <Input
              type="checkbox"
              id={selectOptionValue(e)}
              name={fieldName}
              onClick={(e) => {
                console.log(e.target.checked);
                const newValue = [...validation.values[fieldName]];
                if (!e.target.checked) {
                  newValue.push(e.target.value);
                } else {
                  const index = validation.values[fieldName].indexOf(
                    e.target.value
                  );
                  newValue.splice(index, 1);
                }
                validation.setFieldValue(fieldName, newValue, true);
              }}
              onBlur={validation.handleBlur}
              value={selectOptionValue(e)}
              invalid={
                validation.touched[fieldName] && validation.errors[fieldName]
              }
              checked={(validation.values[fieldName] ?? []).includes(
                getOptionValue(e)
              )}
            />{" "}
            <Label for={selectOptionValue(e)} className="form-label fw-normal">
              {selectOptionLabel(e)}
            </Label>
          </div>
        ))}
        <div
          className={classNames({
            "is-invalid":
              validation.touched[fieldName] && validation.errors[fieldName]
                ? true
                : false,
          })}
        ></div>
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "textarea")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-label">{label}</Label>
        <Input
          type="textarea"
          className="form-control"
          name={fieldName}
          maxLength={255}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values[fieldName]}
          invalid={
            validation.touched[fieldName] && validation.errors[fieldName]
          }
        />
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "select")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-label">{label}</Label>
        <Select
          placeholder={placeHolder}
          className={classNames({
            "select-control": true,
            "is-invalid":
              validation.touched[fieldName] && validation.errors[fieldName],
          })}
          value={
            selectItems.filter((e) => {
              return selectOptionValue(e) == validation.values[fieldName];
            })[0] ?? null
          }
          stringify={selectStringify ?? selectOptionLabel}
          isClearable={selectIsClearable}
          isSearchable={selectIsSearchable}
          getOptionLabel={selectOptionLabel}
          getOptionValue={selectOptionValue}
          name={fieldName}
          options={selectItems}
          onChange={(option, meta) => {
            validation.setFieldValue(fieldName, option?.id ?? null, true);
          }}
        />
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "time")
    return (
      <Col className="mt-2 form-group" md={md}>
        <Label className="form-label">{label}</Label>

        <InputGroup
          className={classNames({
            "is-invalid":
              validation.touched[fieldName] && validation.errors[fieldName],
          })}
        >
          <Flatpickr
            value={validation.values[fieldName] ?? ""}
            className={classNames({
              "form-control": true,
              "d-block": true,
              "is-invalid":
                validation.touched[fieldName] && validation.errors[fieldName],
            })}
            placeholder="Seleccionar..."
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",
              time_24hr: true,
            }}
            onChange={([date]) => {
              console.log(date);
              validation.setFieldValue(
                fieldName,
                dayjs(date).format("HH:mm"),
                true
              );
            }}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <i className="mdi mdi-clock-outline" />
            </span>
          </div>
        </InputGroup>
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "date")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-Label">{label}</Label>
        <ReactDatePicker
          wrapperClassName={classNames({
            "is-invalid":
              validation.touched[fieldName] && validation.errors[fieldName],
          })}
          className={classNames({
            "form-control": true,
            "is-invalid":
              validation.touched[fieldName] && validation.errors[fieldName],
          })}
          name={fieldName}
          selected={validation.values[fieldName] ?? null}
          onChange={(e) => validation.setFieldValue(fieldName, e, true)}
          dateFormat="dd/MM/yyyy"
          invalid={validation.touched[fieldName] && validation.errors[fieldName]}
        />
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  if (fieldType == "boolean")
    return (
      <Col className="mt-2" md={md}>
        <Label className="form-Label">{label}</Label>{" "}
        <Input
          name={fieldName}
          type="checkbox"
          onClick={(evt) => {
            validation.setFieldValue(fieldName, !evt.target.checked, true);
          }}
          checked={validation.values[fieldName]}
        />
        <FormFeedback type="invalid">
          {validation.errors[fieldName]}
        </FormFeedback>
      </Col>
    );

  return (
    <Col className="mt-2" md={md}>
      <Label className="form-label">{label}</Label>
      <Input
        className="form-control"
        placeholder={placeHolder}
        name={fieldName}
        type={fieldType}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values[fieldName] ?? ""}
        invalid={validation.touched[fieldName] && validation.errors[fieldName]}
        maxLength={maxLength}
        readOnly={readOnly}
      />
      <FormFeedback type="invalid">{validation.errors[fieldName]}</FormFeedback>
    </Col>
  );
};

export default NibbleInput;
