import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";

//redux
import { withTranslation } from "react-i18next";
import {
  addSponsor,
  deleteProvider,
  deleteSponsor,
  getSectionsSelect,
  getSponsors,
  putUserResetPassword,
  updateSponsor,
} from "../../helpers/backend_helper";
import TableContainer2 from "../../components/Common/TableContainer2";

import "../../components/Common/custom.css";
import classNames from "classnames";
import ConfirmModal from "../../components/Common/ConfirmModal";
import getAuthUser from "../../components/helpers/auth";
import ConfirmResetPasswordModal from "../../components/Common/ConfirmResetPasswordModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SponsorContracts from "./Details/SponsorContracts";

const SponsorsContractsList = (props) => {
  const t = props.t;
  const user = getAuthUser();
  const sponsor = user.sponsor;
  const masterItemName = t("Contract");
  const masterItemNamePlural = t("Contracts");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={masterItemNamePlural}
            breadcrumbItem={masterItemName + " " + t("List")}
          />
          <SponsorContracts sponsor={sponsor} readonly={true}/>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(SponsorsContractsList);
