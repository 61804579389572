import PropTypes from 'prop-types'
import React from "react"
import { withTranslation } from 'react-i18next'
import { Col, Modal, ModalBody, Row } from "reactstrap"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, message, t }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick}>
      <ModalBody className="py-2 px-3">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "7em", color: "orange" }}
              />
              <h3>{t("Are you sure?")}</h3>
              <h4>{message ? message : t("You won't be able to revert this!")}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3 pb-4">
              <button
                type="button"
                className="btn btn-primary btn-sm ms-2"
                onClick={onDeleteClick}
              >
                {t("Yes, delete it!")}
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm ms-2"
                onClick={onCloseClick}
              >
                {t("Cancel")}
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any
}

export default withTranslation()(DeleteModal)
